
import { NotificationType } from "./notifications/Notifications";
import { ssePostRequest } from "./httpUtils";

/**
 * A helper function that fetches chunks of a PDF document from the server.
 * This function makes a request to the server to get chunks of a PDF document
 * and returns a promise that resolves with the fetched data.
 * It uses the promise pattern for backwards compatibility purposes.
 *
 * @param {Object} payload - The payload to be sent to the server.
 * @returns {Promise<Object>} A promise that resolves with the fetched data from the server.
 * @throws Will throw an error if there is a problem fetching the chunks.
 */
const getChunksInternal = (payload) => new Promise((resolve, reject) => {
  const payloadForChunksOnly = { ...payload };
  payloadForChunksOnly.get_chunks_only = true;
  try {
    const source = ssePostRequest("/api/query_chatbot", payloadForChunksOnly, {
      onFinal(payload) {
        source.close();
        resolve(payload.value);
      },
      onError(error) {
        source.close();
        reject(error)
      }
    });
  } catch (error) {
    reject(error);
  }
});

/**
 * Fetches chunks of a PDF document and processes them to extract relevant information.
 * This function makes a request to the server to get chunks of a PDF document,
 * processes these chunks to calculate their dimensions and positions, and then
 * updates the state with the processed chunks and relevant files.
 *
 * @param {Object} options - The options object containing callback functions and payload.
 * @param {Function} options.addNotification - A function to display notifications.
 * @param {Object} options.payload - The payload to be sent to the server.
 * @param {Function} options.setChunkLocations - A function to update the chunk locations state.
 * @param {Function} options.setHighlightAreas - A function to update the highlight areas state.
 * @param {Function} options.setRelevantFiles - A function to update the relevant files state.
 * @param {Array} options.successfulFileUploads - An array of successfully uploaded files.
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 * @throws Will throw an error if there is a problem fetching or processing the chunks.
 */
const getChunks = async ({
  addNotification,
  payload,
  setChunkLocations,
  setHighlightAreas,
  setRelevantFiles,
  successfulFileUploads,
}) => {
  try {
    const response = await getChunksInternal(payload);
    let chunks = response.chunks;
    const files_based_on_summaries = response.files_based_on_summaries.map(
      (fileid) => {
        return successfulFileUploads.find(
          (file) => file.data.file_id === fileid
        );
      }
    );

    console.log("HERE ARE THE FILES BASED ON SUMMARIES");
    console.log(files_based_on_summaries);

    const finalChunks = chunks.map((chunk) => {
      return {
        pageIndex: chunk[1] - 1,
        height: (chunk[5] / chunk[7]) * 100,
        width: (chunk[4] / chunk[6]) * 100,
        left: (chunk[3] / chunk[6]) * 100,
        top: (chunk[2] / chunk[7]) * 100,
        file_id: chunk[8],
      };
    });

    console.log("FINAL CHUNKS!");
    console.log(finalChunks);

    setHighlightAreas(finalChunks);

    // Extract pageIndexes and remove duplicates
    const uniquePageIndexes = finalChunks
      .reduce((acc, current) => {
        // Check if an object with the same pageIndex and file_id already exists in the accumulator
        const duplicateIndex = acc.findIndex(
          (obj) =>
            obj.pageIndex === current.pageIndex &&
            obj.file_id === current.file_id
        );

        // If no duplicate is found, add the current object to the accumulator
        if (duplicateIndex === -1) {
          acc.push({
            pageIndex: current.pageIndex,
            file_id: current.file_id,
          });
        }

        return acc;
      }, [])
      .map((obj) => ({ pageIndex: obj.pageIndex + 1, file_id: obj.file_id }))
      .sort((a, b) => a.pageIndex - b.pageIndex);

    setChunkLocations(uniquePageIndexes);
    setRelevantFiles && setRelevantFiles(files_based_on_summaries);
    if (!setRelevantFiles) {
      console.warn(
        "setRelevantFiles is not defined. Relevant files will not be set."
      );
    }
  } catch (error) {
    console.log("error", error)
    addNotification && addNotification(
      "Error getting relevant highlights from PDF.",
      "",
      NotificationType.error
    );
    if (!addNotification) {
      console.warn(
        "addNotification is not defined. No notification will be shown."
      );
    }
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
    } else if (error.request) {
      // console.log(error.request);
    } else {
      // console.log("Error", error.message);
    }
    // console.log(error.config);
  }
};

export default getChunks;

  

