import { useState, useEffect } from "react";
import { postRequest } from "../../utils/httpUtils";
// import TableDetails from "../../utils/TableDetails";
import { Viewer } from "../../utils";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import Typed from "react-typed";

export default function Results({ files, appInstanceID }) {
  const [tables, setTables] = useState({});
  const [viewMode, setViewMode] = useState("ui");
  //const [annotatedPdfFile, setAnnotatedPdfFile] = useState(null);
  const [isAnnotating, setIsAnnotating] = useState(false);
  // const [comparisonPdfUrl, setComparisonPdfUrl] = useState(null);
  // const [differences, setDifferences] = useState(null);
  const [isComparing, setIsComparing] = useState(false);
  const [dates, setDates] = useState({});
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [isParsing, setIsParsing] = useState(false);
  //const { jumpToPage } = pageNavigationPluginInstance;
  const [checkOptions, setCheckOptions] = useState({
    previousYear: true,
    trackedChanges: false,
    mathAccuracy: false,
  });
  const [processedDates, setProcessedDates] = useState({
    currentYear: null,
    previousYear: null,
    sortedFileIds: {
      current: null,
      previous: null,
    },
  });
  const [annotatedPdfFiles, setAnnotatedPdfFiles] = useState({
    previousYear: {
      file1: null,
      file2: null,
    },
    trackedChanges: {
      file1: null,
      file2: null,
    },
    mathAccuracy: {
      file1: null,
      file2: null,
    },
  });
  // Fetch tables data
  useEffect(() => {
    const getTables = async () => {
      try {
        setIsParsing(true); 
        const response = await postRequest(
          "/api/footnote_ai/consistency_check",
          {
            files,
            app_instance_id: appInstanceID,
          }
        );
        
        const { footnotes, dates } = response.data.data;

        console.log("Tables:", footnotes);
        // console.log("Dates:", dates);
        setTables(footnotes);
        setDates(dates);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
      finally {
        setIsParsing(false); 
      }
    };
    getTables();
  }, [files, appInstanceID]);

  useEffect(() => {
    if (!Object.keys(dates).length) return;

    const processDatesData = () => {
      // Process dates to get years and sort file IDs
      const datesArray = Object.entries(dates).map(([fileId, dateStr]) => ({
        fileId,
        date: new Date(dateStr),
        year: new Date(dateStr).getFullYear(),
      }));

      // Sort by year descending (most recent first)
      datesArray.sort((a, b) => b.year - a.year);

      setProcessedDates({
        currentYear: datesArray[0].year,
        previousYear: datesArray[1].year,
        sortedFileIds: {
          current: datesArray[0].fileId,
          previous: datesArray[1].fileId,
        },
      });
    };

    processDatesData();
  }, [dates]);

  // useEffect(() => {
  //   const handleAnnotation = async () => {
  //     console.log("View Mode set to:", viewMode);
  //     if(annotatedPdfFile){ console.log("Annotated PDF File:", annotatedPdfFile); }
  //     else { console.log("Annotated PDF File is null"); }
  //     if (viewMode === "ui" && !annotatedPdfFile) {
  //       await createAnnotatedPdf();
  //     }
  //   };

  //   handleAnnotation();
  // }, [viewMode, annotatedPdfFile, tables]);

  // useEffect(() => {
  //   const handleComparison = async () => {
  //     if (viewMode === "tracked" && !comparisonPdfUrl) {
  //       await createComparisonPdf();
  //     }
  //   };

  //   handleComparison();
  // }, [viewMode, comparisonPdfUrl, tables]);

  useEffect(() => {
    if (viewMode !== "ui") return;

    console.log("Check Options:", checkOptions);
    const generatePDFs = async () => {
      try {

        const processedData = {};
        Object.entries(tables).forEach(([footnoteTitle, files]) => {
          const comparisons = files?.comparisons?.results || null;
          const verifications = files?.verify_total?.results || null;
          processedData[footnoteTitle] = processComparisonsAndVerifications(comparisons, verifications);
        });

        if (
          checkOptions.previousYear &&
          (!annotatedPdfFiles?.previousYear?.file1 ||
            !annotatedPdfFiles?.previousYear?.file2)
        ) {
          await createPreviousYearCheckPdf();
        } else if (
          checkOptions.trackedChanges &&
          (!annotatedPdfFiles?.trackedChanges?.file1 ||
            !annotatedPdfFiles?.trackedChanges?.file2)
        ) {
          await createTrackedChangesPdf();
        } else if (
          checkOptions.mathAccuracy &&
          (!annotatedPdfFiles?.mathAccuracy?.file1 ||
            !annotatedPdfFiles?.mathAccuracy?.file2)
        ) {
          await createSummationCheckPdf();
        }
      } catch (error) {
        console.error("Error generating PDFs:", error);
      }
    };

    if (Object.keys(tables).length > 0) {  // Only run if tables data is available
      generatePDFs();
    }
  }, [checkOptions, viewMode,tables]);

  const handleCheckboxChange = (option) => {
    console.log("Selected Checkbox option:", option);
    setCheckOptions({
      previousYear: option === "previousYear",
      trackedChanges: option === "trackedChanges",
      mathAccuracy: option === "mathAccuracy",
    });
  };

  const createPreviousYearCheckPdf = async () => {
    if (viewMode !== "ui") return;
    // Skip if already generated
    if (
      annotatedPdfFiles.previousYear.file1 &&
      annotatedPdfFiles.previousYear.file2
    ) {
      return;
    }
    try {
      setIsAnnotating(true);

      // Create maps for both files
      const file1AnnotationsMap = new Map();
      const file2AnnotationsMap = new Map();

      // Process each footnote entry
      Object.entries(tables).forEach(([footnoteTitle, files]) => {
        if (!files.comparisons?.results) return;

        // Get file entries
        const fileEntries = Object.entries(files).filter(
          ([key]) => key !== "comparisons" && key !== "verify_total"
        );

        if (fileEntries.length < 2) return;

        const [file1ArrayString, file1Values] = fileEntries[0];
        const [file2ArrayString, file2Values] = fileEntries[1];
        const [, file1ID] = JSON.parse(file1ArrayString);
        const [, file2ID] = JSON.parse(file2ArrayString);

        const file1Data = JSON.parse(file1Values[0]);
        const file2Data = JSON.parse(file2Values[0]);

        // Process each comparison result
        files.comparisons.results.forEach((comp) => {
          // File 1 data
          const [row1, col1] = comp.position[0];
          const cell1 = file1Data.cells?.find(
            (cell) => cell.col === col1 && cell.row === row1
          );

          // File 2 data
          const [row2, col2] = comp.position[1];
          const cell2 = file2Data.cells?.find(
            (cell) => cell.col === col2 && cell.row === row2
          );

          if (cell1 && cell2) {
            const key1 = `${cell1.page_number}-${cell1.top}-${cell1.left}-${comp.value1}`;
            const key2 = `${cell2.page_number}-${cell2.top}-${cell2.left}-${comp.value2}`;

            // All comparisons are added with their is_equal status
            if (!file1AnnotationsMap.has(key1)) {
              file1AnnotationsMap.set(key1, {
                value: comp.value1,
                is_equal: comp.is_equal,
                page_number: cell1.page_number,
                color: comp.is_equal ? [0,1,0.94]: [1,0.70,0.75], 
                bounding_box: {
                  top: cell1.top,
                  left: cell1.left,
                  width: cell1.width,
                  height: cell1.height,
                  page_width: cell1.page_width,
                  page_height: cell1.page_height,
                },
              });
            }

            if (!file2AnnotationsMap.has(key2)) {
              file2AnnotationsMap.set(key2, {
                value: comp.value2,
                is_equal: comp.is_equal,
                page_number: cell2.page_number,
                color: comp.is_equal ? [0,1,0.94] : [1,0.70,0.75],
                bounding_box: {
                  top: cell2.top,
                  left: cell2.left,
                  width: cell2.width,
                  height: cell2.height,
                  page_width: cell2.page_width,
                  page_height: cell2.page_height,
                },
              });
            }
          }
        });

        window.file1ID = file1ID;
        window.file2ID = file2ID;
      });

      console.log("File 1 Annotations Map:", file1AnnotationsMap);
      console.log("File 2 Annotations Map:", file2AnnotationsMap);

      // Create annotated PDFs for both files
      const [file1Response, file2Response] = await Promise.all([
        postRequest("/api/footnote_ai/annotate_pdf_after_comparison", {
          file_id: window.file1ID,
          footnote_title: "Previous Year Comparison",
          comparisons: Array.from(file1AnnotationsMap.values()),
        }),
        postRequest("/api/footnote_ai/annotate_pdf_after_comparison", {
          file_id: window.file2ID,
          footnote_title: "Previous Year Comparison",
          comparisons: Array.from(file2AnnotationsMap.values()),
        }),
      ]);

      setAnnotatedPdfFiles((prev) => ({
        ...prev,
        previousYear: {
          file1: file1Response.data?.annotated_file || null,
          file2: file2Response.data?.annotated_file || null,
        },
      }));
    } catch (error) {
      console.error("Error creating previous year PDFs:", error);
    } finally {
      setIsAnnotating(false);
    }
  };

  const createTrackedChangesPdf = async () => {
    if (viewMode !== "ui") return;
    if (
      annotatedPdfFiles.trackedChanges.file1 &&
      annotatedPdfFiles.trackedChanges.file2
    ) {
      return;
    }
    try {
      setIsComparing(true);

      // Get file entries from the first footnote
      const firstEntry = Object.entries(tables)[0];
      if (!firstEntry) return;

      const [_, files] = firstEntry;
      const fileEntries = Object.entries(files).filter(
        ([key]) => key !== "comparisons" && key !== "verify_total"
      );

      if (fileEntries.length < 2) return;

      // Get file IDs from the entries
      const file1ID = JSON.parse(fileEntries[0][0])[1];
      const file2ID = JSON.parse(fileEntries[1][0])[1];

      console.log("Making comparison request with:", { file1ID, file2ID });

      // Make comparison request with PDF output
      const response = await postRequest("/api/footnote_ai/compare_and_highlight_pdf_diff", {
        file1_id: file1ID,
        file2_id: file2ID,
        output_type: "pdf",
      });
      console.log("Comparison API Response:", response);
      if (response.data?.files) {
        console.log("Setting annotated PDF files:", response.data.files);
        console.log("Highlights for file 1:", response.data.highlights.file1);
            console.log("Highlights for file 2:", response.data.highlights.file2);
            
            // Process highlights similar to how comparisons are processed
            const file1HighlightMap = new Map();
            const file2HighlightMap = new Map();

            response.data.highlights.file1.forEach(highlight => {
                const key = `${highlight.page_number}-${highlight.bounding_box.top}-${highlight.bounding_box.left}-${highlight.value}`;
                file1HighlightMap.set(key, highlight);
            });

            response.data.highlights.file2.forEach(highlight => {
                const key = `${highlight.page_number}-${highlight.bounding_box.top}-${highlight.bounding_box.left}-${highlight.value}`;
                file2HighlightMap.set(key, highlight);
            });

            console.log("Processed highlight maps:");
            console.log("File 1 highlights:", file1HighlightMap);
            console.log("File 2 highlights:", file2HighlightMap);

        setAnnotatedPdfFiles((prev) => ({
          ...prev,
          trackedChanges: {
            file1: response.data.files.file1 || null,
            file2: response.data.files.file2 || null,
            highlights: {
              file1: file1HighlightMap,
              file2: file2HighlightMap
          },
          },
        }));
      }
      else {
        console.error("No files in response data:", response.data);
      }
    } catch (error) {
      console.error("Error creating tracked changes PDFs:", error);
    } finally {
      setIsComparing(false);
    }
  };

  const createSummationCheckPdf = async () => {
    if (viewMode !== "ui") return;
    if (
      annotatedPdfFiles.mathAccuracy.file1 
    ) {
      return;
    }
    try {
      setIsAnnotating(true);

      const file1VerificationsMap = new Map();

      Object.entries(tables).forEach(([footnoteTitle, files]) => {
        if (!files.verify_total?.results) return;

        const fileEntries = Object.entries(files).filter(
          ([key]) => key !== "comparisons" && key !== "verify_total"
        );

        if (fileEntries.length < 2) return;

        const [file1ArrayString, file1Values] = fileEntries[0];
        const [, file1ID] = JSON.parse(file1ArrayString);
        const file1Data = JSON.parse(file1Values[0]);

        files.verify_total.results.forEach((verify) => {
          if (verify.table === "table_one") {
            const [row, col] = verify.position[0];
            const cell = file1Data.cells?.find(
              (cell) => cell.col === col && cell.row === row
            );
            if (cell) {
              const key = `${cell.page_number}-${cell.top}-${cell.left}-verify`;
              file1VerificationsMap.set(key, {
                value: verify.is_equal ? "Correct" : "Incorrect",
                page_number: cell.page_number,
                bounding_box: {
                  top: cell.top,
                  left: cell.left + cell.width * 0.7,
                  width: cell.width * 0.1,
                  height: cell.height * 0.8,
                  page_width: cell.page_width,
                  page_height: cell.page_height,
                },
                annotation_type: "point",
                color: verify.is_equal ? [0,1,0.94]: [1,0.70,0.75],
              });
            }
          }
        });

        window.file1ID = file1ID;
      });

      const file1Response = await postRequest("/api/footnote_ai/annotate_pdf_after_comparison", {
        file_id: window.file1ID,
        footnote_title: "Summation Check",
        comparisons: Array.from(file1VerificationsMap.values()),
        differences: [],
      });
      console.log("File 1 response:", file1Response.data);
      setAnnotatedPdfFiles((prev) => ({
        ...prev,
        mathAccuracy: {
          file1: file1Response.data?.annotated_file || null,
          file2: null, 
        },
      }));
    } catch (error) {
      console.error("Error creating summation check PDFs:", error);
    } finally {
      setIsAnnotating(false);
    }
  };

  const getActivePDFs = () => {
    if (!annotatedPdfFiles) return { file1: null, file2: null };

    if (checkOptions.previousYear && annotatedPdfFiles.previousYear) {
      return annotatedPdfFiles.previousYear;
    }
    if (checkOptions.trackedChanges && annotatedPdfFiles.trackedChanges) {
      return annotatedPdfFiles.trackedChanges;
    }
    if (checkOptions.mathAccuracy && annotatedPdfFiles.mathAccuracy) {
      return annotatedPdfFiles.mathAccuracy;
    }

    return { file1: null, file2: null };
  };

  // const createComparisonPdf = async () => {
  //   if (viewMode !== "tracked" || comparisonPdfUrl) return;

  //   try {
  //     setIsComparing(true);

  //     // Get file entries from the first footnote
  //     const firstEntry = Object.entries(tables)[0];
  //     if (!firstEntry) return;

  //     const [_, files] = firstEntry;
  //     const fileEntries = Object.entries(files).filter(
  //       ([key]) => key !== "comparisons" && key !== "verify_total"
  //     );

  //     if (fileEntries.length < 2) return;

  //     // Get file IDs from the entries
  //     const file1ID = JSON.parse(fileEntries[0][0])[1];
  //     const file2ID = JSON.parse(fileEntries[1][0])[1];

  //     // Make comparison request
  //     const response = await postRequest("/api/compare_pdfs", {
  //       file1_id: file1ID,
  //       file2_id: file2ID,
  //       output_type: "json",
  //     });

  //     if (response.data?.differences) {
  //       setDifferences(response.data.differences);
  //     }
  //   } catch (error) {
  //     console.error("Error creating comparison PDF:", error);
  //   } finally {
  //     setIsComparing(false);
  //   }
  // };

  // const createAnnotatedPdf = async () => {
  //   if (viewMode !== "ui" || annotatedPdfFile) return;

  //   try {
  //     setIsAnnotating(true);

  //     // Create maps for both files
  //     const file1AnnotationsMap = {
  //       matching: new Map(),
  //       differing: new Map(),
  //       verifications: new Map(),
  //     };
  //     const file2AnnotationsMap = {
  //       matching: new Map(),
  //       differing: new Map(),
  //       verifications: new Map(),
  //     };

  //     // Process each footnote entry
  //     Object.entries(tables).forEach(([footnoteTitle, files]) => {
  //       if (!files.comparisons?.results) return;

  //       // Get file entries
  //       const fileEntries = Object.entries(files).filter(
  //         ([key]) => key !== "comparisons" && key !== "verify_total"
  //       );

  //       if (fileEntries.length < 2) return;

  //       const [file1ArrayString, file1Values] = fileEntries[0];
  //       const [file2ArrayString, file2Values] = fileEntries[1];
  //       const [, file1ID] = JSON.parse(file1ArrayString);
  //       const [, file2ID] = JSON.parse(file2ArrayString);

  //       const file1Data = JSON.parse(file1Values[0]);
  //       const file2Data = JSON.parse(file2Values[0]);

  //       // Process each comparison result
  //       files.comparisons.results.forEach((comp) => {
  //         // File 1 data
  //         const [row1, col1] = comp.position[0];
  //         const cell1 = file1Data.cells?.find(
  //           (cell) => cell.col === col1 && cell.row === row1
  //         );

  //         // File 2 data
  //         const [row2, col2] = comp.position[1];
  //         const cell2 = file2Data.cells?.find(
  //           (cell) => cell.col === col2 && cell.row === row2
  //         );

  //         if (cell1 && cell2) {
  //           const key1 = `${cell1.page_number}-${cell1.top}-${cell1.left}-${comp.value1}`;
  //           const key2 = `${cell2.page_number}-${cell2.top}-${cell2.left}-${comp.value2}`;

  //           if (comp.is_equal) {
  //             // Store matching values (green)
  //             if (!file1AnnotationsMap.matching.has(key1)) {
  //               file1AnnotationsMap.matching.set(key1, {
  //                 value: comp.value1,
  //                 page_number: cell1.page_number,
  //                 bounding_box: {
  //                   top: cell1.top,
  //                   left: cell1.left,
  //                   width: cell1.width,
  //                   height: cell1.height,
  //                   page_width: cell1.page_width,
  //                   page_height: cell1.page_height,
  //                 },
  //               });
  //             }
  //             if (!file2AnnotationsMap.matching.has(key2)) {
  //               file2AnnotationsMap.matching.set(key2, {
  //                 value: comp.value2,
  //                 page_number: cell2.page_number,
  //                 bounding_box: {
  //                   top: cell2.top,
  //                   left: cell2.left,
  //                   width: cell2.width,
  //                   height: cell2.height,
  //                   page_width: cell2.page_width,
  //                   page_height: cell2.page_height,
  //                 },
  //               });
  //             }
  //           } else {
  //             // Store differing values (red)
  //             if (!file1AnnotationsMap.differing.has(key1)) {
  //               file1AnnotationsMap.differing.set(key1, {
  //                 value: comp.value1,
  //                 page_number: cell1.page_number,
  //                 bounding_box: {
  //                   top: cell1.top,
  //                   left: cell1.left,
  //                   width: cell1.width,
  //                   height: cell1.height,
  //                   page_width: cell1.page_width,
  //                   page_height: cell1.page_height,
  //                 },
  //               });
  //             }
  //             if (!file2AnnotationsMap.differing.has(key2)) {
  //               file2AnnotationsMap.differing.set(key2, {
  //                 value: comp.value2,
  //                 page_number: cell2.page_number,
  //                 bounding_box: {
  //                   top: cell2.top,
  //                   left: cell2.left,
  //                   width: cell2.width,
  //                   height: cell2.height,
  //                   page_width: cell2.page_width,
  //                   page_height: cell2.page_height,
  //                 },
  //               });
  //             }
  //           }
  //         }
  //       });

  //       // Process verify_total results if they exist
  //       if (files.verify_total?.results?.length > 0) {
  //         files.verify_total.results.forEach((verify) => {
  //           const [row, col] = verify.position[0];

  //           // Get the appropriate table cells based on verify.table
  //           const cell =
  //             verify.table === "table_one"
  //               ? file1Data.cells?.find(
  //                   (cell) => cell.col === col && cell.row === row
  //                 )
  //               : file2Data.cells?.find(
  //                   (cell) => cell.col === col && cell.row === row
  //                 );

  //           if (cell) {
  //             const key = `${cell.page_number}-${cell.top}-${cell.left}-verify`;
  //             const annotation = {
  //               value: verify.is_equal ? "✔️" : "❌",
  //               page_number: cell.page_number,
  //               bounding_box: {
  //                 top: cell.top,
  //                 left: cell.left + cell.width * 0.7, // Start at 70% of cell width
  //                 width: cell.width * 0.1, // Make mark only 10% of cell width
  //                 height: cell.height * 0.8, // Make mark slightly shorter
  //                 page_width: cell.page_width,
  //                 page_height: cell.page_height,
  //               },
  //             };

  //             // Add to appropriate map based on table and equality
  //             if (verify.table === "table_one") {
  //               if (verify.is_equal) {
  //                 file1AnnotationsMap.matching.set(key, annotation);
  //               } else {
  //                 file1AnnotationsMap.differing.set(key, annotation);
  //               }
  //             } else {
  //               if (verify.is_equal) {
  //                 file2AnnotationsMap.matching.set(key, annotation);
  //               } else {
  //                 file2AnnotationsMap.differing.set(key, annotation);
  //               }
  //             }
  //           }
  //         });
  //       }

  //       // Save the file IDs
  //       window.file1ID = file1ID;
  //       window.file2ID = file2ID;
  //     });
  //     console.log(
  //       "File 1 Annotations Map matching:",
  //       file1AnnotationsMap.matching
  //     );
  //     console.log(
  //       "File 1 Annotations Map differing:",
  //       file1AnnotationsMap.differing
  //     );

  //     console.log(
  //       "File 2 Annotations Map matching:",
  //       file2AnnotationsMap.matching
  //     );
  //     console.log(
  //       "File 2 Annotations Map differing:",
  //       file2AnnotationsMap.differing
  //     );
  //     // Create annotated PDFs for both files
  //     const [file1Response, file2Response] = await Promise.all([
  //       postRequest("/api/footnote_ai/annotate_pdf_after_comparison", {
  //         file_id: window.file1ID,
  //         footnote_title: "Combined Annotations",
  //         comparisons: Array.from(file1AnnotationsMap.matching.values()),
  //         differences: Array.from(file1AnnotationsMap.differing.values()),
  //       }),
  //       postRequest("/api/footnote_ai/annotate_pdf_after_comparison", {
  //         file_id: window.file2ID,
  //         footnote_title: "Combined Annotations",
  //         comparisons: Array.from(file2AnnotationsMap.matching.values()),
  //         differences: Array.from(file2AnnotationsMap.differing.values()),
  //       }),
  //     ]);
  //     // After the Promise.all
  //     console.log("File 1 response:", file1Response.data);
  //     console.log("File 2 response:", file2Response.data);

  //     // Store both annotated files
  //     setAnnotatedPdfFiles({
  //       file1: file1Response.data?.annotated_file,
  //       file2: file2Response.data?.annotated_file,
  //     });
  //   } catch (error) {
  //     console.error("Error creating annotated PDFs:", error);
  //   } finally {
  //     setIsAnnotating(false);
  //   }
  // };

  const processComparisonsAndVerifications = (comparisons, verifications) => {
    console.log(
      "processComparisonsAndVerifications() : Comparisons:",
      comparisons
    );
    console.log(
      "processComparisonsAndVerifications() : Verifications:",
      verifications
    );
    const cellsToHighlight = {
      0: [],
      1: [],
    };

    const verifiedCells = {
      0: [], // Verified cells for first table
      1: [], // Verified cells for second table
    };

    if (comparisons) {
      comparisons.forEach((item) => {
        const color =
          item.is_equal === true
            ? "#90EE90"
            : item.is_equal === false
            ? "#FF7F7F"
            : "#CCCCCC";

        const [table1Row, table1Col] = item.position[0];
        cellsToHighlight[0].push([table1Row, table1Col, color]);

        const [table2Row, table2Col] = item.position[1];
        cellsToHighlight[1].push([table2Row, table2Col, color]);
      });
    }

    // Handle verifications
    if (verifications) {
      verifications.forEach((item) => {
        const tableIndex = item.table === "table_one" ? 0 : 1;
        const [row, col] = item.position[0];
        verifiedCells[tableIndex].push([row, col, item.is_equal]);
      });
    }

    return { cellsToHighlight, verifiedCells };
  };

  // const renderSideBySideComparison = () => {
  //   return Object.entries(tables).map(([footnoteTitle, files], i) => {
  //     const comparisons = files?.comparisons?.results || null;
  //     const verifications = files?.verify_total?.results || null;
  //     const { cellsToHighlight, verifiedCells } =
  //       processComparisonsAndVerifications(comparisons, verifications);

  //     return (
  //       <div key={footnoteTitle} className="mb-8">
  //         <h1 className="text-xxl font-bold mb-4 text-center">
  //           {footnoteTitle}
  //         </h1>
  //         <div
  //           className="grid grid-cols-1 md:grid-cols-2 gap-8"
  //           style={{ direction: "rtl" }}
  //         >
  //           {Object.entries(files).map(
  //             ([fileArrayString, arrayValues], tableIndex) => {
  //               if (
  //                 fileArrayString === "comparisons" ||
  //                 fileArrayString === "verify_total"
  //               )
  //                 return null;

  //               const [fileName, fileID] = JSON.parse(fileArrayString);

  //               return (
  //                 <div key={fileArrayString} className="border rounded-lg p-4">
  //                   <h3 className="text-md mb-4 text-center font-semibold">
  //                     {fileName}
  //                   </h3>
  //                   <div className="mb-6">
  //                     <div className="border rounded overflow-hidden">
  //                       <Viewer
  //                         fileUrl={`/api/files/${fileID}.pdf`}
  //                         plugins={[pageNavigationPluginInstance]}
  //                         onDocumentLoad={() => {
  //                           if (arrayValues?.[0]) {
  //                             const parsedData = JSON.parse(arrayValues[0]);
  //                             if (parsedData.cells?.[0]) {
  //                               jumpToPage(parsedData.cells[0].page_number - 1);
  //                             }
  //                           }
  //                         }}
  //                       />
  //                     </div>
  //                   </div>
  //                   <div className="border rounded p-4 bg-white">
  //                     <TableDetails
  //                       tableDetails={JSON.parse(arrayValues[0]).cells}
  //                       highlights={cellsToHighlight[tableIndex]}
  //                       tableVerifiedCells={verifiedCells[tableIndex]}
  //                     />
  //                   </div>
  //                 </div>
  //               );
  //             }
  //           )}
  //         </div>
  //         <hr className="my-8" />
  //       </div>
  //     );
  //   });
  // };

  const renderUIView = () => {
    const firstEntry = Object.entries(tables)[0];
    if (!firstEntry) return null;
    const [_, files] = firstEntry;
    const fileEntries = Object.entries(files)
      .filter(([key]) => key !== "comparisons" && key !== "verify_total")
      .slice(0, 2);
    // Sort fileEntries to match our date order
    const sortedFileEntries = fileEntries.sort(([aKey], [bKey]) => {
      const aFileId = JSON.parse(aKey)[1];
      const bFileId = JSON.parse(bKey)[1];
      if (aFileId === processedDates.sortedFileIds.current) return -1;
      if (bFileId === processedDates.sortedFileIds.current) return 1;
      return 0;
    });
    const activePDFs = getActivePDFs();
    return (
      

      <div className="flex flex-col">
        <div className="border-b p-2">
          <div className="flex justify-between">
            <div className="border rounded-lg p-4 shadow-md w-1/2">
              <div className="mb-2">
                <input
                  type="checkbox"
                  id="py-check"
                  checked={checkOptions.previousYear}
                  onChange={() => handleCheckboxChange("previousYear")}
                />
                <label htmlFor="py-check" className="ml-2">
                  Previous Year Check
                </label>
              </div>

              <div className="mb-2">
                <input
                  type="checkbox"
                  id="tracked-changes"
                  checked={checkOptions.trackedChanges}
                  onChange={() => handleCheckboxChange("trackedChanges")}
                />
                <label htmlFor="tracked-changes" className="ml-2">
                  Tracked Changes
                </label>
              </div>

              <div>
                <input
                  type="checkbox"
                  id="math-accuracy"
                  checked={checkOptions.mathAccuracy}
                  onChange={() => handleCheckboxChange("mathAccuracy")}
                />
                <label htmlFor="math-accuracy" className="ml-2">
                  Mathematical Accuracy
                </label>
              </div>
            </div>

            <div className="border rounded-lg p-4 shadow-md w-1/2">
              <div className="flex items-center gap-2 mb-2">
                <div className="w-4 h-4 bg-customBlue-100 rounded"></div>
                <span>Matching Text</span>
              </div>
              <div className="flex items-center gap-2 mb-2">
                <div className="w-4 h-4 bg-customRed-100 rounded"></div>
                <span>Non-matching Text</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 text-gray-600">✔️</div>
                <span>Mathematical Accuracy</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 p-4 h-[calc(100vh-8rem)]">
          {sortedFileEntries.map(([fileArrayString, arrayValues], index) => {
            const [fileName, fileID] = JSON.parse(fileArrayString);
            const pdfFile = checkOptions.mathAccuracy 
              ? (index === 0 ? activePDFs?.file1 : null)
              : (index === 0 ? activePDFs?.file2 : activePDFs?.file1);
            if (index === 1 && checkOptions.mathAccuracy) {
              return null;
            }
            return (
              <div key={fileArrayString} className="flex-1 border rounded">
                <div className="border-b p-2">
                  <div className="text-center font-medium">
                    {index === 0
                      ? `Current Year: ${processedDates.currentYear}`
                      : `Previous Year: ${processedDates.previousYear}`}
                  </div>
                </div>
                <div className="h-[calc(100%-3rem)]">
                  {isAnnotating ? (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50">
                      <div className="text-gray-600">
                        Creating annotated PDF...
                      </div>
                    </div>
                  ) : (
                    <Viewer
                      fileUrl={
                        pdfFile
                          ? `/api/files/${pdfFile}`
                          : `/api/files/${fileID}.pdf`
                      }
                      plugins={[pageNavigationPluginInstance]}
                    />
                  )}
                </div>
              </div>
            );
          })}
          
      </div>
      </div>
    );
  };

  return (
    
    <div className="p-4">
      {/* <div className="mb-4 flex justify-center">
        <div className="inline-flex rounded-lg border border-gray-200">
          <button
            className={`px-6 py-2 rounded-l-lg ${
              viewMode === "comparison"
                ? "bg-customHighlightColor text-white"
                : "bg-white text-gray-700"
            }`}
            onClick={() => setViewMode("comparison")}
          >
            Side-by-Side Comparison
          </button>
          <button
            className={`px-6 py-2 rounded-r-lg ${
              viewMode === "ui"
                ? "bg-customHighlightColor text-white"
                : "bg-white text-gray-700"
            }`}
            onClick={() => setViewMode("ui")}
          >
            UI
          </button>
        </div>
      </div> */}

      {/* {viewMode === "comparison"
        ? renderSideBySideComparison()
        : renderUIView()} */}
      <div className="flex flex-col relative">
          {isParsing && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 z-50">
              <div className="text-gray-600 text-xl">
                Working through and comparing documents
                <Typed strings={["..."]} loop typeSpeed={40} />
              </div>
            </div>
          )}
        </div>
      {renderUIView()}
    </div>
  );
}