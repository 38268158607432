
import { ssePostRequest } from "../../utils/httpUtils";
import getChunks from "../../utils/GetChunks";
import { useAppInstanceData } from "../useAppInstanceData";

// I DON'T THINK THIS FILE IS USED ANYWHERE MAJOR

export const useAccountingQueryRequest = ({
  addNotification,
  checklistFileID,
  fileType,
  queryValues,
  responseSource,
  setAccountingQueryDone,
  setAccountingQueryOutput,
  setAccountingQuerySubmitPressed,
  setDoneAddToSavedPrompts,
  setHighlightAreas,
  setInitialResult,
  setResponseSource,
  setChunkLocations,
  successfulFileUploads,
  user,
}) => {
  const { appInstanceData } = useAppInstanceData();
  const handleAccountingQuerySubmit = async (event, queryType) => {
    setAccountingQuerySubmitPressed(true);
    setDoneAddToSavedPrompts(false);

    setAccountingQueryOutput("");
    setAccountingQueryDone(false);

    setHighlightAreas([]);
    setChunkLocations([]);

    if (responseSource) {
      console.log("\tClosing SSE connection");
      responseSource.removeEventListener("message");
      responseSource.close();
    }

    event.preventDefault();

    const formData = new FormData(event.target),
      formDataObj = Object.fromEntries(formData.entries());
    const payload_for_query = {
      query: formDataObj.query,
      streaming: true,
      files: [checklistFileID],
      user: user,
      query_type: queryType,
      get_chunks_only: false,
      file_type: fileType,
      app_instance_id: appInstanceData.id,
    };

    const source = ssePostRequest("/api/query_chatbot", payload_for_query, {
      onStatus: (jsonPayload) => {
        console.log("Status:", jsonPayload);
      },
      onStreamingText: (jsonPayload) => {
        setAccountingQueryOutput((output) => {
          return output + jsonPayload.value;
        });
      },
      onFinal: (jsonPayload) => {
        console.log("Final:", jsonPayload);
        setAccountingQueryDone(true);
        getChunks({
          payload: payload_for_query,
          setHighlightAreas,
          setChunkLocations,
          successfulFileUploads,
        });
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    });
    setResponseSource(source);
  };

  return { getChunks, handleAccountingQuerySubmit };
};
