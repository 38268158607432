import React from "react";
import Dropzone from "./Dropzone";
import URLSubmitForm from "./URLSubmitForm";
import {
  XMarkIcon,
  DocumentTextIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

 // https://react-dropzone.org/#!/Accepting%20specific%20file%20types

const FileUpload = ({
  onDrop,
  handleURLSubmit,
  urlInput,
  hideDropzone = false,
  hideURLInput = false,
  testID = "dropzone",
  handleClose,
  uploadedFiles = [],
}) => {
  const toMB = (bytes) => {
    return `${(bytes / (1024*1024)).toFixed(2)} MB`
  }
  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-row justify-between items-start">
        {/* <div>
          <div className="text-customBlack font-medium text-xl">Import your file</div>
          <div className="text-[#5d5d5d] text-base">Please attach the file you want to upload</div>
        </div> */}
        { handleClose && (
          <div onClick={handleClose} className="cursor-pointer">
            <XMarkIcon className="h-5 w-5 text-customHighlightColor" />
          </div>
        ) }
      </div>
      <div className="border-b border-[#F4F6FB] my-4" />
      <div
        className="flex flex-col space-y-4 mb-3"
        data-testid={testID}
        id="dropzone-filetype"
      >
        {!hideDropzone && <Dropzone onDrop={onDrop} />}
      </div>

      <div className="border border-[#E7E7E7] rounded-lg">
        <div className="bg-customGray-30 rounded-lg p-3">
          { uploadedFiles?.length ? (
            <>
              { uploadedFiles.map((f, key) => (
                <div key={key} className="bg-white rounded p-3 shadow-sm my-2">
                  <div className="flex flex-row items-start justify-between">
                    <div className="flex flex-row items-center">
                      { f?.name && (
                        <>
                          <div className="bg-[#F4F6FB] w-10 h-10 flex flex-col justify-center items-center rounded-full">
                            <DocumentTextIcon className="w-4 h-4 text-[#0085FF]" />
                          </div>
                          <div>
                            <div className="ml-2">
                              { f?.name || f?.data?.name }
                            </div>
                            <div className="ml-2 text-sm font-normal text-[#808080]">
                              { toMB(f?.size) }
                            </div>
                          </div>
                        </>
                      ) }
                    </div>
                  </div>
                  { parseInt(f.progress * 100) !== 100 && (
                    <div className="flex flex-row items-center mt-2">
                      <div className="bg-[#F4F6FB] w-full h-3 rounded-full ">
                        <div
                          className="w-0 h-3 rounded-full bg-gradient-to-r from-customHighlightColor to-[#BAA6FF80]"
                          style={{ width: `${parseInt(f.progress * 100)}%` }}
                        />
                      </div>
                      <div className="w-14 text-right text-[#808080] text-sm font-normal">
                        {parseInt(f.progress * 100)}%
                      </div>
                    </div>
                  )}
                </div>
              )) }
            </>
          ) : (
            <div className="flex flex-row items-center bg-white rounded p-3 shadow-sm">
              <div className="flex flex-col justify-center items-center w-14 h-14 bg-[#F4F6FB] p-2 rounded-full">
                <div className="flex flex-col justify-center items-center w-10 h-10 bg-[#E6F3FF] p-2 rounded-full">
                  <InformationCircleIcon className="w-5 h-5 text-[#0085FF]" />
                </div>
              </div>
              <div className="text-[#5D5D5D] ml-3 text-lg font-normal">
                You haven't yet uploaded any documents.
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="border-b border-[#F4F6FB] my-4" />
      <div>
        {!hideURLInput && (
          <URLSubmitForm
            handleURLSubmit={handleURLSubmit}
            urlInput={urlInput}
          />
        )}
      </div>
    </div>
  );
};

export default FileUpload;
