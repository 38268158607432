import { useCallback, useState, useEffect } from "react";
import { ssePostRequest } from "../../utils/httpUtils";
import { uniqueId } from "lodash";

const isValidString = str => typeof str === "string" && str.trim().length > 0;

const useFetchFootnotes = (query, params = {}) => {
    const [footnotes, setFootnotes] = useState([]);
    const [footnotesCount, setFootnotesCount] = useState(null);
    const [companiesCount, setCompaniesCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loadingIDs, setLoadingIDs] = useState([]);
    const [error, setError] = useState(null);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [messagesFromBackend, setMessagesFromBackend] = useState([]);

    const { footnoteTitles, companies, years, industries, isPublic, appInstanceID, accountingBasis } = params;

    const hasFilters = (footnoteTitles || []).length > 0 || 
                       (companies || []).length > 0 || 
                       (years || []).length > 0 || 
                       (industries || []).length > 0 || 
                       (accountingBasis || []).length > 0;

    const resetFootnotesState = () => {
        setFootnotes([]); // Reset footnotes before fetching
        setFootnotesCount(0); // Reset footnotes count
        setProgressPercentage(0); // Reset progress percentage
        setMessagesFromBackend([]); // Reset messages from backend
    }

    /**
     * Refactored fetchFootnotes to optionally accept an overrideQuery while maintaining backward compatibility.
     * If overrideQuery is not provided, it defaults back to the original `query`.
     */
    const fetchFootnotes = async (overrideQuery) => {
        const effectiveQuery = overrideQuery !== undefined ? overrideQuery : query;

        if (!isPublic && (appInstanceID === undefined))
            return;
        
        const requestID = uniqueId();
        setError(null); // Reset error state
        resetFootnotesState();
        setLoadingIDs(loadingIDs => [...loadingIDs, requestID]); // Mark this request as loading
        // Reusable cleanup function to remove requestID from loadingIDs

        const cleanupLoadingState = () => {
            setLoadingIDs(loadingIDs => loadingIDs.filter(id => id !== requestID));
        };
        // Construct query parameters

        const searchParams = new URLSearchParams();
        searchParams.append("page", currentPage);
        searchParams.append("page_size", pageSize);
        if (appInstanceID !== undefined) {
            searchParams.append("app_instance_id", appInstanceID);
        }
        searchParams.append("is_public", isPublic);
        if (isValidString(effectiveQuery)) {
            searchParams.append("query", effectiveQuery.trim());
        }
        (footnoteTitles || []).forEach(title => {
            searchParams.append("footnote_title", title);
        });
        (companies || []).forEach(company => {
            searchParams.append("company", company);
        });
        (years || []).forEach(year => {
            searchParams.append("year", year);
        });
        (industries || []).forEach(industry => {
            searchParams.append("industry", industry);
        });
        (accountingBasis || []).forEach(basis => {
            searchParams.append("accounting_basis", basis);
        });

        const queryString = searchParams.toString();
        try {
            ssePostRequest(`/api/footnotes/?${queryString}`, null, {
                onStatus: (statusData) => {
                    console.log("Status Update:", statusData);
                },
                onProgress: ({value}) => {
                    const { step_number, total_steps } = value;
                    const progress = Math.floor((step_number / total_steps) * 100);
                    setProgressPercentage(progress);
                },
                onStreamingText: ({value}) => {
                    setMessagesFromBackend(messages => [...messages, value]);
                },
                onFinal: ({value}) => {
                    setFootnotes(value.footnotes);
                    setFootnotesCount(value.count);
                    setCompaniesCount(value.companies_count);
                    setProgressPercentage(100);
                    cleanupLoadingState();
                },
                onError: (errorData) => {
                    console.error("Error occurred:", errorData);
                    setError("Failed to fetch footnotes.");
                    resetFootnotesState();
                    cleanupLoadingState();
                },
                onMetadata: (metadataData) => {
                    console.log("Metadata received:", metadataData);
                },
            });
        } catch (error) {
            console.error("Error setting up SSE request:", error);
            setError("Failed to fetch footnotes.");
            resetFootnotesState();
            cleanupLoadingState();
        }
    }

    // Optional helper function to show how you might provide a different entry point
    // for passing a query explicitly. This is not required, but an option.
    // Adding this function to maintain clarity that fetchFootnotes now accepts a query.
    // Comment: Introduced this helper for explicit query overrides.  
    const fetchFootnotesWithQuery = (newQuery) => {
        return fetchFootnotes(newQuery);
    };

    useEffect(() => {            
        // Maintains backwards compatibility: fetchFootnotes() with no args uses the original query.
        fetchFootnotes();
    }, [currentPage, pageSize, appInstanceID, footnoteTitles, companies, years, industries, isPublic, accountingBasis]);

    useEffect(() => {
        setCurrentPage(1);
    }, [pageSize]);

    const navigateNext = useCallback(() => {
        setCurrentPage((prevPage) => prevPage + 1);
    }, []);

    const navigatePrevious = useCallback(() => {
        setCurrentPage((prevPage) => prevPage - 1);
    }, []);

    const setPageCallback = useCallback((page) => {
        if (page < 1 || page > Math.ceil(footnotesCount / pageSize)) {
            return;
        }
        setCurrentPage(page);
    }, [footnotesCount, pageSize]);

    const setPageSizeCallback = useCallback((size) => {
        if (size < 1) {
            return;
        }
        setPageSize(size);
    }, []);

    const resetToFirstPage = useCallback(() => {
        setCurrentPage(1);
    }, []);

    const hasNextPage = footnotesCount > currentPage * pageSize;
    const hasPreviousPage = currentPage > 1;
    const totalPages = Math.ceil(footnotesCount / pageSize);

    return {
        companiesCount,
        error,
        fetchFootnotes,
        fetchFootnotesWithQuery,
        footnotes,
        footnotesCount,
        hasFilters,
        hasNextPage,
        hasPreviousPage,
        loading: loadingIDs.length > 0,
        messagesFromBackend,
        navigateNext,
        navigatePrevious,
        pageSize,
        progressPercentage,
        resetToFirstPage,
        setCurrentPage: setPageCallback,
        setPageSize: setPageSizeCallback,
        totalPages,
    };
}

export default useFetchFootnotes;
