import React, { useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import RedirectToSignIn from "../utils/RedirectToSignIn";

import Home from "../Home";
import InvoiceCrossReference from "../apps/crossReference/InvoiceCrossReference";
import AuditChecklist from "../AuditChecklist";
import BoardMinutesAnalyzer from "../apps/boardMinutesAnalyzer/BoardMinutesAnalyzer";
import PCAOBAnalyzer from "../apps/pcaobAnalyzer/pcaobAnalyzer";
import SignIn from "../SignIn";
import Profile from "../Profile";
import Logs from "../admin/Logs";
import Feedback from "../admin/Feedback";
import Apps from "../Apps";
import MyApps from "../MyApps";
import CreateApp from "../appManipulation/CreateApp";
import EditApp from "../appManipulation/EditApp";
import AppDetail from "../AppDetail";
import GeneralDataChatAnalyzer from "../apps/generalDataChatAnalyzer/GeneralDataChatAnalyzer";
import CompanyApps from "../CompanyApps";
import Changelog from "../screens/changelog/Changelog";
import PublishApp from "../appManipulation/PublishApp";
import FootnoteAI from "../apps/FootnoteAI/FootnoteAI";
import GenerateAFootnote from "../apps/GenerateAFootnote/GenerateAFootnote";
import GenericExtractor from "../apps/GenericExtractor/GenericExtractor";
import FootnotesDatabase from "../apps/footnotesDatabase/FootnotesDatabase";
import SuperApp from "../SuperApp";
import { getRequest } from "../utils/httpUtils";
import DummyApp from "../apps/DummyApp";
import InternalConsistencyCheck from "../apps/InternalConsistencyCheck/InternalConsistencyCheck";

/**
 * Protects routes to ensure that only signed-in users can access them, redirecting
 * others to the sign-in page. It helps in removing the redundancy of checking
 * authentication status in every component.
 *
 * @param {{ children: JSX.Element }} props The child component(s) to be rendered within the protected route.
 * @returns {JSX.Element} A component that renders the children if the user is signed in, otherwise redirects to the sign-in page.
 */
const ProtectedRoute = ({ children }) => (
  <>
    <SignedIn>{children}</SignedIn>
    <SignedOut>
      <RedirectToSignIn />
    </SignedOut>
  </>
);

const ProtectedRouteWithKey = ({ children }) => {
  const { instance_id, conversation_uuid } = useParams();
  const key = `${instance_id}-${conversation_uuid}`;
  return <ProtectedRoute key={key}>{children}</ProtectedRoute>;
};

const Router = () => {
  const [superAppRoutes, setSuperAppRoutes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest("/api/get_superapps");

        setSuperAppRoutes(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };

    fetchData();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/board_minutes_analyzer/:instance_id?"
        element={
          <ProtectedRoute>
            <BoardMinutesAnalyzer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/fs_footnote_extractor/:instance_id"
        element={
          <ProtectedRoute key="Footnote Extractor">
            <GenericExtractor type="10k" />
          </ProtectedRoute>
        }
      />
      {superAppRoutes?.map((route, index) => (
        <Route
          key={index}
          path={`/${route[0]}`}
          element={
            <ProtectedRoute key={`superAppRoute-${route[0]}`}>
              <SuperApp superAppSlug={route[0]} />
            </ProtectedRoute>
          }
        />
      ))}
      <Route
        path="/footnote_ai0/:instance_id"
        element={
          <ProtectedRoute key="Footnote AI">
            <FootnoteAI />
          </ProtectedRoute>
        }
      />
      <Route
        path="/generate_a_footnote/:instance_id"
        element={
          <ProtectedRoute key="Generate A Footnote">
            <GenerateAFootnote />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pdf_tables_to_csvs/:instance_id"
        element={
          <ProtectedRoute key="All Tables">
            <GenericExtractor type="tables" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/invoice_cross_reference/:instance_id?"
        element={
          <ProtectedRoute>
            <InvoiceCrossReference />
          </ProtectedRoute>
        }
      />
      <Route
        path="/audit_checklist/:instance_id?"
        element={
          <ProtectedRoute>
            <AuditChecklist />
          </ProtectedRoute>
        }
      />
      <Route
        path="/general_data_chat/:instance_id?/:conversation_uuid?"
        element={
          <ProtectedRouteWithKey>
            <GeneralDataChatAnalyzer fileType="General Data Chat" />
          </ProtectedRouteWithKey>
        }
      />
      {/* <Route
        path="/general_data_chat/:instance_id?/approved_qa"
        element={
          <ProtectedRouteWithKey>
            <ApprovedQuestionsAnswers />
          </ProtectedRouteWithKey>
        }
      /> */}
      <Route
        path="/pcaob_analzyer/:instance_id?"
        element={
          <ProtectedRouteWithKey>
            <PCAOBAnalyzer />
          </ProtectedRouteWithKey>
        }
      />
      <Route path="/apps_l1" element={<Apps layer={1} />} />
      <Route path="/apps_l2" element={<Apps layer={2} />} />
      <Route
        path="/myapps"
        element={
          // <ProtectedRoute key="App Select">
          <MyApps />
          // </ProtectedRoute>
        }
      />
      <Route
        path="/footnotes_database/:instance_id"
        element={
          <ProtectedRoute key="Footnotes Database">
            <FootnotesDatabase isPublic={false} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/internal_consistency_check/:instance_id"
        element={
          <ProtectedRoute key="internal_consistency_check">
            <InternalConsistencyCheck />
          </ProtectedRoute>
        }
      />
      <Route
        path="/roll_forward_financial_statements/:instance_id"
        element={
          <ProtectedRoute key="roll_forward_financial_statements">
            <DummyApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/10k-footnote-search/:instance_id?"
        element={<FootnotesDatabase isPublic={true} />}
      />
      <Route
        path="/company_apps"
        element={
          <ProtectedRoute key="Company App Select">
            <CompanyApps />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/create/:app"
        element={
          <ProtectedRoute key="Application">
            <CreateApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/edit/:instance_id"
        element={
          <ProtectedRoute key="Application Edit">
            <EditApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/publish/:app_instance_id"
        element={
          <ProtectedRoute key="Application Publish">
            <PublishApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/view/:app"
        element={
          <ProtectedRoute key="View Application">
            <AppDetail />
          </ProtectedRoute>
        }
      />
      {/* <Route path="/sign-in/*" element={<SignIn />} />
      <Route path="/sign-up/*" element={<SignIn />} /> */}
      <Route path="/sign_in" element={<SignIn />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin_users"
        element={
          <ProtectedRoute>
            <Logs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin_feedback"
        element={
          <ProtectedRoute>
            <Feedback />
          </ProtectedRoute>
        }
      />
      <Route path="/changelog" element={<Changelog />} />
    </Routes>
  );
};

export default Router;
