import React, { useEffect, useRef, useState } from 'react';
import { renderDocument, parseAsync } from 'docx-preview';

/**
 * A React component for rendering and displaying.docx documents fetched from a URL.
 *
 * This component fetches a .docx document from the provided URL, parses it, and renders it within a specified container element.
 * It handles errors gracefully and logs them for debugging purposes.
 *
 * @component
 * @example
 * // Usage example
 * <DocxViewer docUrl="/path/to/document.docx" />
 */
const DocxViewer = ({ docUrl }) => {
  const containerRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const response = await fetch(docUrl);
        if (!response.ok) {
          setError(Error('Failed to fetch the document. The network response was not ok.'));
          return;
        }
        const documentBlob = await response.blob();
        const parsedDocument = await parseAsync(documentBlob);
        await renderDocument(parsedDocument, containerRef.current, null, { ignoreWidth: true });
        setError(null);
      } catch (err) {
        setError(err);
      }
    };

    if (docUrl && containerRef.current) {
      fetchDoc();
    }
  }, [docUrl, containerRef.current]);

  useEffect(() => {
    if (!error) return;
    console.log("Error on docx viewer", error)
  }, [error])

  return <div style={{height: "750px", maxHeight: "750px"}} test-id="docx-container" ref={containerRef}></div>;
};

export default DocxViewer;
