import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

const ClipboardButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  return (
    <>
      <button onClick={copyToClipboard} className="h-4 w-4 shrink-0">
        <ClipboardDocumentCheckIcon className="h-5 w-5 text-gray-600 hover:text-customHighlightColor" />
      </button>
      {copied && <p className="text-xs text-gray-600">Copied to clipboard!</p>}
    </>
  );
};

export default ClipboardButton;