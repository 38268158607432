// Dropzone.js
import React from "react";
import { useDropzone } from "react-dropzone";
import {
  docxMimeType,
  docxExtConst,
  xlsxExtConst,
  pdfExtConst,
  pdfMimeType,
  xlsxMimeType,
} from "../constants";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

/**
 * Dropzone component for file uploads.
 *
 * @component
 * @example
 * ```jsx
 * <Dropzone onDrop={handleDrop} />
 * ```
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.onDrop - Callback function to handle file drop events.
 * @param {string} [props.dragText=...] - Text to display when no files are being dragged.
 *
 * @returns {React.Element} The Dropzone component.
 */
export const Dropzone = ({
  onDrop,
  dragText = "Drop your files or click to browse",
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      [pdfMimeType]: pdfExtConst,
      [docxMimeType]: docxExtConst,
      [xlsxMimeType]: xlsxExtConst,
    },
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className={`h-48 border-1 border-dashed border-[#BAA6FF] rounded-lg flex justify-center items-center text-center p-1 ${
        isDragActive ? "bg-customHighlightColor text-white" : "text-grey"
      }`}
      data-dropzone
      id="dropzone"
    >
      <input {...getInputProps()} />
      <div
        className={`flex space-x-4 flex-col items-center justify-center space-y-4 text-[#5d5d5d]`}
      >
        <CloudArrowUpIcon className="h-10 w-10 text-sm bg-customHighlightColor text-white rounded-full p-2" />
        <p>{dragText}</p>
      </div>
    </div>
  );
};

export default Dropzone;
