import { useState, useCallback } from 'react';
import { postRequest } from '../utils/httpUtils';

/**
 * Custom React hook to encapsulate the logic for editing a prompt via an API call.
 * 
 * This hook provides functionality to edit a prompt by making a POST request to a specific endpoint with the prompt ID, user information, app instance ID, and the new prompts data.
 * It manages loading and error states internally, and exposes a performAction function to trigger the editing process along with the current loading and error states.
 * 
 * @param {string} appInstanceId - The unique identifier for the application instance under which the prompt resides.
 * @param {string} user - The user information associated with the prompt.
 * @returns {{ editPrompt: function, loading: boolean, error: any }} An object containing the performAction function to initiate prompt editing, and states for loading and error handling.
 * 
 * - `editPrompt`: A function that accepts a promptId and newPrompts and handles the editing process. It sets loading to true at the start, attempts to edit the prompt by making a POST request,
 *   and updates loading and error states accordingly based on the outcome of the request.
 * - `loading`: A boolean indicating whether the editing process is ongoing.
 * - `error`: Any error encountered during the editing process.
 * 
 * @example
 * // Example usage in SomeComponent.js:
 * const { editPrompt: performEditPrompt } = useEditPrompt(instanceId, user);
 * performEditPrompt(promptId, newPrompts); // Initiates editing of a prompt with the given promptId under the current appInstanceId and user context.
 */
const useEditPrompt = (appInstanceId, user) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const editPrompt = useCallback(async (promptId, newPrompt) => {
    setLoading(true);
    setError(null);

    try {
      await postRequest(`/api/edit_prompt`, {
        id: promptId,
        user,
        app_instance_id: appInstanceId,
        new_prompts: newPrompt,
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [appInstanceId, user]);

  return { editPrompt, loading, error };
};

export default useEditPrompt;
