import { useState, useCallback, useEffect, useRef } from "react";
import { useUser } from "@clerk/clerk-react";
import { useDispatch } from "react-redux"
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import { useQueryType } from "../../hooks/useQueryType";
import { useAccountingQueryRequest } from "../../hooks/ragQuery/useAccountingQueryRequest";
import { useFetchSavedPrompts } from "../../hooks/ragQuery/useFetchSavedPrompts";
import { ssePostRequest } from "../../utils/httpUtils";
import { handleAddToSavedPrompts } from "../../hooks/addToSavePrompts";
import { uploadDemoFiles as uploadDemoFilesInternal } from "../../hooks/uploadDemoFiles";
import { handleFileUpload } from "../../utils/FileUpload/handleFileUpload";
import { logEvent } from "../../redux/reducers/app.reducer";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";

export const useBoardMinutesAnalyzer = () => {
  const dispatch = useDispatch()
  const { isSignedIn, user, isLoaded } = useUser();
  const [initialResult, setInitialResult] = useState(null);
  const [highlightAreas, setHighlightAreas] = useState([]);
  const { addNotification } = useNotifications();
  const [chunkLocations, setChunkLocations] = useState([]);

  const [accountingQuerySubmitPressed, setAccountingQuerySubmitPressed] =
    useState(false);
  const [queryType, setQueryType, queryTypeError, queryTypes, queryHeaderText] =
    useQueryType();
  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(logEvent({
        user,
        event: `Looked at Board Minutes Analyzer.`
      }));
    }
  }, [isLoaded, isSignedIn, user]);
  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable
  const [filesDropped, setFilesDropped] = useState(false);
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentReportURL, setCurrentReportURL] = useState(null);
  const [listOfDOSLines, setListOfDOSLines] = useState([]);
  const [numberOfReports, setNumberOfReports] = useState(0);
  const [boardMinuteDetails, setBoardMinutesDetails] = useState([]);
  const [responseSource, setResponseSource] = useState(null);
  const [reportDetails, setReportDetails] = useState(null);
  const [checklistPDFURL, setChecklistPDFURL] = useState(null);
  const [checklistFileID, setChecklistFileID] = useState(null);
  const [accountingQueryOutput, setAccountingQueryOutput] = useState(null);
  const [accountingQueryDone, setAccountingQueryDone] = useState(false); // Not used but may be
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [queryValues, setQueryValues] = useState([]); // TODO: Rename this state variable to checklistQuestions as queryValues is ambiguous
  const [doneAddToSavedPrompts, setDoneAddToSavedPrompts] = useState(false);
  const queryInput = useRef(null);
  const [currentSource, setCurrentSource] = useState(null);
  const { appInstanceData }  = useAppInstanceData()

  const { fetchData: fetchSavedPrompts } = useFetchSavedPrompts({
    user,
    queryType,
    setSavedPrompts,
    addNotification,
    docType: "Board Minutes Analyzer",
    app_instance_id: appInstanceData.id,
  });

  const { handleAccountingQuerySubmit } = useAccountingQueryRequest({
    addNotification,
    checklistFileID,
    fileType: "",
    queryValues,
    responseSource,
    setAccountingQueryDone,
    setAccountingQueryOutput,
    setAccountingQuerySubmitPressed,
    setDoneAddToSavedPrompts,
    setHighlightAreas,
    setInitialResult,
    setResponseSource,
    setChunkLocations,
    successfulFileUploads,
    user,
  });

  const handleAddToSavedPromptsButton = (event) =>
    handleAddToSavedPrompts({
      event,
      queryType,
      queryInput,
      queryValues,
      savedPrompts,
      setSavedPrompts,
      setDoneAddToSavedPrompts,
      user,
      fileType: "Board Minutes Analyzer",
      addNotification,
      app_instance_id: appInstanceData.id,
    });

  const parsePDF = (fileName, fileID) => {
    ssePostRequest(
      "/api/board_minutes_analyzer",
      { file: fileID },
      {
        onStatus: (jsonPayload) => {
          setListOfDOSLines((prevLines) => [
            ...prevLines,
            jsonPayload["value"],
          ]);
        },
        onFinal: (jsonPayload) => {
          setBoardMinutesDetails((current) => [
            ...current,
            jsonPayload["value"],
          ]);
          setNumberOfReports((prevNumber) => prevNumber + 1);
          setListOfDOSLines((prevLines) => [
            ...prevLines,
            "Successfully extracted data from " + fileName,
          ]);
        },
      }
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = () => {
        setFilesDropped(true);

        if (responseSource) {
          responseSource.removeEventListener("message");
          responseSource.close();
        }
      };

      const thenAction = ({ file, fileID, response }) => {
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        console.log("Running parse PDF on ");
        console.log(file.name);
        parsePDF(file.name, fileID);
        setListOfDOSLines((prevLines) => [
          ...prevLines,
          "Successfully uploaded " + file.name,
        ]);
      };

      const catchAction = ({ fileID }) => {
        setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
        setSuccessfulFileUploads((successfulFileUploads) =>
          successfulFileUploads.filter(
            (file) => file.data["file_id"] !== fileID
          )
        );
      };
      handleFileUpload(
        acceptedFiles,
        { addNotification, fileType: "", user },
        { preAction, thenAction, catchAction }
      );
    },
    [user]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("SUCCESSFUL FILE UPLOADS!");
      console.log(successfulFileUploads);
      setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
      setCurrentReportURL(successfulFileUploads[0].data["file_url"]);
      setCurrentSource(successfulFileUploads[0]);
    } else {
      setCurrentReportURL(null);
      setFilesDropped(false);
    }
  }, [successfulFileUploads]);

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      setChecklistFileID(fileID => {
        if(fileID === null) {
          // This ensures that if no file ID is provided, the first successful file upload's ID is used.
          // This fix addresses the issue where the board minutes analyzer query would fail if a file wasn't selected first.
          return successfulFileUploads[0].data["file_id"];
        }
        return fileID;
      });
    }
  }, [checklistFileID, successfulFileUploads, setChecklistFileID]);

  const sampleFiles = [
    "https://tellensamplefiles.blob.core.windows.net/main/2023%20New%20York%20City%20Loft%20Board%20Minutes/minutes-20230119.pdf",
    "https://tellensamplefiles.blob.core.windows.net/main/2023%20New%20York%20City%20Loft%20Board%20Minutes/minutes-20230216.pdf",
    "https://tellensamplefiles.blob.core.windows.net/main/2023%20New%20York%20City%20Loft%20Board%20Minutes/minutes-20230316.pdf",
    "https://tellensamplefiles.blob.core.windows.net/main/2023%20New%20York%20City%20Loft%20Board%20Minutes/minutes-20230518.pdf",
    // "https://tellensamplefiles.blob.core.windows.net/main/2023%20New%20York%20City%20Loft%20Board%20Minutes/minutes-20230615.pdf",
    // "https://tellensamplefiles.blob.core.windows.net/main/2023%20New%20York%20City%20Loft%20Board%20Minutes/minutes-20230921.pdf",
    // "https://tellensamplefiles.blob.core.windows.net/main/2023%20New%20York%20City%20Loft%20Board%20Minutes/minutes-20231019.pdf",
    // "https://tellensamplefiles.blob.core.windows.net/main/2023%20New%20York%20City%20Loft%20Board%20Minutes/minutes-20231116.pdf",
  ];

  const uploadDemoFiles = () =>
    uploadDemoFilesInternal({
      sampleFiles,
      setFilesDropped,
      setSuccessfulFileUploads,
      user,
      onStartUpload: (file) => {
        setListOfDOSLines((prevLines) => [
          ...prevLines,
          "Uploading " + file.filename + "...",
        ]);
      },
      onSuccessfulUpload: (response, file, fileID) => {
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        setListOfDOSLines((prevLines) => [
          ...prevLines,
          "Successfully uploaded " + file.filename,
        ]);
        parsePDF(file.filename, fileID, file.url);
      },
      onErrorUpload: () => {
        addNotification("Error uploading file.", "", NotificationType.error);
      },
    });

  const resetAnalyzerState = () => {
    setAccountingQueryOutput(null);
    setAccountingQueryDone(false);
    setHighlightAreas([]);
    setChunkLocations([]);

    if (queryInput && queryInput.current !== null) {
      queryInput.current.value = "";
    }
  };

  const analyzerContext = {
    accountingQueryDone,
    accountingQueryOutput,
    accountingQuerySubmitPressed,
    boardMinuteDetails,
    checklistFileID,
    checklistPDFURL,
    currentReportURL, // Deprecated
    currentSource,
    doneAddToSavedPrompts,
    filesDropped,
    handleAccountingQuerySubmit,
    handleAddToSavedPromptsButton,
    highlightAreas,
    isLoaded,
    isSignedIn,
    numberOfChecklists: 0,
    numberOfReports,
    onDrop,
    parsePDF,
    queryHeaderText,
    queryInput,
    queryTypeError,
    queryType,
    queryTypes,
    queryValues,
    reportDetails,
    resetAnalyzerState,
    responseSource,
    savedPrompts,
    setAccountingQueryDone,
    setAccountingQueryOutput,
    setChecklistFileID,
    setChecklistPDFURL,
    setCurrentReportURL,
    setCurrentSource,
    setDoneAddToSavedPrompts,
    setFilesDropped,
    setHighlightAreas,
    setQueryType,
    setQueryValues,
    setReportDetails,
    setResponseSource,
    setSavedPrompts,
    setSuccessfulFileUploads,
    setChunkLocations,
    successfulFileUploads,
    chunkLocations,
    uploadDemoFiles,
    user,
  };

  // Modify the return statement to include the new dictionary:
  return {
    analyzerContext,
    fetchSavedPrompts,
    fileIDs,
    setFileIDs,
    listOfDOSLines,
    setListOfDOSLines,
  };
};
