import React from 'react';

const categories = ['general', 'audit', 'tax'];

const CategorySelect = ({ onChange }) => {
  return (
    <>
      <label className="block mb-2 font-bold text-gray-700">
        Select a category:
      </label>
      <select
        onChange={onChange}
        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm capitalize"
        required // This makes the selection required
      >
        <option value="" disabled selected>
          Choose a category
        </option>
        {categories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
    </>
  );
};

export default CategorySelect;
