import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { logEvent } from "../redux/reducers/app.reducer";
import { postRequest } from "../utils/httpUtils";
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../utils";

const ExpandedEventDetails = ({ events, userId }) => {
  const filteredEvents = useMemo(() => {
    if (!events) return [];
    return events.filter((event) => event.user_id === userId);
  }, [events, userId]);
  return (
    <tr>
      <td colSpan="3" className="bg-gray-50">
        <div className="py-2 px-4">
          {
            //Here begins the feed
          }
          <div className="flow-root">
            <ul className="-mb-8 divide-y divide-gray-200">
              {filteredEvents
                .map((userEvents, index) => {
                  // If there are no events, return a message or null
                  if (
                    !userEvents.events.length ||
                    userEvents.events.length === 0
                  ) {
                    return (
                      <li key={`no-events-${index}`} className="py-3">
                        <div className="text-center text-sm text-gray-500">
                          No events to display
                        </div>
                      </li>
                    );
                  }

                  // If there are events, map them into list items
                  return userEvents.events.map((event, eventIndex) => (
                    <li key={eventIndex} className="py-3">
                      <div className="flex space-x-3">
                        <div className="flex-shrink-0 w-40 text-sm basis-1/12">
                          <time dateTime={event.datetime}>
                            {formatTimestamp(event.timestamp)}
                          </time>
                        </div>
                        <div className="flex-grow min-w-0 basis-1/12">
                          <p className="text-sm text-gray-900">
                            <Link
                              to={`/${event.app_slug}/${event.app_instance_id}`}
                            >
                              {event.app_instance_name}
                            </Link>
                          </p>
                        </div>
                        <div className="flex-grow min-w-0 basis-1/12">
                          <p className="text-sm text-gray-900">
                            {event.binary_feedback === "positive" && (
                              <HandThumbUpIcon
                                className={`h-5 w-5 transition-colors transform mt-1`}
                              />
                            )}
                            {event.binary_feedback === "negative" && (
                              <HandThumbDownIcon
                                className={`h-5 w-5 transition-colors transform mt-1`}
                              />
                            )}
                          </p>
                        </div>
                        <div className="flex-grow min-w-0 basis-1/12">
                          <p className="text-sm text-gray-900">
                            {event.text_feedback}
                          </p>
                        </div>
                        <div className="flex flex-col flex-grow min-w-0 basis-8/12 gap-1">
                          {(event.context || []).map((context, index) => (
                            <div key={`${index}-${context.message}`}>
                              <span className="text-sm text-black capitalize font-bold">
                                {context.role === "assistant" ? "Tellen" : context.role}:
                              </span>{" "}
                              <span className="text-sm text-gray-900">
                                {context.message}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                  ));
                })}
            </ul>
          </div>
        </div>
      </td>
    </tr>
  )
};

const UserTableRow = ({ user, events }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => setExpanded((prev) => !prev), []);
  return (
    <>
      <tr onClick={toggle} className="cursor-pointer hover:bg-gray-100">
        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
          <div className="flex items-center">
            <div className="h-11 w-11 flex-shrink-0">
              <img
                className="h-11 w-11 rounded-full"
                src={user.image}
                alt=""
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">{user.name}</div>
              <div className="mt-1 text-gray-500">{user.email}</div>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900">
            {user.last_active === user.signed_up ? "-" : user.last_active}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900">{user.signed_up}</div>
        </td>
      </tr>
      {expanded && (
        <ExpandedEventDetails events={events} userId={user.id} />
      )}
    </>
  );
};

const SlackEventsTable = ({ events }) => {
  const [showEvents, setShowEvents] = useState(false);
  const toggleShowEvents = useCallback(() => setShowEvents((prev) => !prev), []);
  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table className="min-w-full divide-y divide-gray-300">
          <tr className="cursor-pointer hover:bg-gray-100" onClick={toggleShowEvents}>
            <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
              <div className="flex items-center">
                <div className="font-semibold text-gray-900">Slack events</div>
              </div>
            </td>
          </tr>
          {showEvents && <ExpandedEventDetails events={events} userId={null} />}
        </table>
      </div>
    </div>
  )
}

const Feedback = () => {
  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(
        logEvent({
          user,
          event: "Looked at users table.",
        })
      );
    }
  }, [isLoaded, isSignedIn, user]);

  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postRequest("/api/admin/feedback", {
          user: user,
        });

        setUsers(response.data["users"]);
        setEvents(response.data["events"]);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };

    if (isSignedIn) {
      fetchData();
    }
  }, [isLoaded, isSignedIn, user]);

  return (
    <>
      <h1 className="text-xl font-bold mb-4">Chat Feedback</h1>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name and Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Last Active
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Signed Up
                  </th>
                  {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    ></th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users && users.map((user) => (
                  <UserTableRow
                    key={user.email}
                    user={user}
                    events={events}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <SlackEventsTable events={events} />
      </div>
    </>
  );
};

export default Feedback;
