import React, { useState, useEffect, useRef, useMemo } from "react";
import Spreadsheet from "react-spreadsheet";
import * as XLSX from "xlsx";

const getButtonClass = (isSelected) => {
  return `text-white text-sm py-1 px-2 mr-1 rounded text-center hover:bg-customHighlightColor ${
    isSelected ? "bg-customHighlightColor" : "bg-gray-600 opacity-50"
  }`;
};

const ExcelViewer = ({ fileUrl }) => {
  const [sheets, setSheets] = useState([]);
  const [currentSheet, setCurrentSheet] = useState("");
  const [loading, setLoading] = useState(true);
  const workbookRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(fileUrl);
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
          type: "array",
        });
        workbookRef.current = workbook; // Store the workbook in useRef
        const sheetNames = workbook.SheetNames;
        setSheets(sheetNames);
        setCurrentSheet(sheetNames[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fileUrl]);

  // Memoize the sheet data calculation
  const sheetData = useMemo(() => {
    if (currentSheet && workbookRef.current) {
      const worksheet = workbookRef.current.Sheets[currentSheet];
      const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      let maxColumns = 0;
      jsonSheet.forEach((row) => {
        maxColumns = Math.max(maxColumns, row.length);
      });

      /**
       * For now this limits the number of columns to 5 and the number of rows to 5 as well.
       */
      maxColumns = Math.min(maxColumns, 1000000); // Maximum 5 columns

      const processedSheetData = jsonSheet.map((row) => {
        const newRow = new Array(maxColumns);
        for (let i = 0; i < maxColumns; i++) {
          if (row[i] === undefined) {
            newRow[i] = { value: "", readonly: true };
          } else {
            newRow[i] = { value: row[i], readonly: true };
          }
        }
        return newRow;
      });

      return processedSheetData.slice(0, 2000000); // Maximum 5 rows
    }
    return [];
  }, [currentSheet, workbookRef.current]); // Recompute only when currentSheet or workbook changes

  const handleSheetChange = (sheetName) => {
    setCurrentSheet(sheetName);
  };

  return (
    <div style={{ height: "750px", overflow: "auto" }}>
      <div style={{ marginBottom: "1rem" }}>
        {sheets.map((sheetName) => (
          <button
            key={sheetName}
            onClick={() => handleSheetChange(sheetName)}
            className={getButtonClass(currentSheet === sheetName)}
            style={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}
          >
            {sheetName}
          </button>
        ))}
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="overflow-scroll flex-grow max-h-full max-w-full">
          <Spreadsheet data={sheetData} />
        </div>
      )}
    </div>
  );
};

export default ExcelViewer;
