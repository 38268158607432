import React, { useState, useEffect } from "react";

/**
 * `TypingDots` is a React functional component that simulates a typing animation with three dots, followed by a blinking cursor.
 * 
 * The component uses React hooks (`useState` and `useEffect`) to manage and render the animation.
 * It alternates between displaying 0 to 3 dots, mimicking a typing effect, and toggles the visibility of the cursor, creating a blinking effect.
 *
 * The typing effect of the dots is controlled by a setInterval function that adds a dot every 250 milliseconds until three dots are displayed, and then resets.
 * The blinking cursor effect is achieved through another setInterval function that toggles the cursor's visibility every 500 milliseconds.
 * 
 * Both intervals are set up in the `useEffect` hook, which ensures that they are cleared when the component is unmounted to prevent memory leaks.
 * 
 * Returns:
 * - A JSX fragment containing the current state of `dots` and the cursor, where the cursor's visibility is controlled by the `cursorVisible` state.
 *
 * Note:
 * - The timing intervals for the dot typing speed and cursor blink speed can be adjusted as needed.
 */

const TypingDots = () => {
  const [dots, setDots] = useState("");
  const [cursorVisible, setCursorVisible] = useState(true);

  useEffect(() => {
    // Dot typing effect
    const dotInterval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 250); // Adjust time for dot typing speed

    // Cursor blinking effect
    const cursorInterval = setInterval(() => {
      setCursorVisible((prevVisible) => !prevVisible);
    }, 200); // Adjust time for cursor blink speed

    return () => {
      clearInterval(dotInterval);
      clearInterval(cursorInterval);
    };
  }, []);

  return (
    <>
      {dots}
      {cursorVisible ? "|" : ""}
    </>
  );
};

export default TypingDots;