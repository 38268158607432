import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
// Create a context
const NotificationsContext = createContext();

// Create a custom hook
export const useNotifications = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationsProvider"
    );
  }

  return context;
};

// Create a provider component
export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((title, message, iconType) => {
    setNotifications((prevNotifications) => [
      { id: uuidv4(), content: { title, message }, iconType },
      ...prevNotifications,
    ]);
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  const value = {
    notifications,
    addNotification,
    removeNotification,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const NotificationType = {
  success: "success",
  warning: "warning",
  error: "error",
};

export const NotificationContent = React.forwardRef(
  ({ currentNotification, removeNotification }, ref) => {
    let IconComponent;
    let iconClassName = "h-6 w-6 ";
    switch (currentNotification?.iconType) {
      case NotificationType.success:
        IconComponent = CheckCircleIcon;
        iconClassName += "text-green-400";
        break;
      case NotificationType.warning:
        IconComponent = ExclamationCircleIcon;
        iconClassName += "text-yellow-400";
        break;
      case NotificationType.error:
        IconComponent = XCircleIcon;
        iconClassName += "text-red-400";
        break;
    }

    useEffect(() => {
      const timer = setTimeout(() => {
        removeNotification(currentNotification.id);
      }, 2000);

      return () => clearTimeout(timer); // This will clear the timer when the component unmounts
    }, [currentNotification, removeNotification]);

    return (
      <div
        className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50"
        ref={ref}
      >
        {!!currentNotification && (
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <IconComponent data-testid="icon" className={iconClassName} aria-hidden="true" />
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">
                  {currentNotification?.content.title}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {currentNotification?.content.message}
                </p>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => removeNotification(currentNotification.id)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export const Notifications = () => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <p></p>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          {notifications && notifications.map((notification) => (
            <Transition
              key={notification.id}
              show={true}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Transition.Child
                as={NotificationContent}
                currentNotification={notification}
                removeNotification={removeNotification}
              />
            </Transition>
          ))}
        </div>
      </div>
    </>
  );
};
