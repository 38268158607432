import React, { useState, useEffect } from "react";
import AppsListLong from "./appsPage/AppsListLong";
import { postRequest } from "./utils/httpUtils";
import { getAppURL } from "./utils/utils";

const SuperApp = ({ superAppSlug }) => {
  const [superApp, setSuperApp] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postRequest("/api/get_superapp", {
          superapp_slug: superAppSlug,
        });

        const dataUpdatedWithURLs = {
          ...response.data,
          app_instances: response.data.app_instances.map((appInstance) => ({
            ...appInstance,
            url: getAppURL(appInstance),
          })),
        };

        setSuperApp(dataUpdatedWithURLs);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };
    if (superAppSlug) {
      fetchData();
    }
  }, []);

  return (
    <div className="h-screen">
      <div className="flex flex-col">
        <div className="flex">
          <div className="w-2/3 flex flex-col">
            <h1 className="text-4xl font-medium mb-5 text-gradient-to-r from-blue-600 via-green-500 to-indigo-400">
              {superApp["name"]}
            </h1>
            {/* <SignedIn>
                <Link to="/invoice_cross_reference">
                  <button
                    // type="submit"
                    className="transition-colors duration-300 bg-customBlack hover:bg-customHighlightColor text-customLightGray hover:text-customLightGray font-bold p-3 rounded"
                  >
                    Try the Invoice Cross Reference tool
                  </button>
                </Link>
              </SignedIn> */}
            {/* <SignedOut>
                <Link to="/sign_in">
                  <button
                    // type="submit"
                    className="transition-colors duration-300 bg-customBlack hover:bg-customHighlightColor text-customLightGray hover:text-customLightGray font-bold p-3 rounded"
                  >
                    Sign up to get started
                  </button>
                </Link>
              </SignedOut> */}
          </div>
        </div>

        {/* <AppsList
          pageName=""
          appsArray={
            superApp &&
            superApp["appInstances"] &&
            superApp["app_instances"].length > 0 &&
            superApp["app_instances"][0].length > 0
          }
        /> */}

        <div className="text-gray-800 font-medium">{superApp["description"]}</div>

        <AppsListLong pageName="" appsArray={superApp["app_instances"]} />
      </div>

      {/* Right Panel (1/3 width) */}
      {/* <div className="w-1/3 flex-grow p-2">
        {faqs.length > 0 && <h1 className="text-2xl font-bold mb-5">FAQs</h1>}
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div> */}
    </div>
  );
  // const appData = useAppList();
  // return <AppsList pageName="My Apps" appsArray={appData && appData.my_apps} />;
};

export default SuperApp;
