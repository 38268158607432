import React from "react";
import ReactSelect from "react-select";
import Creatable from 'react-select/creatable';


export const Select = (props) => {
  return (
    <ReactSelect
      styles={{
        multiValue: (styles) => ({
          ...styles,
          backgroundColor: "rgb(59, 130, 246)",
          color: "rgb(255,255,255)",
        }),
        valueContainer: (styles) => ({
          ...styles,
          padding: '0 6px'
        }),
        input: (styles) => ({
          ...styles,
          margin: '0px',
        }),
        indicatorSeparator: styles => ({
          display: 'none',
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: "rgb(255,255,255)",
        }),
        input: (styles) => ({
          ...styles,
          outline: "none",
          border: 0,
        }),
        container: (styles) => ({
          ...styles,
          maxWidth: 220,
        }),
        multiValueRemove: (styles) => ({
          ...styles,
          backgroundColor: "rgba(0,0,0,0)",
        }),
      }}
      {...props}
    />
  );
};

export const CreatableSelect = (props) => {
  return (
    <Creatable
      components={
        {
          DropdownIndicator: null,
          ClearIndicator: null,
        }
      }
      styles={{
        multiValue: (styles) => ({
          ...styles,
          backgroundColor: "rgb(59, 130, 246)",
          color: "rgb(255,255,255)",
        }),
        valueContainer: (styles) => ({
          ...styles,
          padding: '0 6px'
        }),
        input: (styles) => ({
          ...styles,
          margin: '0px',
        }),
        indicatorSeparator: styles => ({
          display: 'none',
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: "rgb(255,255,255)",
        }),
        input: (styles) => ({
          ...styles,
          outline: "none",
          border: 0,
        }),
        container: (styles) => ({
          ...styles,
          maxWidth: 220,
        }),
        multiValueRemove: (styles) => ({
          ...styles,
          backgroundColor: "rgba(0,0,0,0)",
        }),
      }}
      {...props}
    />
  );
};
