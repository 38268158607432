import React, { useMemo, useCallback } from "react";
import {
  CheckCircleIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import Typed from "react-typed";

const InvoiceRow = ({
  invoice,
  index,
  currentInvoiceURL,
  crossReferenceCheckInvoices,
  setCurrentDetailsType,
  setCurrentInvoiceURL,
  setCurrentDetailsFilename,
}) => {
  const parsedInvoice = useMemo(() => JSON.parse(invoice.data), [invoice]);
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      setCurrentDetailsType("invoice");
      const newURL =
        crossReferenceCheckInvoices &&
        crossReferenceCheckInvoices[index] &&
        crossReferenceCheckInvoices[index]["Annotated Invoice URL"]
          ? crossReferenceCheckInvoices[index]["Annotated Invoice URL"]
          : parsedInvoice?.file_url;
      setCurrentInvoiceURL(newURL);
      setCurrentDetailsFilename(parsedInvoice?.["filename"]);
    },
    [
      setCurrentDetailsFilename,
      setCurrentDetailsType,
      setCurrentInvoiceURL,
      crossReferenceCheckInvoices,
      index,
      parsedInvoice,
    ]
  );

  const clicked =
    currentInvoiceURL === parsedInvoice?.["file_url"] ||
    (crossReferenceCheckInvoices &&
      crossReferenceCheckInvoices[index] &&
      currentInvoiceURL ===
        crossReferenceCheckInvoices[index]["Annotated Invoice URL"]);

  const filename = parsedInvoice?.["filename"];
  return (
    <tr className="border-b border-gray-300 whitespace-nowrap">
      <td className="text-sm py-2">
        <button
          className={`text-gray-600 hover:text-customHighlightColor no-underline ${
            clicked ? "font-bold" : ""
          }`}
          onClick={handleClick}
          type="button"
          disabled={clicked}
        >
          {filename}
        </button>
      </td>
      <td className="py-2">
        {invoice ? (
          parsedInvoice?.["Invoice  ID"]?.text
        ) : (
          <Typed strings={["..."]} loop typeSpeed={40} />
        )}
      </td>
      <td className="py-2">
        {invoice ? (
          parsedInvoice?.["Invoice Date"]?.text
        ) : (
          <Typed strings={["..."]} loop typeSpeed={40} />
        )}
      </td>
      <td className="py-2">
        {invoice ? (
          parsedInvoice?.["Vendor Name"]?.text
        ) : (
          <Typed strings={["..."]} loop typeSpeed={40} />
        )}
      </td>
      <td className="py-2">
        {invoice ? (
          parsedInvoice?.["Customer Name"]?.text
        ) : (
          <Typed strings={["..."]} loop typeSpeed={40} />
        )}
      </td>
      <td className="py-2">
        {invoice ? (
          parsedInvoice?.["Invoice Total"]?.text.symbol === "USD" ? (
            "$"
          ) : (
            parsedInvoice?.["Invoice Total"]?.text.symbol
          )
        ) : (
          <Typed strings={["..."]} loop typeSpeed={40} />
        )}
        {invoice ? parsedInvoice?.["Invoice Total"]?.text.amount : null}
      </td>
      <td>
        {crossReferenceCheckInvoices[index] ? (
          crossReferenceCheckInvoices[index]["Agreement"] && (
            <CheckCircleIcon className="h-7 w-7" />
          )
        ) : (
          <Typed strings={["..."]} loop typeSpeed={40} />
        )}
      </td>
      <td>
        {crossReferenceCheckInvoices[index] ? (
          crossReferenceCheckInvoices[index]["Payment Date"]
        ) : (
          <Typed strings={["..."]} loop typeSpeed={40} />
        )}
      </td>
      <td>
        {crossReferenceCheckInvoices && crossReferenceCheckInvoices[index] ? (
          crossReferenceCheckInvoices[index]["Annotated Invoice URL"] ? (
            <a
              href={crossReferenceCheckInvoices[index]["Annotated Invoice URL"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>
                <ArrowDownTrayIcon className="h-6 w-6 text-gray-500 hover:text-customHighlightColor bg-white" />
              </button>
            </a>
          ) : null
        ) : (
          <Typed strings={["..."]} loop typeSpeed={40} />
        )}
      </td>
    </tr>
  );
};

export const ParsedInvoicesTable = ({
  invoiceDetails,
  crossReferenceCheckInvoices,
  currentInvoiceURL,
  setCurrentInvoiceURL,
  setCurrentDetailsType,
  setCurrentDetailsFilename,
  allFilesParsed,
  urlInputSubmitPressed,
  urlInput,
}) => {
  return (
    <table className="table-auto w-1/2">
      <thead>
        <tr className="border-b border-gray-300">
          <th className="min-w-[300px]">Parsed invoices</th>
          <th className="min-w-[75px]">ID</th>
          <th className="min-w-[110px]">Date</th>
          <th className="min-w-[250px]">Vendor</th>
          <th className="min-w-[300px]">Customer</th>
          <th className="min-w-[130px]">Amount</th>
          <th className="min-w-[130px]">Agrees with statement?</th>
          <th className="min-w-[110px]">Payment Received</th>
          <th className="min-w-[110px]">Annotated invoice</th>
        </tr>
      </thead>
      <tbody>
        <>
          {invoiceDetails.length > 0
            ? invoiceDetails.map((invoice, index) => (
                <InvoiceRow
                  key={invoice.uuid}
                  invoice={invoice}
                  index={index}
                  currentInvoiceURL={currentInvoiceURL}
                  crossReferenceCheckInvoices={crossReferenceCheckInvoices}
                  setCurrentDetailsType={setCurrentDetailsType}
                  setCurrentInvoiceURL={setCurrentInvoiceURL}
                  setCurrentDetailsFilename={setCurrentDetailsFilename}
                />
              ))
            : null}
          {!allFilesParsed && (
            <p className="text-sm py-2">
              Parsing
              {urlInputSubmitPressed && urlInput.current && (
                <>
                  {" "}
                  <i>{urlInput.current.value.slice(0, 50)}</i>
                </>
              )}
              <Typed strings={["..."]} loop typeSpeed={40} />
            </p>
          )}
        </>
      </tbody>
    </table>
  );
};

export default ParsedInvoicesTable;
