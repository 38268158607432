import React, { useCallback, useState } from 'react';
import { FolderIcon } from '@heroicons/react/24/outline';
import Dialog from './Dialog';
import SavedPrompt from './SavedPrompt';

const PromptsDialog = ({ savedPrompts, onSelectPrompt, appInstanceId, fetchSavedPrompts }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    if (!Array.isArray(savedPrompts) || savedPrompts.length === 0) {
        return null;
    }
    return (
        <>
            <button
                type="button"
                className="mr-2 shadow h-10 w-10 flex flex-col justify-center items-center rounded-full bg-white text-black"
                onClick={openModal}
            >
                <FolderIcon className="h-5 w-5 text-customHighlightColor" aria-hidden="true" onClick={openModal} />

            </button>
            <Dialog isOpen={isOpen} closeDialog={closeModal} title="Saved Prompts" description="Click any prompt to insert it into the query box.">
                {savedPrompts.map(({ prompts, id }) => (
                    <SavedPrompt
                        key={id}
                        prompts={prompts}
                        id={id}
                        setQuery={onSelectPrompt}
                        appInstanceId={appInstanceId}
                        fetchSavedPrompts={fetchSavedPrompts}
                    />
                ))}
            </Dialog>
        </>
    )
}

export default PromptsDialog;