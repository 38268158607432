import { combineReducers } from "@reduxjs/toolkit";
import app from "./app.reducer";
import apps from "./apps.reducer";
import conversations from "./conversations.reducer";

const reducers = {
  app,
  apps,
  conversations,
};

const allReducers = { ...reducers };

const appReducer = combineReducers(allReducers);

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
