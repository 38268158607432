import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import {
  ClockIcon,
  FunnelIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { Select } from "../../components";
import { postRequest } from "../../utils/httpUtils";
import { Link } from "react-router-dom";

import Dialog from "../../utils/Dialog";
import { uniqueId } from "lodash";

const AddTemplateDialog = ({ onAddTemplate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [disclosureType, setDisclosureType] = useState("");
  const onAddTemplateInternal = useCallback(() => {
    onAddTemplate(disclosureType);
    closeModal();
  }, [disclosureType, onAddTemplate]);
  return (
    <>
      <button
        type="button"
        className="mr-2 shadow h-10 w-10 flex flex-col justify-center items-center rounded-full bg-white text-black"
        onClick={openModal}
      >
        <PlusCircleIcon className="w-7 h-7 text-customHighlightColor cursor-pointer" />
      </button>
      <Dialog title="Add Template" isOpen={isOpen} closeDialog={closeModal}>
        <input
          type="text"
          placeholder="Disclosure Type"
          value={disclosureType}
          onChange={(e) => setDisclosureType(e.target.value)}
        />
        <button onClick={onAddTemplateInternal}>Add Template</button>
      </Dialog>
    </>
  );
};

const ChooseFootnote = ({
  appInstanceID,
  footnotes,
  setFootnotes,
  selectedTemplates, // This is only coming in so we can give a number
  setSelectedTemplates,
  setSelectedTemplateTitles,
  relatedWorkpapers,
  workpapersForFootnote,
  setWorkpapersForFootnote,
  promptsForFootnotes,
  setPromptsForFootnotes,
}) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [expandedRowId, setExpandedRowId] = useState(null);
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const [rows, setRows] = useState([
    // {
    //   id: 0,
    //   row_data: ["Operating Lease Disclosure", "Approved"],
    //   metadata: { bgcolor: "#dff5e1" },
    // }
  ]);

  const addNewFootnoteByTitle = (title) => {
    setFootnotes((prevFootnotes) => {
      const firstFootnote = prevFootnotes[0] || [];
      const newFootnote = {
        footnote_id: uniqueId(),
        footnote: { title },
        is_new: true,
      };
      if (!firstFootnote) {
        return [{ footnotes: [newFootnote] }];
      }
      const copiedFirstFootnote = { ...firstFootnote };
      copiedFirstFootnote.footnotes.push(newFootnote);
      return [copiedFirstFootnote, ...prevFootnotes.slice(1)];
    });
  };

  const editExistingFootnoteByTitle = (title, existingFootnoteIndex) => {
    setFootnotes((prevFootnotes) => {
      const firstFootnote = prevFootnotes[0] || [];
      const existingFootnote = firstFootnote?.footnotes[existingFootnoteIndex];

      if (!existingFootnote) {
        console.error("No existing footnote found");
        return prevFootnotes;
      }

      const updatedFootnote = {
        ...existingFootnote,
        footnote: {
          ...existingFootnote.footnote,
          title,
        },
      };

      const updatedFirstFootnote = {
        ...firstFootnote,
        footnotes: [
          ...firstFootnote.footnotes.slice(0, existingFootnoteIndex),
          updatedFootnote,
          ...firstFootnote.footnotes.slice(existingFootnoteIndex + 1),
        ],
      };

      return [updatedFirstFootnote, ...prevFootnotes.slice(1)];
    });
  };

  // Update rows when footnotes change
  useEffect(() => {
    if (footnotes && footnotes.length > 0) {
      const firstFootnote = footnotes[0];
      const updatedRows = firstFootnote?.footnotes?.map((singleFootnote) => ({
        id: singleFootnote.footnote_id,
        row_data: [singleFootnote.footnote.title, "Awaiting approval"],
        metadata: { bgcolor: "#dff5e1" },
      }));
      setRows(updatedRows);
    }
  }, [footnotes]);

  const handleStatusChange = (index, newStatus) => {
    const updatedRows = rows?.map((row, idx) =>
      idx === index
        ? {
            ...row,
            row_data: [row.row_data[0], newStatus],
            metadata: {
              bgcolor: newStatus === "Approved" ? "#dff5e1" : "white",
            },
          }
        : row
    );

    // setSelectedTemplates is now redundant, as it's equivalent to the keys from selectedTemplateTitles and workpapersForFootnote, so let's get rid of it.
    setSelectedTemplates((prevSelectedTemplates) => {
      const footnoteID = rows[index].id;

      if (prevSelectedTemplates.includes(footnoteID)) {
        return prevSelectedTemplates;
      }

      return [...prevSelectedTemplates, footnoteID];
    });

    setSelectedTemplateTitles((prevSelectedTemplateTitles) => {
      const footnoteID = rows[index].id;
      const footnoteTitle = rows[index]["row_data"][0];
      console.log("Adding ID and title: " + footnoteID + " " + footnoteTitle);

      // Check if the footnoteID already exists in the state
      if (prevSelectedTemplateTitles[footnoteID]) {
        return prevSelectedTemplateTitles; // Return unchanged if it already exists
      }

      // Add the new footnoteID and title to the state
      return {
        ...prevSelectedTemplateTitles,
        [footnoteID]: footnoteTitle,
      };
    });
    setRows(updatedRows);
  };

  const handleWorkpaperFootnoteSelectChange = (footnoteID, selectedOptions) => {
    setWorkpapersForFootnote((prevState) => ({
      ...prevState,
      [footnoteID]: selectedOptions
        ? selectedOptions?.map((option) => option.value)
        : [],
    }));
    console.log("selectedoptions: ", selectedOptions);
    console.log("workpapersForFootnote: ", workpapersForFootnote);
  };

  const handleDeleteRow = (index) => {
    setFootnotes((prevFootnotes) => {
      const firstFootnote = prevFootnotes[0];
      const updatedFirstFootnote = {
        ...firstFootnote,
        footnotes: [
          ...firstFootnote.footnotes.slice(0, index),
          ...firstFootnote.footnotes.slice(index + 1),
        ],
      };

      return [updatedFirstFootnote, ...prevFootnotes.slice(1)];
    });
  };

  const getHoverMenu = (index) => (
    <div className="absolute right-0 flex space-x-2 bg-white border border-gray-300 rounded-md shadow-lg p-2 z-10">
      <button
        key={`approve-${index}`}
        onClick={() => handleStatusChange(index, "Approved")}
        className="flex items-center text-customHighlightColor text-sm"
      >
        <CheckCircleIcon className="w-5 h-5 mr-1" />
      </button>
      <button
        key={`awaiting-${index}`}
        onClick={() => handleStatusChange(index, "Awaiting approval")}
        className="flex items-center text-blue-800 text-sm"
      >
        <ClockIcon className="w-5 h-5 mr-1" />
      </button>
      <AddTemplateDialog
        onAddTemplate={(title) => editExistingFootnoteByTitle(title, index)}
      />
      <button
        key={`delete-${index}`}
        onClick={() => handleDeleteRow(index)}
        className="flex items-center text-red-500 text-sm"
      >
        <TrashIcon className="w-5 h-5 mr-1" />
      </button>
    </div>
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRows = useMemo(() => {
    return rows.slice(indexOfFirstItem, indexOfLastItem);
  }, [rows, currentPage, itemsPerPage]); // Memoized so that the useEffect for generating prompts doesn't keep getting called

  useEffect(() => {
    const generatePrompts = async () => {
      for (const row of currentRows) {
        if (!promptsForFootnotes[row.id]) {
          // Don't regenerate prompts if they already exist
          try {
            const response = await postRequest(
              "/api/footnote_ai/generate_prompt",
              {
                app_instance_id: appInstanceID,
                footnote_id: row.id,
              }
            );
            console.log("Creating prompt for footnote: ", row.id);
            // console.log("hi!", response.data.data);
            console.log(promptsForFootnotes);
            setPromptsForFootnotes((prevState) => ({
              ...prevState,
              [row.id]: response.data.data,
            }));
          } catch (error) {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          }
        }
      }
    };

    if (currentRows && currentRows.length > 0) {
      generatePrompts();
    }
  }, [currentRows, appInstanceID]);

  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex flex-col items-baseline md:flex-row p-6 min-h-screen">
      <div className="w-full bg-white p-4 shadow-lg rounded-md">
        <div className="flex items-center justify-between mb-2">
          <div className="flex flex-col w-full">
            <div className="flex items-center">
              <h3 className="text-lg font-bold text-customBlack">
                Suggested Footnotes
              </h3>
              {/* <div className="flex items-center bg-blue-50 text-blue-500 text-sm font-semibold px-2 py-1 rounded-md">
                <ClockIcon className="w-4 h-4 mr-1" /> In progress
              </div> */}
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="relative w-full bg-customLightGray h-1 rounded">
                <div
                  className="bg-customHighlightColor h-1 rounded"
                  style={{ width: "20%" }}
                ></div>
              </div>
              <span className="text-sm text-customDarkGray ml-2"></span>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <button className="text-sm text-customHighlightColor border border-customHighlightColor bg-customHighlightColor/10 rounded-md px-2 py-1">
              {footnotes?.[0]?.footnotes?.length || 0} All
            </button>
            <button className="text-sm text-customDarkGray border border-gray-300 rounded-md px-2 py-1">
              {selectedTemplates?.length || 0} Selected
            </button>
            <button className="text-sm text-customDarkGray border border-gray-300 rounded-md px-2 py-1">
              {footnotes?.[0]?.footnotes?.length - selectedTemplates?.length ||
                0}{" "}
              Awaiting approval
            </button>
          </div>
          <div className="flex items-center space-x-3">
            <FunnelIcon className="w-6 h-6 text-customHighlightColor cursor-pointer" />
            <AddTemplateDialog onAddTemplate={addNewFootnoteByTitle} />
          </div>
        </div>

        <div className="w-full mt-6 relative">
          <table className="min-w-full bg-white shadow-lg rounded-md border">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text font-bold text-gray-700 tracking-wider">
                  Disclosure Type
                </th>
                <th className="px-6 py-3 text-left text font-bold text-gray-700 tracking-wider">
                  Prompt for Footnote Generation (
                  <Link to={`/app/edit/${appInstanceID}`}>
                    Edit Master Prompt
                  </Link>
                  )
                </th>
                <th className="px-6 py-3 text-left text font-bold text-gray-700 tracking-wider">
                  Related Workpaper(s)
                </th>
                <th className="px-6 py-3 text-left text font-bold text-gray-700 tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentRows?.map((row, index) => (
                <tr
                  key={row.id}
                  className={`border-b ${
                    hoveredRow === index ? "relative" : ""
                  }`}
                  style={{ backgroundColor: row.metadata.bgcolor }}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td className="px-6 py-4 text-sm text-gray-500 font-size font-bold">
                    {row.row_data[0]}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500 font-size">
                    <div style={{ whiteSpace: "pre-line" }}>
                      {promptsForFootnotes[row.id] ? (
                        <>
                          {expandedRowId === row.id
                            ? promptsForFootnotes[row.id] // Show full content if expanded
                            : promptsForFootnotes[row.id]?.slice(0, 20) +
                              "..."}{" "}
                          {/* Show truncated content */}
                          <button
                            onClick={() =>
                              setExpandedRowId((prevExpandedRowId) =>
                                prevExpandedRowId === row.id ? null : row.id
                              )
                            }
                            className="ml-2 text-blue-500"
                          >
                            {expandedRowId === row.id ? "−" : "+"}{" "}
                            {/* Toggle + and − signs */}
                          </button>
                        </>
                      ) : (
                        "..."
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm relative">
                    <div className="relative inline-block w-full text-gray-700">
                      <Select
                        label="Related Workpaper(s)"
                        options={relatedWorkpapers?.map((item) => ({
                          value: item.data.file_id,
                          label: item.data.filename,
                        }))}
                        value={
                          workpapersForFootnote[row.id]?.map((file_id) => {
                            const matchedOption = relatedWorkpapers.find(
                              (item) => item.data.file_id === file_id
                            );
                            return {
                              value: matchedOption?.data.file_id,
                              label: matchedOption?.data.filename,
                            };
                          }) || []
                        }
                        onChange={(selectedOptions) =>
                          handleWorkpaperFootnoteSelectChange(
                            row.id,
                            selectedOptions
                          )
                        }
                        isClearable
                        isMulti
                        placeholder="Related Workpaper(s)"
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm relative">
                    <span
                      className={`flex items-center font-semibold ${
                        row.row_data[1] === "Approved"
                          ? "text-green-400"
                          : "text-blue-500"
                      }`}
                    >
                      {row.row_data[1] === "Approved" ? (
                        <>
                          <CheckCircleIcon className="w-4 h-4 mr-1" /> Approved
                        </>
                      ) : (
                        <>
                          <ClockIcon className="w-4 h-4 mr-1" /> Awaiting
                          approval
                        </>
                      )}
                    </span>
                    {hoveredRow === index && (
                      <div className="absolute bottom-50 right-0">
                        {getHoverMenu(index + (currentPage - 1) * itemsPerPage)}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end items-center mt-4 space-x-2 border-customHighlightColor ">
          <button
            className={`text-sm p-2 border-customHighlightColor rounded-md ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : "text-blue-500"
            }`}
            disabled={currentPage === 1}
            onClick={() => paginate(currentPage - 1)}
          >
            <ChevronLeftIcon className="w-4 h-4 text-customHighlightColor" />
          </button>
          {[...Array(totalPages)]?.map((_, i) => (
            <button
              key={i}
              className={`text-sm px-3 py-1 border-customHighlightColor rounded-md ${
                currentPage === i + 1
                  ? "bg-purple-100 text-customHighlightColor"
                  : "text-gray-700 border border-customHighlightColor"
              }`}
              onClick={() => paginate(i + 1)}
            >
              {String(i + 1).padStart(2, "0")}
            </button>
          ))}
          <button
            className={`text-sm p-2 border-customHighlightColor rounded-md ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : "text-blue-500"
            }`}
            disabled={currentPage === totalPages}
            onClick={() => paginate(currentPage + 1)}
          >
            <ChevronRightIcon className="w-4 h-4 text-customHighlightColor" />
          </button>
        </div>
      </div>

      {/* <div className="w-full md:w-2/3 bg-white p-4 shadow-lg rounded-md md:ml-4 mt-4 md:mt-0"> */}
      {/* Right Section - Details Panel */}
      {/* </div> */}
    </div>
  );
};

export default ChooseFootnote;
