import React from "react";
import cn from "classnames";
import {
  PaperAirplaneIcon,
  StarIcon,
  XCircleIcon,
  CloudArrowUpIcon,
} from "@heroicons/react/24/outline";
import ToggleFileComponent from "../../utils/ToggleFileComponent";
import PromptsDialog from "../../utils/PromptsDialog";

/**
 * A functional component representing a message form with various features.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} [props.value=''] - The current value of the input field.
 * @param {function} props.onChange - Callback function for input change events.
 * @param {string} [props.inputName='query'] - Name attribute for the input element.
 * @param {HTMLInputElement | null} [props.inputRef] - Reference to the input element.
 * @param {function} props.onSubmit - Callback function for form submission.
 * @param {function} [props.onClear] - Callback function for clearing the conversation.
 * @param {function} [props.onFileButtonClick] - Optional callback for file button click.
 * @param {function} [props.onStar] - Callback function for star button click.
 * @param {boolean} [props.hideStar=false] - Flag to hide the star button.
 * @param {string} [props.placeholder=''] - Placeholder text for the input field.
 * @param {string} [props.className=''] - Custom class names for the form.
 * @param {string} [props.label=''] - Label text for the form.
 * @param {boolean} [props.fullWidth=false] - Flag to make the form full width.
 * @param {boolean} [props.hideClear=false] - Flag to hide the clear button.
 * @param {boolean} [props.showReturnOptions=true] - Flag to show return options.
 * @param {string} [props.returnType] - Current return type option.
 * @param {function} [props.setReturnType] - Function to update return type.
 * @param {function} props.fetchSavedPrompts - Function to fetch saved prompts.
 * @param {function} props.onSelectPrompt - Function to select a prompt.
 * @param {string} props.appInstanceId - Unique identifier for the application instance.
 * @param {array} props.savedPrompts - Array of saved prompts.
 *
 * @returns {JSX.Element} - The JSX element representing the message form.
 */
export const MessageForm = ({
  // State Management & Input Configuration
  value,
  onChange,
  inputName = "query",
  inputRef,
  
  // Submission Handlers
  onSubmit,
  onClear,
  onFileButtonClick, // Optional prop

  // Star Handling
  onStar,
  hideStar = false,

  // Display Options
  placeholder = "",
  className = "",
  label = "",
  fullWidth = false,
  hideClear = false,

  // Prompt Handling
  // Note: These props are not used if showReturnOptions is false
  showReturnOptions = true,
  returnType, 
  setReturnType, 
  fetchSavedPrompts,
  onSelectPrompt,
  appInstanceId,
  savedPrompts,
}) => {
  return (
    <form onSubmit={onSubmit} className={className}>
      {label && (
        <p className="block text-gray-700 text-sm font-bold mb-0 my-2">
          {label}
        </p>
      )}
      <div className="flex my-2">
        {onFileButtonClick && (
          <button
            type="button"
            className="mr-2 w-12 h-12 border-1 border-customGray-60 flex flex-col justify-center items-center rounded-full bg-white text-black"
            onClick={onFileButtonClick}
          >
            <CloudArrowUpIcon
              className="h-4 w-4 shrink-0 text-customHighlightColor"
              aria-hidden="true"
            />
          </button>
        )}
        <PromptsDialog
          fetchSavedPrompts={fetchSavedPrompts}
          onSelectPrompt={onSelectPrompt}
          appInstanceId={appInstanceId}
          savedPrompts={savedPrompts}
        />
        <div
          className={cn("flex h-12 relative border-1 border-customGray-60 rounded-full w-full bg-white", {
            "flex-1": fullWidth,
          })}
        >
          <input
            ref={inputRef}
            className="px-4 border-0 appearance-none rounded-full flex-1 text-gray-700 leading-tight outline-none"
            value={value}
            name={inputName}
            placeholder={placeholder || "Type a message"}
            onChange={onChange}
            data-testid="chat-msg-field"
          />

          {onStar && value.length > 0 && !hideStar ? (
            <button
              type="button"
              className="flex flex-col justify-center items-center self-center h-6 w-6 bg-customGray-50 mx-2 rounded-full text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
              onClick={onStar}
            >
              <StarIcon
                className="h-4 w-4 shrink-0 text-customHighlightColor"
                aria-hidden="true"
              />
            </button>
          ) : null}


          {onClear && !hideClear && (
            <button
              type="button"
              title="Clear conversation"
              className="cursor-pointer mr-2 h-9 w-9 flex flex-col  self-center justify-center items-center rounded-full bg-customGray-50 text-black"
              onClick={onClear}
              data-testid="clear-chat-history-btn"
            >
              <XCircleIcon
                className="h-5 w-5 text-customHighlightColor"
                aria-hidden="true"
              />
            </button>
          )}
          <button
            type="submit"
            className="h-9 w-9 flex flex-col justify-center items-center self-center rounded-full bg-customGray-50 text-black mr-2"
            data-testid="send-chat-msg-btn"
          >
            <PaperAirplaneIcon
              className="h-5 w-5 text-customHighlightColor"
              aria-hidden="true"
            />
          </button>

        </div>
      </div>
      {(showReturnOptions && setReturnType) && (
        <div className="flex justify-end mt-4">
          <ToggleFileComponent
            setSelectedOption={setReturnType}
            selectedOption={returnType}
          />
        </div>
      )}
    </form>
  );
};
