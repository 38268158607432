import { useState, useEffect } from "react";
import { postRequest } from "../../utils/httpUtils";
import Conversation from "../../utils/Conversation";

export default function Questions({
  inputToGenerateQuestions,
  questions,
  setQuestions,
  setAnswers,
  appInstanceID,
}) {
  useEffect(() => {
    console.log("Generating questions...");
    const generateQuestions = async () => {
      try {
        const response = await postRequest(
          "/api/footnote_ai/generate_questions",
          {
            input_to_generate_questions: inputToGenerateQuestions,
            app_instance_id: appInstanceID,
          }
        );
        console.log("questions:")
        console.log(response.data["questions"].length);
        console.log(response.data["questions"]);

        setQuestions(response.data["questions"]);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };

    if (questions?.length < 1) {
      generateQuestions();
    }
  }, [inputToGenerateQuestions]);

  return (
    <>
      {questions?.length > 0 ? (
        <Conversation questions={questions} onChangeAnswers={setAnswers} />
      ) : (
        "Loading..."
      )}
    </>
  );
}
