import React from "react";
import { SignIn } from "@clerk/clerk-react";
import { useLocation } from "react-router-dom";

// https://clerk.com/docs/components/authentication/sign-in

const SignInModal = ({ open, setOpen }) => {
  const location = useLocation();

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("modal-backdrop")) {
      setOpen(false);
    }
  };

  return (
    open && (
      <div
        className="modal-backdrop bg-black bg-opacity-50 fixed inset-0 flex items-center justify-center"
        onClick={handleBackdropClick}
      >
        <div className="modal-content p-2 max-w-md relative">
          <SignIn
            path="/sign-in"
            afterSignInUrl={location.pathname}
            afterSignUpUrl={location.pathname}
          />
        </div>
      </div>
    )
  );
};

export default SignInModal;
