import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { logEvent } from "../../redux/reducers/app.reducer";
import { postRequest } from "../../utils/httpUtils";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";

const FilesForFootNotesTable = ({ instance_id, successfulFileUploads }) => {
  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();

  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "company_name",
    direction: "asc",
  });
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(
        logEvent({
          user,
          event: "Looked at approved questions/answers view.",
        })
      );
    }
  }, [isLoaded, isSignedIn, user]);

  const fetchTableData = async () => {
    try {
      const response = await postRequest(
        "/api/footnote_ai/get_files_for_table",
        {
          instance_id: instance_id,
        }
      );
      const _tableData = response.data || [];
      console.log("tableData", _tableData);
      setTableData(
        _tableData
        .map(data => ({ ...data, date_uploaded: data.created_at }))
        .sort((a, b) => {
          if (a["company_name"] < b["company_name"]) return -1;
          if (a["company_name"] > b["company_name"]) return 1;
          return 0;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchTableData();
    }
  }, [isLoaded, isSignedIn, user, successfulFileUploads]);

  const sortTable = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });

    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setTableData(sortedData);
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return "";
    return sortConfig.direction === "asc" ? " \u2191" : " \u2193";
  };

  return (
    <>
      <h1 className="text-xl font-bold mb-4">Files</h1>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-x-auto">
              <table className="table-auto w-full border-collapse border border-gray-300 shadow-lg">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border border-gray-300 px-2 py-1 text-left cursor-pointer" />
                    <th
                      className="border border-gray-300 px-2 py-1 text-left cursor-pointer"
                      onClick={() => sortTable("filename")}
                    >
                      File Name{getSortIndicator("filename")}
                    </th>
                    <th
                      className="border border-gray-300 px-2 py-1 text-left cursor-pointer"
                      onClick={() => sortTable("company_name")}
                    >
                      Company Name{getSortIndicator("company_name")}
                    </th>
                    <th
                      className="border border-gray-300 px-2 py-1 text-left cursor-pointer"
                      onClick={() => sortTable("industry_name")}
                    >
                      Industry{getSortIndicator("industry_name")}
                    </th>
                    <th
                      className="border border-gray-300 px-2 py-1 text-left cursor-pointer"
                      onClick={() => sortTable("accounting_basis")}
                    >
                      Accounting Basis{getSortIndicator("accounting_basis")}
                    </th>
                    <th
                      className="border border-gray-300 px-2 py-1 text-left cursor-pointer"
                      onClick={() => sortTable("date")}
                    >
                      Date{getSortIndicator("date")}
                    </th>
                    <th
                      className="border border-gray-300 px-2 py-1 text-left cursor-pointer"
                      onClick={() => sortTable("date_uploaded")}
                    >
                      Date Uploaded{getSortIndicator("date_uploaded")}
                    </th>
                    <th
                      className="border border-gray-300 px-2 py-1 text-left cursor-pointer"
                      onClick={() => sortTable("footnote_count")}
                    >
                      Footnote Count{getSortIndicator("footnote_count")}
                    </th>
                    <th className="border border-gray-300 px-2 py-1 text-left">
                      Footnote Titles
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <React.Fragment key={item.file_id}>
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-white" : "bg-gray-100"
                        }`}
                      >
                        <td className="border border-gray-300 px-2 py-1">
                          <a
                            className="text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
                            href={`/api/files/${item.file_id}.pdf`}
                          >
                            <ArrowDownOnSquareIcon
                              className="h-4 w-4 shrink-0"
                              aria-hidden="true"
                            />
                          </a>
                        </td>
                        <td className="border border-gray-300 px-2 py-1 text-sm">
                          {item.filename || "N/A"}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {item.company_name || "N/A"}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {item.industry_name || "N/A"}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {item.accounting_basis || "N/A"}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {item.date &&
                          item.date.match(
                            /^[a-zA-Z]{3}, \d{2} [a-zA-Z]{3} \d{4}/
                          )
                            ? item.date.match(
                                /^[a-zA-Z]{3}, \d{2} [a-zA-Z]{3} \d{4}/
                              )[0]
                            : "Invalid Date"}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {item.date_uploaded &&
                          item.date_uploaded.match(
                            /^[a-zA-Z]{3}, \d{2} [a-zA-Z]{3} \d{4}/
                          )
                            ? item.date_uploaded.match(
                                /^[a-zA-Z]{3}, \d{2} [a-zA-Z]{3} \d{4}/
                              )[0]
                            : "Invalid Date"}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {item.footnote_count}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {item.footnote_count > 0 ? (<>
                            {expandedRow === index ? (
                              <ul className="list-disc ml-2">
                                {item.footnote_titles.map((title, idx) => (
                                  <li key={idx} className="text-sm leading-tight">
                                    {title}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <span
                                className="text-gray-500 cursor-pointer"
                                onClick={() =>
                                  setExpandedRow(
                                    expandedRow === index ? null : index
                                  )
                                }
                              >
                                Click to show
                              </span>
                            )}
                          </>) : "N/A"}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilesForFootNotesTable;
