import React from "react";

const Progress = ({ percentage }) => {
  return (
    <div className="mt-2 w-full bg-gray-300 rounded">
      <div
        className="h-2 rounded bg-customHighlightColor"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default Progress;