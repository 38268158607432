// ToggleFileComponent.js
import React, { useCallback } from "react";
import { ReturnType } from "./enums";

const toggleOptions = [
  { label: "Conversation", value: ReturnType.NON_FILE },
  { label: "DOCX", value: ReturnType.DOCX },
  { label: "XLSX", value: ReturnType.XLSX },
];

const ToggleFileComponent = ({ setSelectedOption, selectedOption }) => {
  const handleChange = useCallback(
    (event) => {
      setSelectedOption(event.target.value);
    },
    [setSelectedOption]
  );

  return (
    <div>
      Give me output as{" "}
      <select
        className="text-sm py-1 px-8 mr-1 rounded "
        value={selectedOption}
        onChange={handleChange}
      >
        {toggleOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ToggleFileComponent;
