import { useState, useCallback } from 'react';
import { postRequest } from '../utils/httpUtils';

/**
 * Custom React hook to encapsulate the logic for deleting a prompt via an API call.
 * 
 * This hook provides functionality to delete a prompt by making a POST request to a specific endpoint with the prompt ID, user information, and app instance ID. It manages loading and error states internally,
 * and exposes a performAction function to trigger the deletion process along with the current loading and error states.
 * 
 * @param {string} appInstanceId - The unique identifier for the application instance under which the prompt resides.
 * @param {string} user - The user information associated with the prompt.
 * @returns {{ performAction: function, loading: boolean, error: any }} An object containing the performAction function to initiate prompt deletion, and states for loading and error handling.
 * 
 * - `performAction`: A function that accepts a promptId and handles the deletion process. It sets loading to true at the start, attempts to delete the prompt by making a POST request,
 *   and updates loading and error states accordingly based on the outcome of the request.
 * - `loading`: A boolean indicating whether the deletion process is ongoing.
 * - `error`: Any error encountered during the deletion process.
 * 
 * @example
 * // Example usage in SomeComponent.js:
 * const { performAction: performDeletePrompt } = useDeletePrompt(instanceId, user);
 * performDeletePrompt(promptId); // Initiates deletion of a prompt with the given promptId under the current appInstanceId and user context.
 */
const useDeletePrompt = (appInstanceId, user) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deletePrompt = useCallback(async (promptId) => {
    setLoading(true);
    setError(null);

    try {
      await postRequest(`/api/delete_prompt`, {
        id: promptId,
        user,
        app_instance_id: appInstanceId,
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [appInstanceId, user]);

  return { deletePrompt, loading, error };
};

export default useDeletePrompt;
