import { Link } from "react-router-dom";
import React from "react";
import {
  ArrowRightIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import FootnoteAppIcon from "../utils/FootnoteAppIcon";
import ChatbotIcon from "../utils/ChatbotIcon";
import classnames from "classnames";

const getIcon = (slug) => {
  const footnoteApps = [
    "footnote_ai0",
    "10k-footnote-search",
    "generate_a_footnote",
    "internal_consistency_check",
    "roll_forward_financial_statements",
  ];

  if (footnoteApps.includes(slug)) return FootnoteAppIcon;
  if (slug === "general_data_chat") return ChatbotIcon;
  return DocumentMagnifyingGlassIcon;
};

const AppCardLong = ({ card, url, cta_text, slug }) => {
  const Icon = getIcon(slug);
  const isFootnoteApp = Icon === FootnoteAppIcon;

  return (
    <Link
      // data-testid="card-item-go-btn"
      // className="flex py-3 px-6 font-normal text-customHighlightColor cursor-pointer justify-center no-underline"
      to={url}
      data-testid="card-item"
      className="py-4 px-3 w-full transition-colors duration-300 flex flex-row items-center no-underline"
    >
      <div className="flex flex-col flex-1">
        <div
          data-testid="card-item-name"
          className="flex-1 text-base font-medium break-words text-gray-700 mb-1"
        >
          {card.name}
        </div>
        <div className="w-full text-base text-gray-500">
          {card.description}
        </div>
      </div>
      <ArrowRightIcon className="w-5 h-5 text-customHighlightColor" />
    </Link>
  );
};

export default AppCardLong;