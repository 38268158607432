import React, { useCallback, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

export const DownloadDropdown = ({
  handleCSVDownloadCallback,
  handleXlsxDownloadCallback,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = useCallback(
    () => setIsDropdownOpen(!isDropdownOpen),
    [setIsDropdownOpen, isDropdownOpen]
  );
  const handleCSVDownloadCallbackInternal = useCallback(() => {
    handleCSVDownloadCallback();
    setIsDropdownOpen(false);
  }, [handleCSVDownloadCallback, setIsDropdownOpen]);
  const handleXlsxDownloadCallbackInternal = useCallback(() => {
    handleXlsxDownloadCallback();
    setIsDropdownOpen(false);
  }, [handleXlsxDownloadCallback, setIsDropdownOpen]);

  return (
    <div className="relative text-sm">
      <button
        className="text-gray-500 hover:text-customHighlightColor bg-white py-2 rounded-md flex"
        onClick={toggleDropdown}
      >
        Download
        {isDropdownOpen ? (
          <ChevronUpIcon className="ml-2 w-5 h-5" />
        ) : (
          <ChevronDownIcon className="ml-2 w-5 h-5" />
        )}
      </button>
      {isDropdownOpen && (
        <div className="absolute mt-1 w-50 bg-white shadow-md rounded-md z-10">
          <ul className="py-1">
            <li>
              <button
                className="text-gray-500 hover:text-customHighlightColor w-full text-left px-1 py-2 hover:bg-gray-100"
                onClick={handleCSVDownloadCallbackInternal}
              >
                Comma-separated values (.csv)
              </button>
            </li>
            <li>
              <button
                className="text-gray-500 hover:text-customHighlightColor w-full text-left px-1 py-2 hover:bg-gray-100"
                onClick={handleXlsxDownloadCallbackInternal}
              >
                Excel spreadsheet (.xlsx)
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DownloadDropdown;
