/**
 * The markup within this React component is deprecated and will be replaced by the markup in NewRagQueryComponent.js.
 * Please refer to NewRagQueryComponent.js for the updated markup and implementation.
 */
import React, { useState, useEffect, useRef, useMemo } from "react";
import StreamingText from "./StreamingText";
import Typed from "react-typed";
import {
  BookmarkSquareIcon, // Sadly, Hero Icons doesn't have a SaveIcon! So using this until they make one.
} from "@heroicons/react/24/outline";
import ClipboardButton from "./ClipboardButton";
import Feedback from "./Feedback";
import { truncateText } from "./utils";
import SavedPrompt from "./SavedPrompt";

const RagQueryComponent = ({
  analyzerContext,
  fileIDs,
  jumpToPage,
  userObj,
  appInstanceId,
  originalUserText,
  generatedResponse,
  fetchSavedPrompts,
}) => {
  const {
    accountingQueryDone,
    accountingQueryOutput,
    accountingQuerySubmitPressed,
    currentSource,
    doneAddToSavedPrompts,
    handleAccountingQuerySubmit,
    handleAddToSavedPromptsButton,
    boardMinuteDetails,
    queryHeaderText,
    queryInput,
    queryType,
    queryTypes,
    queryValues,
    resetAnalyzerState,
    responseSource,
    savedPrompts,
    setCurrentSource,
    setQueryType,
    setQueryValues,
    setSavedPrompts,
    successfulFileUploads,
    chunkLocations,
  } = analyzerContext;

  const fileUploadsWithPages = useMemo(() => {
    if (!successfulFileUploads || !chunkLocations) return [];
    return successfulFileUploads.map(({ data }) => {
      const { filename, file_id: fileID, filetype } = data;
      const pages = chunkLocations
        .filter((page) => page.file_id === fileID)
        .map((page) => page.pageIndex);
      return { filename, fileID, pages, filetype };
    });
  }, [successfulFileUploads, chunkLocations]);

  const setSource = (fileID) => {
    setCurrentSource(
      successfulFileUploads.find(({ data }) => data.file_id === fileID)
    );
  };

  const renderQueryTypeButton = (label, key) => (
    <button
      key={key}
      onClick={() => {
        if (responseSource && !responseSource.closed) {
          responseSource.removeEventListener("message");
          responseSource.close();
        }

        setQueryType(label);
        setSavedPrompts([]);
        resetAnalyzerState();

        if (queryType === label) {
          setQueryType(queryTypes[0]);
        } else {
          setQueryType(label);
        }
      }}
      className={`text-white py-2 px-2 rounded text-center
               ${
                 queryType === label
                   ? "bg-customHighlightColor"
                   : "bg-gray-600 hover:bg-customHighlightColor"
               } 
               ${queryType && queryType !== label ? "opacity-50" : ""}`}
      disabled={queryType && queryType === label}
    >
      {label}
    </button>
  );
  const [numQueries, setNumQueries] = useState(1);
  const [query, setQuery] = useState("");

  /*
  The shouldSkipResetOnSourceChangeRef in React helps control the resetting behavior
  of a component when there's a change in the currentSource. It serves as a decision flag,
  allowing the component to bypass resetting its state and query in situations
  where the change is triggered by user interactions that do not require a full component refresh.
  This is beneficial for maintaining a seamless user experience and safeguarding against data loss from unnecessary resets.

  This ref is manually set to true in cases like user clicks on different sources or pages,
  preventing unnecessary state resets during such interactions.
  Additionally, it's checked against in the useEffect hook; if it's false,
  the component resets its state and clears the query.
  After running, the ref's value is reset to false to ensure the component resets appropriately
  for future source changes, thereby optimizing component behavior for user interactions and state management.
   */
  const shouldSkipResetOnSourceChangeRef = useRef(false);

  useEffect(() => {
    if (!shouldSkipResetOnSourceChangeRef.current) {
      resetAnalyzerState();
      setQuery("");
    }
    shouldSkipResetOnSourceChangeRef.current = false;
  }, [currentSource]);

  if (!currentSource) {
    return null;
  }

  return (
    <div className="mt-2">
      <>
        {fileIDs.length > 0 && !boardMinuteDetails && (
          <p className="text-sm">
            Parsing source{fileIDs.length > 1 ? "s" : null}
            <Typed strings={["..."]} loop typeSpeed={40} />
          </p>
        )}

        {boardMinuteDetails && (
          <>
            <div className="flex h-screen">
              <div className="overflow-y-scroll h-screen w-1/2 flex-1 flex flex-column">
                {boardMinuteDetails && (
                  <div className="flex-1 p-4 space-x-2">
                    {queryTypes && queryTypes.map(renderQueryTypeButton)}

                    <form
                      onSubmit={(e) => {
                        setQuery("");
                        handleAccountingQuerySubmit(e, queryType)
                      }}
                    >
                      <p className="block text-gray-700 text-sm font-bold mb-2">
                        <br />
                        {queryHeaderText}
                      </p>
                      
                        <div className="mb-4 relative">
                          <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            rows={3}
                            name="query"
                            ref={queryInput}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          {query.length > 0 && (
                            <button
                              type="button"
                              className="absolute bottom-3 right-2 text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
                              onClick={handleAddToSavedPromptsButton}
                            >
                              <BookmarkSquareIcon
                                className="h-4 w-4 shrink-0"
                                aria-hidden="true"
                              />
                            </button>
                          )}
                        </div>
                      
                      {Array.isArray(savedPrompts) && (
                        <>
                          <p className="text-gray-500 text-sm text-left">
                            Or choose a saved{" "}
                            {queryType === "Checklist" ? "checklist" : "prompt"}
                            :
                          </p>
                          {savedPrompts.map(({ prompts, id }) => (
                            <SavedPrompt fetchSavedPrompts={fetchSavedPrompts} appInstanceId={appInstanceId}  key={id} prompts={prompts} setQuery={setQuery} id={id} />
                          ))}
                          <br />
                        </>
                      )}
                      <button
                        type="submit"
                        className="bg-gray-600 hover:bg-customHighlightColor text-white font-bold py-2 px-4 rounded"
                      >
                        Submit
                      </button>
                      <br />
                      {accountingQueryOutput !== null &&
                        accountingQueryDone &&
                        doneAddToSavedPrompts && (
                          <p className="text-sm">
                            <br />
                            Prompt saved.
                          </p>
                        )}
                    </form>
                    {accountingQueryOutput === "" &&
                      accountingQuerySubmitPressed && (
                        <p className="mt-4">
                          <Typed strings={["..."]} loop typeSpeed={40} />
                        </p>
                      )}
                    {accountingQueryOutput !== null &&
                      queryType !== "Checklist" && (
                        <>
                          <p className="mt-4">
                            <StreamingText
                              text={accountingQueryOutput}
                              done={accountingQueryDone}
                            />
                          </p>
                          {accountingQueryDone && (
                            <ClipboardButton text={accountingQueryOutput} />
                          )}
                          {chunkLocations.length > 0 && (
                            <p>
                              <br />
                              <b>
                                Source
                                {chunkLocations.length > 1 ? "s" : ""}:
                                <br />
                                <br />
                              </b>
                              {fileUploadsWithPages && fileUploadsWithPages
                                .filter(
                                  ({ pages }) =>
                                    Array.isArray(pages) && pages.length > 0
                                )
                                .map(
                                  ({ filename, pages, fileID, filetype }) => (
                                    <div className="mb-2" key={fileID}>
                                      <h6>
                                        {/* {truncateText(filename)} */}
                                        <a
                                          className="text-gray-600 hover:text-customHighlightColor no-underline"
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            shouldSkipResetOnSourceChangeRef.current = true;
                                            setSource(fileID);
                                          }}
                                        >
                                          {truncateText(filename)}
                                        </a>
                                      </h6>
                                      {filetype === "pdf" && (
                                        <div className="flex space-x-2">
                                          <ul>
                                            {pages.map((page, key) => (
                                              <li key={key}>
                                                <a
                                                  className={`text-gray-600 hover:text-customHighlightColor no-underline`}
                                                  href="#"
                                                  key={page}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    if (
                                                      typeof setCurrentSource ===
                                                      "function"
                                                    ) {
                                                      setSource(fileID);
                                                    }
                                                    jumpToPage(page - 1);
                                                    shouldSkipResetOnSourceChangeRef.current = true;
                                                  }}
                                                >
                                                  Page {page}
                                                </a>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                            </p>
                          )}
                          {accountingQueryDone && (
                            <Feedback
                              userObj={userObj}
                              appInstanceId={appInstanceId}
                              originalUserText={originalUserText}
                              generatedResponse={generatedResponse}
                            />
                          )}
                        </>
                      )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default RagQueryComponent;
