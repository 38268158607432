import { useState, useEffect } from "react";
import { postRequest } from "../../utils/httpUtils";
import TextEditor from "../../utils/TextEditor";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ClockIcon,
  CheckBadgeIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";

export default function GenerateFootnote({
  selectedTemplates,
  selectedTemplateTitles,
  workpapersForFootnote,
  promptsForFootnotes,
  appInstanceID,
}) {
  const [footnoteTexts, setFootnoteTexts] = useState({}); // Object with key as templateID and value as footnote
  const [approved, setApproved] = useState(false);

  const [finalPDFURL, setFinalPDFURL] = useState(null);

  const generateFootnote = async (templateID) => {
    const setFootnoteTextsInternal = (footnote) => {
      setFootnoteTexts((prevFootnotes) => {
        return { ...prevFootnotes, [templateID]: footnote };
      });
    };
    setFootnoteTextsInternal("Generating footnote...");

    try {
      const response = await postRequest(
        "/api/footnote_ai/generate_footnotes",
        {
          footnote_id: templateID,
          file_ids: workpapersForFootnote[templateID],
          prompt_for_footnote: promptsForFootnotes[templateID],
          app_instance_id: appInstanceID,
        }
      );

      console.log(response.data.data);
      setFootnoteTextsInternal(response.data.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  const getPDFURL = async (fullMarkdown) => {
    console.log("Getting/got PDF...");

    try {
      const response = await postRequest(
        "/api/footnote_ai/download_footnotes",
        {
          md: fullMarkdown,
        }
      );

      console.log("Getting/got PDF! URL: ", response.data);
      setFinalPDFURL(response.data.url);
      //  setFootnoteTextsInternal(response.data.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  useEffect(() => {
    const fullMarkdown = Object.values(footnoteTexts).join(" ");

    console.log("FULL MARKDOWN ", fullMarkdown);

    // Handle generating footnotes for selected templates
    if (selectedTemplates && selectedTemplates.length > 0) {
      selectedTemplates.forEach((templateID) => {
        if (!footnoteTexts[templateID]) {
          // Only generate if it hasn't been generated already
          generateFootnote(templateID);
        }
      });
    } else {
      setFootnoteTexts({
        0: "NOT generating footnote because required information not provided.",
      });
    }

    // Handle fullMarkdown and PDF generation logic
    if (
      fullMarkdown &&
      Object.keys(selectedTemplates).length > 0 &&
      Object.keys(promptsForFootnotes).length > 0 &&
      !fullMarkdown.includes("Generating footnote")
    ) {
      console.log("Running useEffect for fullMarkdown: ", fullMarkdown);
      getPDFURL(fullMarkdown);
    } else {
      console.log("NOT Running useEffect for fullMarkdown: ", fullMarkdown);
    }
  }, [footnoteTexts, selectedTemplates, promptsForFootnotes]);

  const [expandedFootnotes, setExpandedFootnotes] = useState([]);

  const toggleFootnote = (key) => {
    setExpandedFootnotes(
      (prev) =>
        prev.includes(key)
          ? prev.filter((k) => k !== key) // Collapse if already expanded
          : [...prev, key] // Expand if collapsed
    );
  };
  console.log("selectedTemplateTitles", selectedTemplateTitles);

  return (
    <div className="flex flex-col items-start items-baseline md:flex-row p-6 min-h-screen">
      {/* Left Section - Suggested Footnotes */}
      <div className="w-full bg-white p-4 shadow-lg rounded-md">
        {/* md:w-2/4 for half width */}
        <h1>Generate Footnotes</h1>
        <br />
        {finalPDFURL && (
          // <a href={finalPDFURL} target="_blank" className="no-underline">
          //   <ArrowDownTrayIcon className="h-6 w-6 text-gray-500 hover:text-customHighlightColor bg-white mr-2" />
          //   Download PDF
          // </a>
          <button 
          onClick={() => window.open(finalPDFURL, '_blank')}
          className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
        >
          <ArrowDownTrayIcon className="h-6 w-6 text-gray-500 hover:text-customHighlightColor bg-white mr-2" />
          <span>Download PDF</span>
        </button>

        )}
        <br />
        <br />
        {Object.entries(footnoteTexts).map(([key, footnote]) => (
          <div key={key} className="mb-4">
            <h2
              className="cursor-pointer flex items-center"
              onClick={() => toggleFootnote(key)}
            >
              {footnote == "Generating footnote..." ? (
                <ClockIcon className="w-8 h-8 ml-2 mr-2 text-red-500" />
              ) : (
                <CheckBadgeIcon className="w-8 h-8 ml-2 mr-2 text-green-400" />
              )}
              {selectedTemplateTitles[key]}
              {expandedFootnotes.includes(key) ? (
                <ChevronDownIcon className="w-4 h-4 text-customHighlightColor ml-2" />
              ) : (
                <ChevronLeftIcon className="w-4 h-4 text-customHighlightColor ml-2" />
              )}
            </h2>
            {expandedFootnotes.includes(key) && (
              <>
                <TextEditor text={footnote} />
                <button
                  className={`items-center pt-2 pb-2 px-4 font-normal rounded border-1 cursor-pointer justify-center no-underline ${
                    approved
                      ? "bg-green-500 text-white border-green-900"
                      : "hover:bg-customHighlightColor text-customHighlightColor border-customHighlightColor"
                  } hover:text-customLightGray transition-colors duration-300`}
                  onClick={() => setApproved(true)}
                >
                  {approved ? "Approved" : "Approve"}
                </button>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Right Section - Details Panel */}
      {/* <div className="w-full md:w-2/4 bg-white p-4 shadow-lg rounded-md md:ml-4 md:mt-0"> */}
      {/* <h1>Formatted Output</h1>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            table: ({ node, ...props }) => (
              <table
                className="table-auto border-collapse border border-gray-300 w-full"
                {...props}
              />
            ),
            th: ({ node, ...props }) => (
              <th
                className="border border-gray-300 px-4 py-2 bg-gray-100"
                {...props}
              />
            ),
            td: ({ node, ...props }) => (
              <td className="border border-gray-300 px-4 py-2" {...props} />
            ),
          }}
        >
          {footnotes}
        </ReactMarkdown>
        {/* <div className="flex justify-between items-center border-b py-3">
          <h4 className="text-lg font-bold text-customBlack">Footnote 01</h4>
          <div className="flex items-center space-x-1">
            <span className="text-sm text-blue-500 cursor-pointer">
              <XMarkIcon className="w-6 h-6 cursor-pointer text-customHighlightColor" />
            </span>
          </div>
        </div>
        <div className="w-full mt-6">
          <Table
            colNames={["Suggested disclosure", "Status"]}
            rows={[
              {
                row_data: [
                  "1 Notes and accounts receivable",
                  <font color="red">Awaiting approval</font>,
                ],
                metadata: { bgcolor: "gray" },
              },
              {
                row_data: [
                  "2 Notes and accounts receivable",
                  "Approved by Angela Kimberly on 23/08 at 11:58 AM",
                ],
                metadata: { bgcolor: "green" },
              },
              {
                row_data: [
                  "3 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "4 Investments in debt and equity",
                  <font color="green">Approved</font>,
                ],
              },
              {
                row_data: [
                  "5 Notes and accounts receivable",
                  "Awaiting approval",
                ],
                metadata: { bgcolor: "gray" },
              },
              {
                row_data: [
                  "6 Investments in debt and equity",
                  "Approved by Angela Kimberly on 02/09 at 11:23 AM",
                ],
                metadata: { bgcolor: "green" },
              },
              {
                row_data: [
                  "7 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "8 Investments in debt and equity",
                  "Awaiting approval",
                ],
                metadata: { bgcolor: "gray" },
              },
              { row_data: ["9 Notes and accounts receivable", "In Progress"] },
              { row_data: ["10 Notes and accounts receivable", "In Progress"] },
              {
                row_data: [
                  "11 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "12 Notes and accounts receivable",
                  "Approved by Angela Kimberly on 23/08 at 11:58 AM",
                ],
                metadata: { bgcolor: "green" },
              },
              {
                row_data: [
                  "13 Notes and accounts receivable",
                  "Awaiting approval",
                ],
                metadata: { bgcolor: "blue" },
              },
              { row_data: ["14 Investments in debt and equity", "Approved"] },
              {
                row_data: [
                  "15 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "16 Investments in debt and equity",
                  "Approved by Angela Kimberly on 02/09 at 11:23 AM",
                ],
                metadata: { bgcolor: "green" },
              },
              {
                row_data: [
                  "17 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "18 Investments in debt and equity",
                  "Awaiting approval",
                ],
                metadata: { bgcolor: "pink" },
              },
              { row_data: ["19 Notes and accounts receivable", "In Progress"] },
            ]}
            itemsPerPage={5}
          />
        </div> */}
      {/* </div> */}
    </div>
  );
}
