import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteApp, getMyApps } from "../redux/reducers/apps.reducer"; // Update the import path to your actual file

const useDeleteApp = (app_instance_id, user, path="/apps_l1") => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Using useCallback to prevent unnecessary re-renders
    const handleDelete = useCallback(
        async () => {
            setLoading(true);
            try {
                // Dispatch the deleteApp action
                await dispatch(
                    deleteApp({
                        appId: app_instance_id,
                        payload: {
                            user: user,
                        },
                    })
                );
            } catch (error) {
                console.error("Error deleting app:", error);
            } finally {
                setLoading(false);
            }

            // Fetch the updated list of apps after deletion
            await dispatch(getMyApps({ user }));

            // Navigate back to the apps page
            navigate(path);
        },
        [dispatch, navigate, app_instance_id, user, path] // dependencies
    );

    // Return the handleDelete function
    return { handleDelete, loading };
};

export default useDeleteApp;