import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { errorResponseHandler } from "../../utils"
import { getRequest, postRequest } from "../../utils/httpUtils"
import { getAppURL } from "../../utils/utils";

const initialState = {
  loading: undefined,
  apps: undefined,
  my_apps: undefined,
  app: undefined,
  appInstance: undefined,
  filesByApp: undefined,
  auditChecklist: undefined,
};

export const getApps = createAsyncThunk(
  "get/apps",
  async (body) => {
    try {
      const { data } = await postRequest("/api/get_apps", body);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const getApp = createAsyncThunk(
  "get/app",
  async (body) => {
    try {
      const { data } = await postRequest("/api/get_app", body);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const getAppInstance = createAsyncThunk(
  "get/app-instance",
  async (body) => {
    try {
      const { data } = await postRequest("/api/get_app_instance", body);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const getMyApps = createAsyncThunk(
  "get/my_apps",
  async (body) => {
    try {
      const { data } = await postRequest("/api/get_my_apps", body);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const createApp = createAsyncThunk(
  "create/app",
  async (body) => {
    try {
      const { data } = await postRequest("/api/create_app", body);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const getFilesByAppId = createAsyncThunk(
  "get/files_by_app_id",
  async (appId) => {
    try {
      const { data } = await getRequest(`/api/get_files_by_app_id/${appId}`);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const deleteApp = createAsyncThunk(
  "delete/app",
  async ({ appId, payload }) => {
    try {
      const { data } = await postRequest(`/api/delete_app/${appId}`, payload);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const updateApp = createAsyncThunk(
  "update/app",
  async ({ appId, payload }) => {
    try {
      const { data } = await postRequest(`/api/edit_app/${appId}`, payload);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const auditChecklist = createAsyncThunk(
  "audit/checklist",
  async (payload) => {
    try {
      const { data } = await postRequest("/api/audit_checklist", payload);
      return data;
    } catch (e) {
      errorResponseHandler(e)
    }
  }
);

export const appsSelector = createSelector(
  (state) => state.apps.apps,
  (num) => num
);

export const appSelector = createSelector(
  (state) => state.apps.app,
  (num) => num
);

export const appInstanceSelector = createSelector(
  (state) => state.apps.appInstance,
  (num) => num
);

export const myAppsSelector = createSelector(
  (state) => state.apps.my_apps,
  (apps) => {
    if (!apps) return {};
    const companyApps = (apps.company_apps || []).map((app) => ({
      ...app,
      url: getAppURL(app),
    }));
    const myApps = (apps.my_apps || []).map((app) => ({
      ...app,
      url: getAppURL(app),
    }));
    return {
      ...apps,
      company_apps: companyApps,
      my_apps: myApps,
    };
  }
);

export const filesByAppSelector = createSelector(
  (state) => state.apps.filesByApp,
  (num) => num
);

export const auditChecklistSelector = createSelector(
  (state) => state.apps.auditChecklist,
  (num) => num
);

export const loadingSelector = createSelector(
  (state) => state.apps.loading,
  (num) => num
);

const appsReducer = createSlice({
  name: "apps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getApps.pending, (state) => {
      state.loading = true
    });
    builder.addCase(getApps.fulfilled, (state, action) => {
      state.apps = action.payload
      state.loading = false
    });
    builder.addCase(getApps.rejected, (state) => {
      state.loading = false
    });

    builder.addCase(getApp.pending, (state) => {
      state.loading = true
    });
    builder.addCase(getApp.fulfilled, (state, action) => {
      state.app = (action.payload && action.payload.length) ? action.payload[0] : {}
      state.loading = false
    });
    builder.addCase(getApp.rejected, (state) => {
      state.loading = false
    });

    builder.addCase(getAppInstance.pending, (state) => {
      state.loading = true
    });
    builder.addCase(getAppInstance.fulfilled, (state, action) => {
      state.appInstance = (action.payload && action.payload.length) ? action.payload[0] : {}
      state.loading = false
    });
    builder.addCase(getAppInstance.rejected, (state) => {
      state.loading = false
    });

    builder.addCase(getMyApps.pending, (state) => {
      state.loading = true
    });
    builder.addCase(getMyApps.fulfilled, (state, action) => {
      state.my_apps = action.payload
      state.loading = false
    });
    builder.addCase(getMyApps.rejected, (state) => {
      state.loading = false
    });

    builder.addCase(createApp.pending, (state) => {
      state.loading = true
    });
    builder.addCase(createApp.fulfilled, (state) => {
      state.loading = false
    });
    builder.addCase(createApp.rejected, (state) => {
      state.loading = false
    });
    
    builder.addCase(getFilesByAppId.pending, (state) => {
      state.loading = true
    });
    builder.addCase(getFilesByAppId.fulfilled, (state, action) => {
      if (!state.filesByApp || JSON.stringify(state.filesByApp) !== JSON.stringify(action.payload)) {
        state.filesByApp = action.payload
      }
      state.loading = false
    });
    builder.addCase(getFilesByAppId.rejected, (state) => {
      state.loading = false
    });

    builder.addCase(deleteApp.pending, (state) => {
      state.loading = true
    });
    builder.addCase(deleteApp.fulfilled, (state) => {
      state.loading = false
    });
    builder.addCase(deleteApp.rejected, (state) => {
      state.loading = false
    });

    builder.addCase(updateApp.pending, (state) => {
      state.loading = true
    });
    builder.addCase(updateApp.fulfilled, (state) => {
      state.loading = false
    });
    builder.addCase(updateApp.rejected, (state) => {
      state.loading = false
    });

    builder.addCase(auditChecklist.pending, (state) => {
      state.loading = true
    });
    builder.addCase(auditChecklist.fulfilled, (state, action) => {
      state.auditChecklist = action.payload
      state.loading = false
    });
    builder.addCase(auditChecklist.rejected, (state) => {
      state.loading = false
    });

  },
});

export default appsReducer.reducer;

