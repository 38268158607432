import React from "react";
import StreamingText from "./StreamingText";

export const SimpleChatRow = ({ row, accountingQueryOutput, user }) => {
  // Check if the row represents the assistant or the user
  const isAssistant = row.role === "assistant";
  const isUser = row.role === "user";
  return (
    <div className="flex w-full">
      {accountingQueryOutput.length > 0 && (
        <div className="flex mb-4 w-full">
          {isAssistant && (
            <img
              className="h-8 w-8 ml-2 rounded-full bg-black"
              src={`https://app.tellen.ai/favicon.ico`}
              alt=""
            />
          )}
          <div className="w-full">
            <div className="flex flex-1 mx-2 bg-white rounded-lg shadow">
              <p className="self-start m-3 text-sm text-gray-800">
                {/* Display the assistant's or user's message */}
                <StreamingText text={row.message} done={row.completed} />
                
                {/* Placeholder text when assistant is typing */}
                {row.message === "" && isAssistant && !row.completed && (
                  <span>...</span>
                )}
              </p>
            </div>
          </div>
          {isUser && (
            <img
              className="h-8 w-8 mr-2 rounded-full bg-gray-50"
              src={user["imageUrl"]}
              alt=""
            />
          )}
        </div>
      )}
    </div>
  );
};
export default SimpleChatRow;