import React, { useCallback, useMemo } from "react";

const BoardMinutesRow = ({ meeting, setCurrentReportURL, setChecklistFileID, currentReportURL, setCurrentSource, successfulFileUploads }) => {
  const handleClick = useCallback(() => {
    setCurrentReportURL(meeting.meeting_file_url);
    setChecklistFileID(meeting.meeting_file_id);
    setCurrentSource(successfulFileUploads.find((file) => file.data["file_id"] === meeting.meeting_file_id));
  }, [meeting, setCurrentReportURL, setChecklistFileID]);
  const attendees = useMemo(() => meeting.attendees.join(", "), [meeting]);

  return (
    <tr
      className={`border-b border-gray-300 text-gray-600 hover:bg-gray-200 no-underline cursor-pointer ${
        currentReportURL === meeting.meeting_file_url ? "bg-gray-200" : null
      }`}
      onClick={handleClick}
    >
      <td className="text-sm py-2 text-wrap">{meeting.meeting_file_name}</td>
      <td className="text-sm py-2 text-wrap">{meeting.date}</td>
      <td className="text-sm py-2 text-wrap">{meeting.summary}</td>
      <td className="text-sm py-2 text-wrap">{attendees}</td>
    </tr>
  );
};

const BoardMinutesTable = ({ analyzerContext }) => {
  const {
    boardMinuteDetails,
    setCurrentReportURL,
    setChecklistFileID,
    currentReportURL,
    setCurrentSource,
    successfulFileUploads,
  } = analyzerContext;
  if (boardMinuteDetails.length === 0) {
    return null;
  }
  return (
    <table className="table-auto w-full">
      <thead>
        <tr className="border-b border-gray-300">
          <th className="min-w-[200px]">File</th>
          <th className="min-w-[100px]">Date</th>
          <th className="min-w-[250px]">Summary</th>
          <th className="min-w-[25px]">Attendees</th>
        </tr>
      </thead>
      <tbody>
        {boardMinuteDetails && boardMinuteDetails.map((meeting) => (
          <BoardMinutesRow
            key={meeting.meeting_file_name}
            meeting={meeting}
            currentReportURL={currentReportURL}
            setCurrentReportURL={setCurrentReportURL}
            setChecklistFileID={setChecklistFileID}
            setCurrentSource={setCurrentSource}
            successfulFileUploads={successfulFileUploads}
          />
        ))}
      </tbody>
    </table>
  );
};

export default BoardMinutesTable;
