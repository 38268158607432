import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorResponseHandler } from "../../utils";
import { getRequest } from "../../utils/httpUtils";

const initialState = {
  conversationsList: [],
  loading: undefined,
};

export const getConversationsHistory = createAsyncThunk(
  "get/conversations_history",
  async () => {
    try {
      const { data } = await getRequest("/api/get_conversations");
      return data;
    } catch (e) {
      errorResponseHandler(e);
    }
  }
);

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConversationsHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConversationsHistory.fulfilled, (state, action) => {
      state.conversationsList = action.payload.conversations;
      state.loading = false;
    });
    builder.addCase(getConversationsHistory.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectConversationsList = (state) => state.conversations.conversationsList || [];

export default conversationsSlice.reducer;