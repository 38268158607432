import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

const CustomNavLink = ({
    to,
    isActive,
    disabled,
    activeClassName = 'bg-customHighlightColor',
    disabledClassName = 'cursor-default text-customDarkGray',
    baseClassName = 'transition-colors duration-300 no-underline hover:bg-customHighlightColor group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
    className = '',
    children,
    ...props
}) => {
    // Determine the final class names based on props
    const finalClassName = useMemo(() => {
        return `
      ${baseClassName}
      ${disabled ? disabledClassName : 'cursor-pointer text-customLightGray'}
      ${isActive ? activeClassName : ''}
      ${className}
    `.trim();
    }, [baseClassName, className, disabled, disabledClassName, activeClassName, isActive]);

    return (
        <NavLink
            to={to || "#"}
            className={finalClassName}
            {...props}
        >
            {children}
        </NavLink>
    );
};

export default CustomNavLink;