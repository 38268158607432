import React from "react";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";

export const highlightUtil = (highlightAreas, currentFile = null) => {
  // https://react-pdf-viewer.dev/examples/render-the-highlight-areas/
  const renderHighlights = (props) => (
    <div>
      {highlightAreas
        .filter(({ pageIndex }) => pageIndex === props.pageIndex)
        .filter(
          ({ file_id: fileID }) =>
            currentFile === null ||
            (typeof currentFile === "string" &&
              currentFile.endsWith(`${fileID}.pdf`))
        )
        .map((area, idx) => (
          <div
            key={idx}
            className="highlight-area"
            style={{
              background: "yellow",
              opacity: 0.4,
              ...props.getCssProperties(area, props.rotation),
            }}
          />
        ))}
    </div>
  );

  return highlightPlugin({
    trigger: Trigger.None,
    renderHighlights,
  });
};
