const divideApps = (apps) => {
    const hiddenApps = [];
    const visibleApps = [];
    apps.forEach((app) => {
        if (app.is_hidden) {
            hiddenApps.push(app);
        } else {
            visibleApps.push(app);
        }
    });
    return { hiddenApps, visibleApps };
}

const getAppData = (app) => ({
    label: app.name,
    to: app.url,
    exact: true,
    disabled: app.is_hidden,
    app_id: app.app_instance_id,
})

// Function to create submenu items
const createSubmenu = (appData, appType) => {
    const appTypeData = appData[appType];
    if (!appTypeData) return [];
    const { hiddenApps, visibleApps } = divideApps(appTypeData);
    const hiddenAppData = hiddenApps.map(getAppData);
    const visibleAppData = visibleApps.map(getAppData);
    return {
        hiddenApps: hiddenAppData,
        visibleApps: visibleAppData,
    }
};

export default createSubmenu;