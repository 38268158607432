import ProgressBar from "../../utils/progressBar";
import ChooseTemplate from "./ChooseTemplate";
import GenerateFootnote from "./GenerateFootnote";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChooseFootnote from "./ChooseFootnote";
import { filesByAppSelector } from "../../redux/reducers/apps.reducer";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import { getFilesByAppId } from "../../redux/reducers/apps.reducer";
import GenericExtractor from "../GenericExtractor/GenericExtractor";
import { postRequest } from "../../utils/httpUtils";

const FootnoteAI = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  // const appFiles = useSelector(filesByAppSelector);
  const { appInstanceData } = useAppInstanceData();

  const getStatus = (stepIndex, currentStep) => {
    if (stepIndex === currentStep) return "current";
    if (stepIndex < currentStep) return "completed";
    return "upcoming";
  };

  const stepNames = [
    "Upload data",
    "Suggested footnotes",
    // "Choose template",
    "Generate footnote",
  ];

  const steps = stepNames.map((name, index) => ({
    name,
    status: getStatus(index, currentStep),
  }));

  // UPLOADING DATA
  const [fileUploadType, setFileUploadType] = useState(null);

  const handleCheckboxChange = (value) => {
    setFileUploadType(fileUploadType === value ? null : value);
  };

  const [
    successfulFileUploadsFromUploadTemplates,
    setSuccessfulFileUploadsFromUploadTemplates,
  ] = useState([]);

  const [
    successfulFileUploadsFromUploadData,
    setSuccessfulFileUploadsFromUploadData,
  ] = useState([]);

  const [filesDroppedFromUploadData, setFilesDroppedFromUploadData] =
    useState(false);
  const [filesDroppedFromUploadTemplates, setFilesDroppedFromUploadTemplates] =
    useState(false);

  // CHOOSING TEMPLATE FOOTNOTES
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedTemplateTitles, setSelectedTemplateTitles] = useState({});
  const [workpapersForFootnote, setWorkpapersForFootnote] = useState({});
  const [promptsForFootnotes, setPromptsForFootnotes] = useState({});

  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  useEffect(() => {
    if (appInstanceData && appInstanceData.id) {
      dispatch(getFilesByAppId(appInstanceData.id));
    }
  }, [appInstanceData]);

  // useEffect(() => {
  //   console.log(
  //     "XG successfulFileUploadsFromUploadTemplates",
  //     successfulFileUploadsFromUploadTemplates
  //   );
  //   if (appFiles)
  //     setSuccessfulFileUploadsFromUploadTemplates(
  //       appFiles.map((file) => ({ data: file }))
  //     );
  // }, [appFiles]);

  const [footnotes, setFootnotes] = useState([]);

  const fileIDs = successfulFileUploadsFromUploadTemplates?.map(
    (template) => template.data.file_id
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postRequest("/api/footnote_ai/get_footnotes", {
          file_ids: fileIDs,
        });
        setFootnotes(response.data.data);

        console.log(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };

    fetchData();
  }, [successfulFileUploadsFromUploadTemplates]);

  return (
    <>
      <ProgressBar steps={steps} onStepClick={handleStepClick} />
      <div className="flex justify-between w-full px-4 pb-1">
        <div className="w-1/2 flex justify-start">
          {currentStep > 0 ? (
            <button
              onClick={prevStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Previous
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>

        <div className="w-1/2 flex justify-end">
          {currentStep < steps.length - 1 ? (
            <button
              onClick={nextStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Next
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>
      </div>
      {/* {successfulFileUploadsFromUploadTemplates.length === 0 &&
        successfulFileUploadsFromUploadData.length === 0 && (
          <b>No sample data uploaded. Please click Edit below.</b>
        )} */}

      {currentStep === 0 && (
        <>
          <p>
            Select the file type you'd like to upload. We require last year's
            financial statement, and a number of audit workpapers.
          </p>
          <div className="flex space-x-4">
            <label className="flex items-center border rounded-lg p-2">
              <input
                type="checkbox"
                checked={fileUploadType === "financial_statements"}
                onChange={() => handleCheckboxChange("financial_statements")}
                className="mr-2"
              />
              Financial Statements
            </label>
            <label className="flex items-center border rounded-lg p-2">
              <input
                type="checkbox"
                checked={fileUploadType === "audit_workpapers"}
                onChange={() => handleCheckboxChange("audit_workpapers")}
                className="mr-2"
              />
              Audit Workpapers
            </label>
          </div>
          {fileUploadType === "financial_statements" &&
            (successfulFileUploadsFromUploadTemplates.length !== 10000 ? (
              <GenericExtractor
                successfulFileUploads={successfulFileUploadsFromUploadTemplates}
                setSuccessfulFileUploads={
                  setSuccessfulFileUploadsFromUploadTemplates
                }
                filesDropped={filesDroppedFromUploadTemplates}
                setFilesDropped={setFilesDroppedFromUploadTemplates}
                type="10k"
                showHeader={false}
              />
            ) : (
              <p>You've already uploaded a file.</p>
            ))}
          {fileUploadType && fileUploadType === "audit_workpapers" && (
            <GenericExtractor
              successfulFileUploads={successfulFileUploadsFromUploadData}
              setSuccessfulFileUploads={setSuccessfulFileUploadsFromUploadData}
              filesDropped={filesDroppedFromUploadData}
              setFilesDropped={setFilesDroppedFromUploadData}
              type="upload"
              showHeader={false}
            />
          )}
        </>
      )}
      {currentStep === 1 && (
        <>
          <ChooseFootnote
            appInstanceID={appInstanceData.id}
            footnotes={footnotes}
            setFootnotes={setFootnotes}
            selectedTemplates={selectedTemplates}
            setSelectedTemplates={setSelectedTemplates}
            setSelectedTemplateTitles={setSelectedTemplateTitles}
            relatedWorkpapers={successfulFileUploadsFromUploadData}
            workpapersForFootnote={workpapersForFootnote}
            setWorkpapersForFootnote={setWorkpapersForFootnote}
            promptsForFootnotes={promptsForFootnotes}
            setPromptsForFootnotes={setPromptsForFootnotes}
          />
        </>
      )}
      {/* {currentStep === 2 && (
        <ChooseTemplate
          footnotes={footnotes}
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
        />
      )} */}
      {currentStep === 2 && (
        <GenerateFootnote
          selectedTemplates={selectedTemplates.filter((t) => Number(t))} // Templates that are in the database have a number as their ID TODO make sure to also include new templates
          selectedTemplateTitles={selectedTemplateTitles}
          workpapersForFootnote={workpapersForFootnote}
          appInstanceID={appInstanceData.id}
          promptsForFootnotes={promptsForFootnotes}
        />
      )}

      <div className="flex justify-between w-full px-4 pb-1">
        <div className="w-1/2 flex justify-start">
          {currentStep > 0 ? (
            <button
              onClick={prevStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Previous
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>

        <div className="w-1/2 flex justify-end">
          {currentStep < steps.length - 1 ? (
            <button
              onClick={nextStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Next
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default FootnoteAI;
