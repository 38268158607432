import React from "react"

const FootnoteAppIcon = ({className}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="64.000000pt" height="64.000000pt" viewBox="0 0 1024.000000 1024.000000"
            preserveAspectRatio="xMidYMid meet" className={className}>

            <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
                fill="currentColor" stroke="none">
                <path d="M230 5560 l0 -2500 800 0 800 0 0 895 0 895 1045 0 1045 0 0 575 0
575 -1045 0 -1045 0 0 430 0 430 1220 0 1220 0 -2 598 -3 597 -2017 3 -2018 2
0 -2500z"/>
                <path d="M5077 8053 c-4 -3 -7 -1128 -7 -2500 l0 -2493 740 0 740 0 2 1210 3
1210 1045 -1209 1045 -1210 703 0 702 -1 0 2500 0 2500 -735 0 -735 0 -2
-1232 -3 -1232 -1050 1232 -1050 1232 -696 0 c-383 0 -699 -3 -702 -7z"/>
            </g>
        </svg>
    )
}

export default FootnoteAppIcon