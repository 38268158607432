import { useState, useCallback } from 'react';
import { postRequest } from '../utils/httpUtils';
import { useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { getMyApps } from '../redux/reducers/apps.reducer';

/**
 * Custom React hook to encapsulate the logic for showing or hiding an app instance via API calls.
 * 
 * This hook provides two methods: one to show the app instance and another to hide it. It manages loading and error states internally,
 * and exposes the `showAppInstance` and `hideAppInstance` functions, as well as the current loading and error states.
 * 
 * @param {string} appInstanceId - The unique identifier for the application instance whose visibility is being toggled.
 * @returns {{ showAppInstance: function, hideAppInstance: function, loading: boolean, error: any }} An object containing the `showAppInstance` and `hideAppInstance` functions,
 * and states for loading and error handling.
 * 
 * - `showAppInstance`: A function that triggers the visibility of the app instance to be shown.
 * - `hideAppInstance`: A function that triggers the visibility of the app instance to be hidden.
 * - `loading`: A boolean indicating whether the toggle process is ongoing.
 * - `error`: Any error encountered during the toggle process.
 * 
 * @example
 * // Example usage in a component:
 * const { showAppInstance, hideAppInstance } = useToggleAppInstanceVisibility(appInstanceId);
 * showAppInstance(); // Triggers the app instance to be shown
 * hideAppInstance(); // Triggers the app instance to be hidden
 */
const useToggleAppInstanceVisibility = (appInstanceId) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useUser();
    const dispatch = useDispatch();

    const toggleVisibility = useCallback(async (action) => {
        setLoading(true);
        setError(null);

        try {
            await postRequest('/api/toggle_app_instance_visibility', {
                action,
                app_instance_id: appInstanceId,
            });
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
            dispatch(getMyApps({user}))
        }
    }, [appInstanceId, user, dispatch]);

    const showAppInstance = useCallback(() => {
        return toggleVisibility('SHOW');
    }, [toggleVisibility]);

    const hideAppInstance = useCallback(() => {
        return toggleVisibility('HIDE');
    }, [toggleVisibility]);

    return { showAppInstance, hideAppInstance, loading, error };
};

export default useToggleAppInstanceVisibility;
