import { useState, useCallback } from 'react';
import { postRequest } from '../utils/httpUtils';

/**
 * Custom React hook to encapsulate the logic for deleting a file via an API call.
 * 
 * This hook provides functionality to delete a file by making a POST request to a specific endpoint with the app instance ID and file ID. It manages loading and error states internally,
 * and exposes a performAction function to trigger the deletion process along with the current loading and error states.
 * 
 * @param {string} appInstanceId - The unique identifier for the application instance under which the file resides.
 * @returns {{ performAction: function, loading: boolean, error: any }} An object containing the performAction function to initiate file deletion, and states for loading and error handling.
 * 
 * - `performAction`: A function that accepts a fileId and handles the deletion process. It sets loading to true at the start, attempts to delete the file by making a POST request,
 *   and updates loading and error states accordingly based on the outcome of the request.
 * - `loading`: A boolean indicating whether the deletion process is ongoing.
 * - `error`: Any error encountered during the deletion process.
 * 
 * @example
 * // Example usage in GeneralDataChatAnalyzer.js:
 * const { performAction: performDelete } = useDeleteFile(instanceId);
 * performDelete(fileId); // Initiates deletion of a file with the given fileId under the current appInstanceId context.
 */
const useDeleteFile = (appInstanceId) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const performAction = useCallback(async (fileId) => {
    setLoading(true);
    setError(null);

    try {
      await postRequest(`/api/delete_file/${appInstanceId}/${fileId}`);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [appInstanceId]);

  return { performAction, loading, error };
};

export default useDeleteFile;