import React, { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { getApp, appSelector } from "./redux/reducers/apps.reducer";
import AppVideo from "./images/app-video.png";
import TellenIcon from "./images/icon.png";
import {
  DocumentArrowUpIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export const AppDetail = () => {
  const dispatch = useDispatch()
  const { app } = useParams();
  const appData = useSelector(appSelector)

  useEffect(() => {
    if (app) dispatch(getApp({ app }))
  }, [app]);

  return appData && (
    <div className="flex flex-col gap-4 bg-customLightGray">
      <div className="grid grid-cols-3 gap-4 pb-10">
        <div className="col-span-1">
          <img className="inline cursor-pointer" src={AppVideo} />
        </div>
        <div className="col-span-2 flex flex-col justify-between w-11/12">
          <h1 className="text-4xl font-bold text-customDarkGray">
            {appData.name}
          </h1>
          <p className="mb-5 text-customDarkGray">{appData.description}</p>
          <Link
            data-testid="add-to-my-apps-btn"
            to={`/app/create/${appData.slug}`}
            className="flex items-center pt-2 pb-2 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
          >
            {" "}
            Add to my apps
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 bg-white pt-8 gap-x-8">
        <div className="col-span-2">
          <div className="mb-8">
            {Array.isArray(appData.details) &&
              appData.details.map((detail, index) => (
                <Fragment key={index}>
                  <h3 className="text-xl font-bold flex items-center">
                    <DocumentArrowUpIcon className="h-6 w-6 text-blue-500 inline mr-2" />
                    {detail.header}
                  </h3>
                  <ul className="list-disc pl-5">
                    {detail.bullets.map((bullet, bulletIndex) => (
                      <li key={bulletIndex}>{bullet}</li>
                    ))}
                  </ul>
                </Fragment>
              ))}
          </div>
          <div className="mb-8">
            <h3 className="text-xl font-bold flex items-center">
              <PencilIcon className="h-6 w-6 text-blue-500 inline mr-2" />{" "}
              Creator
            </h3>
            <div className="flex items-center rounded  bg-customLightGray ml-5 w-fit p-2 text-gray-900">
              <img className="inline mr-2" src={TellenIcon} /> Tellen
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="w-11/12 text-xl flex justify-between text-customHighlightColor">
            <div></div>
            <div></div>
          </div>
          <p className="mb-5"></p>
        </div>
      </div>
    </div>
  );
};

export default AppDetail;
