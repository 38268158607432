import React from "react";

export const Summary = ({ metadata }) => {
  return (
    <p>
      <i>Here are the summaries of the files that were used: </i>
      <br />
      <br />
      <ul>
        {metadata.summaries.map(({ file_id, filename, summary }) => (
          <li key={file_id}>
            <span className="font-bold">{filename}</span>: {summary}
          </li>
        ))}
      </ul>
    </p>
  );
};
