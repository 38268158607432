import React, { useState, useEffect } from "react";
import SimpleChatRow from "./SimpleChatRow";
import { MessageForm } from "../components/messageForm";
import { useUser } from "@clerk/clerk-react";

export const Conversation = ({ questions, onChangeAnswers }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [conversationRows, setConversationRows] = useState([
    { role: "assistant", message: questions[0] },
  ]);
  const [done, setDone] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // Update chatMessages when questions change
  useEffect(() => {
    if (questions.length > 0) {
      setConversationRows([{ role: "assistant", message: questions[0] }]);
      setCurrentQuestionIndex(0);
      setDone(false);
    }
  }, [questions]);

  useEffect(() => {
    if (onChangeAnswers) {
      onChangeAnswers(conversationRows.filter((row) => row.role === "user").map((row) => row.message));
    }
  }, [conversationRows, onChangeAnswers]);

  const handleAnswerSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      // Add the user's answer to chat messages
      setConversationRows((prevRows) => [
        ...prevRows,
        { role: "user", message: inputValue },
      ]);
      // Move to the next question
      const nextQuestionIndex = currentQuestionIndex + 1;
      if (nextQuestionIndex < questions.length) {
        setConversationRows((prevRows) => [
          ...prevRows,
          { role: "assistant", message: questions[nextQuestionIndex] },
        ]);
        setCurrentQuestionIndex(nextQuestionIndex);
      } else {
        // Conclude the conversation
        setConversationRows((prevRows) => [
          ...prevRows,
          {
            role: "assistant",
            message: "Thank you for answering all questions!",
          },
        ]);
        setDone(true);
      }

      // Clear the input field
      setInputValue("");
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const { user } = useUser();

  return (
    <div>
      {conversationRows.map((row, index) => (
        <SimpleChatRow
          row={row}
          key={index}
          accountingQueryOutput={conversationRows}
          user={user}
        />
      ))}

      {!done && (
        <MessageForm
          onSubmit={handleAnswerSubmit}
          onChange={handleInputChange}
          value={inputValue}
          inputName="answer"
          placeholder="Type your answer"
          label=""
          fullWidth
          hideStar
          hideClear
        />
      )}
    </div>
  );
};
export default Conversation;
