import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux"
import { makeHexColor } from "./utils";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import { ClerkProvider } from "@clerk/clerk-react";
import { customizationsSelector, getCustomizations } from "./redux/reducers/app.reducer";

const fallbackHighlightColor = "#5E00F6";
const fallbackBackgroundColor = "#111827"; // bg-gray-900

export const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  
  const customizations = useSelector(customizationsSelector);

  useEffect(() => {
    dispatch(getCustomizations())
  }, [])

  // Fetch customizations and set the CSS variable
  useEffect(() => {
    if (customizations) {
      const data = customizations;
      console.log(data.environment)
      document.documentElement.style.setProperty(
        "--custom-highlight-color",
        data.highlight_color ? makeHexColor(data.highlight_color) : fallbackHighlightColor
      );
      document.documentElement.style.setProperty(
        "--bg-color",
        data.bgcolor ? makeHexColor(data.bgcolor) : fallbackBackgroundColor
      );
      if (data && data.name) {
        document.title = data.name;
      }
      if (data && data.posthog_key && data.environment.startsWith("production-")) {
        posthog.init(customizations.posthog_key, {
          api_host: "https://us.posthog.com",
          session_recording: {
            maskAllInputs: true,
          },
        });
      }

      if (data && data.environment && !Sentry.isInitialized()) {
        Sentry.init({
          dsn: "https://cbcf5b2c31b1cac5b1e1826c5a0b1240@o4506321850466304.ingest.sentry.io/4506321879236608",
          integrations: [
            new Sentry.BrowserTracing({
              // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: [
                "localhost",
                "https://tellen.ai",
                "https://s.tellen.ai",
                "https://app.tellen.ai",
                "https://tellen.ai",
                "https://clientdemo.tellenai.com",
                /^https:\/\/.*ngrok\.io/,
              ],
            }),
            new Sentry.Replay(),
          ],
          // Performance Monitoring
          tracesSampleRate: 1.0, // Capture 100% of the transactions
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          environment: data.environment,
        });
      }
      setInitialized(true);
    };
  }, [customizations]);

  const postHogOptions = {
    api_host: "https://app.posthog.com",
  };

  return (
    <Fragment>
      {initialized && customizations && (
        <ClerkProvider publishableKey={customizations.clerk_key}>
          <PostHogProvider apiKey={customizations.posthog_key} options={postHogOptions}>
            {children}
          </PostHogProvider>
        </ClerkProvider>
      )}
    </Fragment>
  );
};
