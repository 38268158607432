import React from "react";
import Typed from "react-typed";
import { ArrowDownOnSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { truncateText } from "./utils";

export const SourcesTable = ({
  successfulFileUploads = [],
  currentPDFURL = null, // We can soon get rid of currentPDFURL as it's part of currentSource
  setCurrentPDFURL = () => "",
  currentSource = null,
  setCurrentSource = () => {},
  fileType = null,
  setCurrentInvoiceDetailsIndex = () => 0,
  invoiceDetails = [],
  setSuccessfulFileUploads = () => {},
  setInvoiceDetails = () => {},
  shouldSkipResetAnalyzerState,
  urlInput = { current: { value: "" } },
  urlInputSubmitPressed = false,
  workingText = "Uploading",
  showDelete = true,
  onDelete = () => {},
}) => {
  return (
    <div className="max-h-36 overflow-y-scroll osx-sidebar-scrollbar inline-block pr-2">
      <table className="table-auto w-1/2">
        <thead>
          <tr className="border-b border-gray-300">
            <th className="min-w-[300px]">Sources</th>
            {fileType === "Invoice" && (
              <>
                <th className="min-w-[100px]">Date</th>
                <th className="min-w-[250px]">Vendor</th>
                <th className="min-w-[250px]">Customer</th>
                <th className="min-w-[100px]">Amount</th>
              </>
            )}
            <th className="min-w-[50px]"></th>
            <th className="min-w-[50px]"></th>
          </tr>
        </thead>
        <tbody>
          {successfulFileUploads.length > 0
            ? successfulFileUploads.map((fileUpload, index) => (
                <tr
                  className="border-b border-gray-300 whitespace-nowrap"
                  key={fileUpload.data["url"]}
                >
                  <td className="text-sm py-2">
                    {currentPDFURL == fileUpload?.data["url"] ||
                    currentSource?.data["url"] == fileUpload?.data["url"] ? (
                      <b>{truncateText(fileUpload.data["filename"])}</b>
                    ) : (
                      <a
                        className="text-gray-600 hover:text-customHighlightColor no-underline"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (!shouldSkipResetAnalyzerState) {
                            console.warn(
                              "Resetting Analyzer State feature is not defined"
                            );
                          } else {
                            shouldSkipResetAnalyzerState.current = true;
                          }
                          setCurrentPDFURL(fileUpload.data["url"]);
                          setCurrentSource(fileUpload);
                          if (fileType === "Invoice") {
                            setCurrentInvoiceDetailsIndex(index);
                          }
                        }}
                      >
                        {truncateText(fileUpload.data["filename"])}
                      </a>
                    )}
                  </td>
                  {fileType === "Invoice" && (
                    <>
                      <td className="py-2">
                        {invoiceDetails[index] ? (
                          JSON.parse(invoiceDetails[index])?.["Invoice Date"]
                            ?.text
                        ) : (
                          <Typed strings={["..."]} loop typeSpeed={40} />
                        )}
                      </td>
                      <td className="py-2">
                        {invoiceDetails[index] ? (
                          JSON.parse(invoiceDetails[index])?.["Vendor Name"]
                            ?.text
                        ) : (
                          <Typed strings={["..."]} loop typeSpeed={40} />
                        )}
                      </td>
                      <td className="py-2">
                        {invoiceDetails[index] ? (
                          JSON.parse(invoiceDetails[index])?.["Customer Name"]
                            ?.text
                        ) : (
                          <Typed strings={["..."]} loop typeSpeed={40} />
                        )}
                      </td>
                      <td className="py-2">
                        {invoiceDetails[index] ? (
                          JSON.parse(invoiceDetails[index])?.["Invoice Total"]
                            ?.text.symbol === "USD" ? (
                            "$"
                          ) : (
                            JSON.parse(invoiceDetails[index])?.["Invoice Total"]
                              ?.text.symbol
                          )
                        ) : (
                          <Typed strings={["..."]} loop typeSpeed={40} />
                        )}
                        {invoiceDetails[index]
                          ? JSON.parse(invoiceDetails[index])?.["Invoice Total"]
                              ?.text.amount
                          : null}
                      </td>
                    </>
                  )}
                  <td className="py-2">
                    <a
                      className="text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
                      href={fileUpload?.data["url"]}
                    >
                      <ArrowDownOnSquareIcon
                        className="h-4 w-4 shrink-0"
                        aria-hidden="true"
                      />
                    </a>
                  </td>
                  {showDelete && (
                    <td className="py-2">
                      <a
                        className="text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
                        href="#"
                        onClick={(e) => {
                          const fileId = fileUpload.data["file_id"];
                          e.preventDefault();
                          onDelete(fileId);

                          setSuccessfulFileUploads((successfulFileUploads) =>
                            successfulFileUploads.filter(
                              (item) => item.data.file_id !== fileId
                            )
                          );

                          if (fileType === "Invoice") {
                            setInvoiceDetails((invoiceDetails) =>
                              invoiceDetails.splice(index, 1)
                            ); // Remove the invoice from invoiceDetails
                          }
                        }}
                      >
                        <TrashIcon
                          className="h-4 w-4 shrink-0"
                          aria-hidden="true"
                        />
                      </a>
                    </td>
                  )}
                </tr>
              ))
            : null}
          {urlInputSubmitPressed ? (
            <p className="text-sm py-2">
              {workingText}
              {urlInput?.current?.value ? (
                <>
                  {" "}
                  <i>{truncateText(urlInput.current.value)}</i>
                </>
              ) : null}

              <Typed strings={["..."]} loop typeSpeed={40} />
            </p>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default SourcesTable;
