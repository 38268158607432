/*
This is a temporal file that will replace the markup of the component in RagQueryComponent.js
once all apps comply with the design from Esfera.

Please be careful about modifying anything other than the markup of this React component.
*/
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Typed from "react-typed";
import { useUser } from "@clerk/clerk-react";
import moment from "moment"
import ChatRow from "../ragQueryComponent/ChatRow";
import { MessageForm } from "../components";
import SavedPrompt from "./SavedPrompt";

const RagQueryComponent = ({
  analyzerContext,
  appInstanceId,
  fileIDs,
  generatedResponse,
  jumpToPage,
  originalUserText,
  userObj,
  submitQuery = () => {}, // This is a function that will be called when the user submits a query, it will eventually replace handleAccountingQuerySubmit
  returnType,
  setReturnType,
  fetchSavedPrompts,
}) => {

  // TODO, this should be deprecated
  const {
    accountingQueryDone,
    accountingQueryOutput,
    boardMinuteDetails,
    chunkLocations,
    doneAddToSavedPrompts,
    handleAccountingQuerySubmit,
    handleAddToSavedPromptsButton,
    queryHeaderText,
    queryInput,
    queryType,
    queryTypes,
    resetAnalyzerState,
    responseSource,
    savedPrompts,
    setCurrentSource,
    setQueryType,
    setSavedPrompts,
    successfulFileUploads,
    currentReportURL,
    setAddMoreFilesModalOpen,
  } = analyzerContext;

  const { user } = useUser();

  const bodyRef = useRef(null);

  // Scrolls to bottom of page on stream update
  useEffect(() => {
    // Implementation note: accountingQueryOutput is added as a dependency for performance purposes.
    // This ensures that the effect only runs when there's new output, reducing unnecessary re-renders.
    const div = bodyRef.current;
    // Null check is necessary because if bodyRef does not exist, the page fails.
    if (div === null) {
      return;
    }
    div.scrollTop = div.scrollHeight;
  }, [accountingQueryOutput]);

  const fileUploadsWithPages = useMemo(() => {
    if (!successfulFileUploads || !chunkLocations) return [];
    return successfulFileUploads.map(({ data }) => {
      const { filename, file_id: fileID, filetype } = data;
      const pages = chunkLocations
        .filter((page) => page.file_id === fileID)
        .map((page) => page.pageIndex);
      return { filename, fileID, pages, filetype };
    });
  }, [successfulFileUploads, chunkLocations]);

  const setSource = (fileID) => {
    setCurrentSource(
      successfulFileUploads.find(({ data }) => data.file_id === fileID)
    );
  };

  const renderQueryTypeButton = (label) => (
    <button
      key={label}
      onClick={() => {
        if (responseSource && !responseSource.closed) {
          responseSource.removeEventListener("message");
          responseSource.close();
        }

        setQueryType(label);
        setSavedPrompts([]);
        resetAnalyzerState();

        if (queryType === label) {
          setQueryType(queryTypes[0]);
        } else {
          setQueryType(label);
        }
      }}
      className={`text-white text-sm py-1 px-2 mr-1 rounded text-center
               ${
                 queryType === label
                   ? "bg-customHighlightColor"
                   : "bg-gray-600 hover:bg-customHighlightColor"
               } 
               ${queryType && queryType !== label ? "opacity-50" : ""}`}
      disabled={queryType && queryType === label}
    >
      {label}
    </button>
  );
  
  const [query, setQuery] = useState("");
  const [chatMesssages, setChatMesssages] = useState([]);
  // console.log("chat messages");

  /*
  The shouldSkipResetOnSourceChangeRef in React helps control the resetting behavior
  of a component when there's a change in the currentSource. It serves as a decision flag,
  allowing the component to bypass resetting its state and query in situations
  where the change is triggered by user interactions that do not require a full component refresh.
  This is beneficial for maintaining a seamless user experience and safeguarding against data loss from unnecessary resets.

  This ref is manually set to true in cases like user clicks on different sources or pages,
  preventing unnecessary state resets during such interactions.
  Additionally, it's checked against in the useEffect hook; if it's false,
  the component resets its state and clears the query.
  After running, the ref's value is reset to false to ensure the component resets appropriately
  for future source changes, thereby optimizing component behavior for user interactions and state management.
   */
  const shouldSkipResetOnSourceChangeRef = useRef(false);

  useEffect(() => {
    if (!shouldSkipResetOnSourceChangeRef.current) {
      resetAnalyzerState();
      setQuery("");
    }
    shouldSkipResetOnSourceChangeRef.current = false;
  }, [currentReportURL]);

  const isToday = useCallback((row, index) => {
    const firstIndex = accountingQueryOutput.findIndex(q => 
      moment(q.created_at).format("DD") === moment().format("DD") && moment(q.created_at).format("DD") === moment(row.created_at).format("DD")
    )
    return index === firstIndex
  }, [accountingQueryOutput])

  return (
    <div className="mt-2">
      <>
        {fileIDs.length > 0 && !boardMinuteDetails && (
          <p className="text-sm">
            Parsing source{fileIDs.length > 1 ? "s" : null}
            <Typed strings={["..."]} loop typeSpeed={40} />
          </p>
        )}

        {boardMinuteDetails && (
          <>
            <div className="flex h-full">
              <div className="flex-1 h-full">
                {boardMinuteDetails && (
                  <div className="flex flex-col h-full">
                    {
                      // Here I want to put everything
                    }
                    {
                      // Here it all begins
                    }
                    <div className="flex-1 flex flex-col h-full justify-between">
                      {accountingQueryOutput.length > 0 &&
                        accountingQueryDone &&
                        doneAddToSavedPrompts && (
                          <p className="text-sm">
                            <br />
                            Prompt saved.
                          </p>
                        )}

                      <div
                        className="overflow-x-scroll overflow-visible"
                        style={{ height: "calc(100vh - 450px)" }}
                        ref={bodyRef}
                      >
                        {accountingQueryOutput.map((row, index) => (
                          <ChatRow
                            key={row.uuid}
                            accountingQueryDone={accountingQueryDone}
                            accountingQueryOutput={accountingQueryOutput}
                            appInstanceId={appInstanceId}
                            chunkLocations={chunkLocations}
                            fileUploadsWithPages={fileUploadsWithPages}
                            generatedResponse={generatedResponse}
                            jumpToPage={jumpToPage}
                            originalUserText={originalUserText}
                            relevantFiles={analyzerContext.relevantFiles}
                            row={row}
                            setSource={setSource}
                            shouldSkipResetOnSourceChangeRef={
                              shouldSkipResetOnSourceChangeRef
                            }
                            user={user}
                            userObj={userObj}
                            submitQuery={submitQuery}
                            index={index}
                            isToday={isToday(row, index)}
                          />
                        ))}
                      </div>
                      {
                        // Here it ends
                      }
                      {successfulFileUploads.length > 0 && (
                        <div className="my-1">
                          {queryTypes &&
                            queryTypes.map((queryLabel) =>
                              renderQueryTypeButton(queryLabel)
                            )}
                        </div>
                      )}
                    </div>

                    <MessageForm
                      inputRef={queryInput}
                      fetchSavedPrompts={fetchSavedPrompts}
                      label={queryHeaderText}
                      value={query}
                      onFileButtonClick={() => {
                        setAddMoreFilesModalOpen(true);
                      }}
                      savedPrompts={savedPrompts}
                      onSelectPrompt={(prompt) => {
                        setQuery(prompt);
                      }}
                      returnType={returnType}
                      setReturnType={setReturnType}
                      onChange={(e) => setQuery(e.target.value)}
                      onSubmit={(e) => {
                        const text = accountingQueryOutput;
                        const _chatMessages = [...chatMesssages];
                        if (text) _chatMessages.push({ type: "ai", text });
                        _chatMessages.push({ type: "me", text: query });
                        setChatMesssages(_chatMessages);
                        setQuery("");
                        handleAccountingQuerySubmit(e, queryType);
                      }}
                      classNames="flex-1"
                      onClear={resetAnalyzerState}
                      onStar={handleAddToSavedPromptsButton}
                      fullWidth
                      appInstanceId={appInstanceId}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default RagQueryComponent;
