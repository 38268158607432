import { useState, useCallback } from 'react';
import { handleDownload } from '../utils';
import { postRequest } from '../utils/httpUtils';

/**
 * Custom React hook to export chat messages and download the resulting file.
 * 
 * This hook provides functionality to export chat messages by making a POST request to the /api/chat_export endpoint. 
 * It manages loading and error states internally and exposes functions to trigger the export process in either DOCX or XLSX format,
 * along with the current loading and error states.
 * 
 * @returns {{ performExportDocx: function, performExportXlsx: function, loading: boolean, error: any }} 
 * An object containing the performExportDocx and performExportXlsx functions to initiate the export process in the desired format, and states for loading and error handling.
 * 
 * - `performExportDocx`: A function that accepts an array of messages and handles exporting them as a DOCX file.
 * - `performExportXlsx`: A function that accepts an array of messages and handles exporting them as an XLSX file.
 * - `loading`: A boolean indicating whether the export process is ongoing.
 * - `error`: Any error encountered during the export process.
 * 
 * @example
 * // Example usage in a component:
 * const { performExportDocx, performExportXlsx, loading, error } = useChatExport();
 * - `performExportDocx`: A function that accepts an index and handles exporting the message at that index as a DOCX file.
 * - `performExportXlsx`: A function that accepts an index and handles exporting the message at that index as an XLSX file.
 */
const useChatExport = (messages) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const performExport = useCallback(async (exportType, index) => {
    if (!messages || !Array.isArray(messages) || messages.length === 0) {
      setError('No messages to export');
      return;
    }
    if (index < 0 || index >= messages.length) {
      setError('Invalid message index');
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const path = '/api/chat_export';
      const jsonData = { message: messages[index], export_type: exportType };
      const { data } = await postRequest(path, jsonData);
      await handleDownload(data.url, exportType, jsonData, "GET");
    } catch (err) {
      setError(err.message || 'An error occurred during export');
    } finally {
      setLoading(false);
    }
  }, [messages]);

  const performExportDocx = useCallback((index) => {
    return performExport('docx', index);
  }, [performExport]);

  const performExportXlsx = useCallback((index) => {
    return performExport('xlsx', index);
  }, [performExport]);

  return { performExportDocx, performExportXlsx, loading, error };
};

export default useChatExport;
