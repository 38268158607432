import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { logEvent } from "../redux/reducers/app.reducer";
import { postRequest } from "../utils/httpUtils";
import { formatTimestamp } from "../utils";

const Logs = () => {
  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(
        logEvent({
          user,
          event: "Looked at users table.",
        })
      );
    }
  }, [isLoaded, isSignedIn, user]);

  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [slackQuestions, setSlackQuestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postRequest("/api/admin/users", { user: user });

        console.log(response.data);
        setUsers(response.data["users"]);
        setEvents(response.data["events"]);
        setSlackQuestions(
          response.data.slack_events
            .flatMap((question) => question.events)
            .filter((event) => event !== null)
        );
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };

    if (isSignedIn) {
      fetchData();
    }
  }, [isLoaded, isSignedIn, user]);

  const [expandedRows, setExpandedRows] = useState({});

  const handleRowClick = (email) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [email]: !prevExpandedRows[email],
    }));
  };

  const TableRow = ({ user, expanded, toggle }) => {
    return (
      <>
        <tr onClick={toggle} className="cursor-pointer hover:bg-gray-100">
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="h-11 w-11 flex-shrink-0">
                <img
                  className="h-11 w-11 rounded-full"
                  src={user.image}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="font-medium text-gray-900">{user.name}</div>
                <div className="mt-1 text-gray-500">{user.email}</div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
            <div className="text-gray-900">
              {user.last_active === user.signed_up ? "-" : user.last_active}
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
            <div className="text-gray-900">{user.signed_up}</div>
          </td>
        </tr>
        {expanded && (
          <tr>
            <td colSpan="3" className="bg-gray-50">
              <div className="py-2 px-4">
                {
                  //Here begins the feed
                }
                <div className="flow-root">
                  <ul className="-mb-8 divide-y divide-gray-200">
                    {events &&
                      events
                        .filter((user_) => user_.user_id === user.id)
                        .map((userEvents, index) => {
                          // If there are no events, return a message or null
                          if (
                            !userEvents.events.length ||
                            userEvents.events.length === 0
                          ) {
                            return (
                              <li key={`no-events-${index}`} className="py-3">
                                <div className="text-center text-sm text-gray-500">
                                  No events to display
                                </div>
                              </li>
                            );
                          }

                          // If there are events, map them into list items
                          return userEvents.events.map((event, eventIndex) => (
                            <li key={eventIndex} className="py-3">
                              <div className="flex space-x-3">
                                <div className="flex-shrink-0 w-40 text-sm">
                                  <time dateTime={event.datetime}>
                                    {formatTimestamp(event.timestamp)}
                                  </time>
                                </div>
                                <div className="flex-grow min-w-0">
                                  <p className="text-sm text-gray-900">
                                    {event.event}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ));
                        })}
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };

  return (
    <>
      <h1 className="text-xl font-bold mb-4">Logs</h1>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name and Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Last Active
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Signed Up
                  </th>
                  {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    ></th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users &&
                  users.map((user) => (
                    <TableRow
                      key={user.email}
                      user={user}
                      expanded={!!expandedRows[user.email]}
                      toggle={() => handleRowClick(user.email)}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-8">
          {slackQuestions.length > 0 && (
            <>
              <h2 className="text-lg font-bold mb-4">Slack Events</h2>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <th className="w-3/4">Event</th>
                  <th className="w-1/4">Date</th>
                </thead>
                <tbody>
                  {slackQuestions.map((event, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td className="py-3 text-sm text-gray-500 break-words">
                        {event.event}
                      </td>
                      <td className="py-3 text-sm text-gray-500">
                        {formatTimestamp(event.timestamp)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Logs;
