import React, { useEffect } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $getRoot, $createParagraphNode, $createTextNode } from "lexical";
import {
  $createTableNode,
  $createTableCellNode,
  $createTableRowNode,
} from "@lexical/table";
import ToolbarPlugin from "./ToolbarPlugin";
import { marked } from "marked";

const theme = {
  // Define your editor theme here
  paragraph: "text-base mb-4",
  heading: {
    h1: "text-3xl font-bold mb-4", // Adjust font size for h1
    h2: "text-2xl font-semibold mb-3", // Adjust font size for h2
    h3: "text-xl font-medium mb-2", // Adjust font size for h3
    h4: "text-lg font-semibold mb-2", // Add style for h4
    h5: "text-md font-medium mb-2", // Add style for h5
    h6: "text-sm font-normal mb-2",
  },
  quote: "border-l-4 border-gray-300 pl-4 italic text-gray-700",
  list: {
    ul: "list-disc list-inside mb-4",
    ol: "list-decimal list-inside mb-4",
  },
  listitem: "ml-4",
  code: "bg-gray-100 p-2 rounded text-sm font-mono",
  bold: "font-bold",
  italic: "italic",
  underline: "underline",
  strikethrough: "line-through",
  table: "border-collapse border border-gray-300",
  tableCell: "border border-gray-300 p-2",
  tableRow: "bg-white",
  tableHeader: "bg-gray-100 font-bold",
};

// Function to parse Markdown and convert it into Lexical nodes
const parseMarkdownToLexicalNodes = (markdown) => {
  const tokens = marked.lexer(markdown);
  const nodes = [];

  tokens.forEach((token) => {
    switch (token.type) {
      case "heading":
        const headingNode = $createParagraphNode();
        const headingText = $createTextNode(token.text);
        headingText.toggleFormat("bold"); // Simulate heading with bold formatting
        headingNode.append(headingText);
        nodes.push(headingNode);
        break;
      case "paragraph":
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(token.text);
        paragraphNode.append(textNode);
        nodes.push(paragraphNode);
        break;
      case "strong":
        const boldNode = $createTextNode(token.text);
        boldNode.toggleFormat("bold");
        nodes.push(boldNode);
        break;
      case "em":
        const italicNode = $createTextNode(token.text);
        italicNode.toggleFormat("italic");
        nodes.push(italicNode);
        break;

      //new update
      case "table":
        const tableNode = $createTableNode();

        // Create the header row
        const headerRow = $createTableRowNode();
        token.header.forEach((cell) => {
          const headerCell = $createTableCellNode("header");
          const cellText = typeof cell === "string" ? cell : cell.text || ""; // Convert to string
          headerCell.append($createTextNode(cellText));
          headerRow.append(headerCell);
        });
        tableNode.append(headerRow);

        // Create each table row
        token.rows.forEach((row) => {
          const tableRow = $createTableRowNode();
          row.forEach((cell) => {
            const tableCell = $createTableCellNode();
            let cellText = typeof cell === "string" ? cell : cell.text || "";

            // Check for Markdown formatting in the cell content
            if (cellText.startsWith("**") && cellText.endsWith("**")) {
              cellText = cellText.slice(2, -2); // Remove ** from start and end
              const boldTextNode = $createTextNode(cellText);
              boldTextNode.toggleFormat("bold");
              tableCell.append(boldTextNode);
            } else {
              tableCell.append($createTextNode(cellText));
            }
            tableRow.append(tableCell);
          });
          tableNode.append(tableRow);
        });

        nodes.push(tableNode);
        break;
      // case "list":
      // const listNode = $createListNode(token.ordered ? "number" : "bullet");
      // token.items.forEach((item) => {
      //   const listItemNode = $createListItemNode();
      //   listItemNode.append($createTextNode(item.text));
      //   listNode.append(listItemNode);
      // });
      // nodes.push(listNode);
      // break;

      default:
        break;
    }
  });

  return nodes;
};

const InsertTextPlugin = ({ text }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (text) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        const nodes = parseMarkdownToLexicalNodes(text);
        nodes.forEach((node) => root.append(node));
      });
    }
  }, [editor, text]);

  return null;
};

// Toolbar Component

const TextEditor = ({ text }) => {
  //const [editor] = useLexicalComposerContext();
  const onChange = (editorState) => {
    if (text != "") {
      editorState.read(() => {
        const root = $getRoot();
        let markdownContent = "";

        root.getChildren().forEach((node) => {
          if (node.getType() === "paragraph") {
            markdownContent += node.getTextContent() + "\n\n";
          } else if (node.getType() === "heading") {
            const level = node.getTag();
            markdownContent += `${"#".repeat(
              level
            )} ${node.getTextContent()}\n\n`;
          } else if (node.getType() === "table") {
            // Convert table nodes to Markdown format
            const tableRows = node.getChildren();

            tableRows.forEach((row, rowIndex) => {
              if (rowIndex === 0) {
                // This is the header row
                const headerCells = row
                  .getChildren()
                  .map((cell) => cell.getTextContent())
                  .join(" | ");
                markdownContent += `| ${headerCells} |\n`;

                // Add a separator row for Markdown table syntax
                const separator = row
                  .getChildren()
                  .map(() => "---")
                  .join(" | ");
                markdownContent += `| ${separator} |\n`;
              } else {
                // This is a body row
                const bodyCells = row
                  .getChildren()
                  .map((cell) => cell.getTextContent())
                  .join(" | ");
                markdownContent += `| ${bodyCells} |\n`;
              }
            });
            markdownContent += "\n";
          }
          // Handle other node types
        });

        markdownContent = markdownContent.trimEnd();
      });
    }
  };

  const initialConfig = {
    namespace: "TextEditor",
    theme,
    nodes: [
      HeadingNode,
      QuoteNode,
      ListItemNode,
      ListNode,
      CodeHighlightNode,
      CodeNode,
      AutoLinkNode,
      LinkNode,
      TableCellNode,
      TableNode,
      TableRowNode,
    ],
    onError: (error) => {
      console.error("Editor Error:", error);
    },
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="container mx-auto p-4">
        <ToolbarPlugin />
        <RichTextPlugin
          contentEditable={
            <ContentEditable className="border border-gray-300 p-4 min-h-[150px] text-base focus:outline-none" />
          }
          placeholder={<div className="text-gray-400">Loading...</div>}
        />
        <OnChangePlugin onChange={onChange} />
        {/* <OnChangePlugin onChange={(editorState) => {
              const jsonState = editorState.toJSON(); // Convert to JSON
              console.log(jsonState);
            }}
              /> */}
        <HistoryPlugin />
        <AutoFocusPlugin />
        <ListPlugin />
        <LinkPlugin />
        <TablePlugin />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />

        <InsertTextPlugin text={text} />
      </div>
    </LexicalComposer>
  );
};

export default TextEditor;
