import React from "react";

const PillButton = ({ label, color, className = "" }) => {
  return (
    <span
      className={`px-4 py-2 rounded-full text-white font-bold ${color} ${className}`}
    >
      {label}
    </span>
  );
};

export default PillButton;
