import { Link } from "react-router-dom";
import React from "react";
import {
  ArrowDownTrayIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import FootnoteAppIcon from "../utils/FootnoteAppIcon";
import ChatbotIcon from "../utils/ChatbotIcon";
import classnames from "classnames";

const getIcon = (slug) => {
  const footnoteApps = [
    "footnote_ai0",
    "10k-footnote-search",
    "generate_a_footnote",
    "internal_consistency_check",
    "roll_forward_financial_statements",
  ];

  if (footnoteApps.includes(slug)) return FootnoteAppIcon;
  if (slug === "general_data_chat") return ChatbotIcon;
  return DocumentMagnifyingGlassIcon;
};

const AppCard = ({ card, url, cta_text, slug }) => {
  const Icon = getIcon(slug);
  const isFootnoteApp = Icon === FootnoteAppIcon;

  return (
    <div
      data-testid="card-item"
      className="group p-4 m-2 border-lightgray border-1 rounded-lg transition-colors duration-300 flex flex-col space-y-2 min-w-80 h-300 text-customDarkGray"
    >
      <div className="flex w-10 p-2 bg-blue-50">
        <Icon
          className={classnames("h-6 w-6", {
            "text-blue-500": !isFootnoteApp,
            "text-customHighlightColor": isFootnoteApp,
          })}
        />
      </div>
      <div className="flex items-start w-full flex-grow">
        <div
          data-testid="card-item-name"
          className="flex-1 text-lg font-medium break-words text-gray-700"
        >
          {card.name}
        </div>
      </div>
      <div className="w-full text-sm py-1 text-gray-500">
        {card.description}
      </div>
      <div className="flex flex-col items-start w-full flex-grow text-sm border-b border-lightgray">
        <p>{/* Add published date or other metadata here */}</p>
      </div>
      <Link
        data-testid="card-item-go-btn"
        className="flex items-center pt-2 pb-2 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
        to={url}
      >
        <ArrowDownTrayIcon className="w-4 h-4 mr-1" /> {cta_text}
      </Link>
    </div>
  );
};

export default AppCard;