import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { logEvent } from "../../redux/reducers/app.reducer";
import { postRequest } from "../../utils/httpUtils";
import {
  PencilIcon,
  TrashIcon,
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { formatNum } from "../../utils/utils";
import TypingDots from "../../utils/TypingDots";
import { CreatableSelect } from "../../components";

const extractTags = (initialTags) => {
  const initialTagsArray = initialTags?.trim().split(',').map(tag => tag.trim()) || [];
  return initialTagsArray.filter(tag => tag !== '');
}

const TagSelector = ({ uniqueTags, initialTags, onNewTags = () => {} }) => {
  // Compute the options for the select component
  const uniqueTagsOptions = useMemo(() => uniqueTags.map(tag => ({ label: tag, value: tag })), [uniqueTags]);

  // Initialize state for selectedTags
  const [selectedTags, setSelectedTags] = useState(() => {
    const initialTagsArray = extractTags(initialTags);
    return initialTagsArray.map(tag => ({ label: tag, value: tag }));
  });

  // Update the selected tags if `initialTags` prop changes
  useEffect(() => {
    const initialTagsArray = extractTags(initialTags);
    setSelectedTags(initialTagsArray.map(tag => ({ label: tag, value: tag })));
  }, [initialTags]);

  useEffect(() => {
    onNewTags(selectedTags.map(tag => tag.value));
  }, [selectedTags]);

  const onChange = useCallback((newValue) => {
    setSelectedTags(newValue);
  }, []);

  return (
    <CreatableSelect
      className="w-full"
      isMulti
      value={selectedTags}
      options={uniqueTagsOptions}
      onChange={onChange}
    />
  );
};

const ApprovedQuestionsAnswers = ({
  instance_id,
  successfulFileUploads,
  dynamicValues,
}) => {
  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();

  // const { instance_id } = useParams();
  // This was initially written as a standalone page, but then incorporated into EditApp.js. If you want to make it standalone again, use useParams to get the instace_id with a URL such as /general_data_chat/xxx/approved_qa and uncomment the relevant route in index.js

  console.log(instance_id);

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(
        logEvent({
          user,
          event: "Looked at approved questions/answers view.",
        })
      );
    }
  }, [isLoaded, isSignedIn, user]);

  const [appName, setAppName] = useState("");
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [slug, setSlug] = useState("");

  const [currentlyEditing, setCurrentlyEditing] = useState(null);
  const [editQuestion, setEditQuestion] = useState("");
  const [editAnswer, setEditAnswer] = useState("");

  const [generatingQAFromFile, setGeneratingQAFromFile] = useState(false);

  const [selectedTag, setSelectedTag] = useState("");
  const [uniqueTags, setUniqueTags] = useState([]);
  const [editTags, setEditTags] = useState([]);

  const handleEdit = (qa) => {
    setCurrentlyEditing(qa.id);
    setEditQuestion(qa.question);
    setEditAnswer(qa.answer);
  };

  const handleSave = async (id) => {
    try {
      const response = await postRequest("/api/save_chatbot_approved_qa", {
        approved_answer_id: id,
        app_instance_id: instance_id,
        question: editQuestion,
        answer: editAnswer,
        user: user,
        tags: editTags,
      });

      console.log(response.data);
      fetchQuestionAnswerData();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
    setCurrentlyEditing(null);
  };

  const handleGenerateQA = async (file_id) => {
    try {
      let create_approved_answers_system_prompt = null;
      let create_approved_answers_user_prompt = null;

      if (
        dynamicValues.hasOwnProperty("create_approved_answers_system_prompt")
      ) {
        create_approved_answers_system_prompt =
          dynamicValues["create_approved_answers_system_prompt"];
      }

      if (dynamicValues.hasOwnProperty("create_approved_answers_user_prompt")) {
        create_approved_answers_user_prompt =
          dynamicValues["create_approved_answers_user_prompt"];
      }

      const response = await postRequest("/api/get_qa_given_file", {
        app_instance_id: instance_id,
        file_id: file_id,
        create_approved_answers_system_prompt:
          create_approved_answers_system_prompt,
        create_approved_answers_user_prompt:
          create_approved_answers_user_prompt,
        user: user,
      });

      console.log(response.data);
      fetchQuestionAnswerData();
      setGeneratingQAFromFile(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
    setCurrentlyEditing(null);
  };

  const fetchQuestionAnswerData = async () => {
    try {
      const response = await postRequest("/api/get_chatbot_approved_qa", {
        instance_id: instance_id,
      });

      const data = response.data["questions_and_answers"] || [];
      setQuestionsAndAnswers(data);

      // Extract unique tags from the data
      const tags = [
        ...new Set(
          data.flatMap((qa) => qa.tags.split(",").map((tag) => tag.trim()))
        ),
      ];
      setUniqueTags(tags);

      setAppName(response.data["app_name"]);
      setSlug(response.data["slug"]);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchQuestionAnswerData();
    }
  }, [isLoaded, isSignedIn, user]);

  const handleDelete = async (id) => {
    try {
      console.log("Clicked for id: ", id);
      const response = await postRequest("/api/delete_chatbot_approved_qa", {
        approved_answer_id: id,
      });

      console.log(response.data);
      fetchQuestionAnswerData();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  const handleToggleApprovalState = async (id) => {
    try {
      console.log("Clicked for id: ", id);
      const response = await postRequest("/api/toggle_qa_approval", {
        approved_answer_id: id,
      });

      console.log(response.data);
      fetchQuestionAnswerData();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  // BEGIN: FOR THE SELECT BOX
  const [selectedFileId, setSelectedFileId] = useState("");

  const handleSelectChange = (event) => {
    setSelectedFileId(event.target.value);
  };

  const handleButtonClick = () => {
    if (selectedFileId) {
      setGeneratingQAFromFile(true);
      handleGenerateQA(selectedFileId);
    }
  };
  // END: FOR THE SELECT BOX

  const filteredQuestionsAndAnswers = useMemo(() => (
    selectedTag
      ? questionsAndAnswers.filter((qa) =>
        qa.tags
          .split(",")
          .map((tag) => tag.trim())
          .includes(selectedTag)
      )
      : questionsAndAnswers), [questionsAndAnswers, selectedTag]);

  return (
    <>
      <h1 className="text-xl font-bold mb-4">
        Approved Questions and Answers {/* for <i>{appName}</i>*/}
      </h1>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <p>
              If a query is asked of the <i>{appName}</i> chatbot that matches
              or is similar to one of the questions listed below, the
              corresponding "approved" answer will be returned instead of one
              based upon input documents.
            </p>
            <p>
              {filteredQuestionsAndAnswers.length !== 0 ? (
                <p>
                  {formatNum(filteredQuestionsAndAnswers.length)} approved
                  question–answer pair(s).{" "}
                  {formatNum(
                    filteredQuestionsAndAnswers.filter((item) => item.approved)
                      .length
                  )}{" "}
                  visible to users.
                </p>
              ) : (
                <p>Create your first approved question–answer pair below.</p>
              )}
            </p>

            <div className="p-5">
              {uniqueTags.length > 0 && (
                <div className="flex justify-end mb-4">
                  <select
                    id="tag-filter"
                    value={selectedTag}
                    onChange={(e) => setSelectedTag(e.target.value)}
                    className="border rounded px-2 py-1"
                  >
                    <option value="">All Tags</option>
                    {uniqueTags.map(
                      (tag) =>
                        tag !== "" && (
                          <option key={tag} value={tag}>
                            {tag}
                          </option>
                        )
                    )}
                  </select>
                </div>
              )}
              <table className="min-w-full border-collapse">
                <thead>
                  <tr>
                    <th className="border-b border-gray-300 px-4 py-2 bg-gray-100 w-1/4">
                      Query
                    </th>
                    <th className="border-b border-gray-300 px-4 py-2 bg-gray-100 w-3/2">
                      Answer
                    </th>
                    <th className="border-b border-gray-300 px-4 py-2 bg-gray-100 w-3/2">
                      Tag(s)
                    </th>
                    <th className="border-b border-gray-300 px-1 py-2 bg-gray-100 w-5">
                      Edit
                    </th>
                    <th className="border-b border-gray-300 px-1 py-2 bg-gray-100 w-5">
                      Delete
                    </th>
                    <th className="border-b border-gray-300 px-1 py-2 bg-gray-100 w-5">
                      Approved
                    </th>
                    <th className="border-b border-gray-300 px-1 py-2 bg-gray-100 w-5">
                      Source
                    </th>
                    <th className="border-b border-gray-300 px-1 py-2 bg-gray-100 w-5">
                      Creator/Updater
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredQuestionsAndAnswers?.map((qa) => (
                    <tr key={qa.id}>
                      <td className="border-b border-gray-300 px-4 py-2">
                        {currentlyEditing === qa.id ? (
                          <textarea
                            value={editQuestion}
                            onChange={(e) => setEditQuestion(e.target.value)}
                            className="h-32 w-full border rounded px-2 py-1"
                          />
                        ) : (
                          qa.question
                        )}
                      </td>
                      <td className="border-b border-gray-300 px-4 py-2">
                        {currentlyEditing === qa.id ? (
                          <textarea
                            value={editAnswer}
                            onChange={(e) => setEditAnswer(e.target.value)}
                            className="h-32 w-full border rounded px-2 py-1"
                          />
                        ) : (
                          qa.answer
                        )}
                      </td>
                      <td className="border-b border-gray-300 px-4 py-2">
                        {currentlyEditing === qa.id ? (
                          <TagSelector
                            initialTags={qa.tags}
                            uniqueTags={uniqueTags}
                            onNewTags={setEditTags}
                          />
                        ) : (
                          qa.tags
                        )}
                      </td>
                      <td className="border-b border-gray-300 px-1 py-2">
                        <div className="flex justify-center items-center h-full">
                          {currentlyEditing === qa.id ? (
                            <CheckCircleIcon
                              className="h-10 w-10 transition-colors transform mt-1 hover:text-customHighlightColor cursor-pointer"
                              onClick={() => {
                                handleSave(qa.id);
                              }}
                            />
                          ) : (
                            <PencilIcon
                              className="h-5 w-5 transition-colors transform mt-1 hover:text-customHighlightColor cursor-pointer"
                              onClick={() => handleEdit(qa)}
                            />
                          )}
                        </div>
                      </td>
                      <td className="border-b border-gray-300 px-1 py-2">
                        <div className="flex justify-center items-center h-full">
                          <TrashIcon
                            className="h-5 w-5 transition-colors transform mt-1 hover:text-customHighlightColor cursor-pointer"
                            onClick={() => {
                              if (currentlyEditing === "new") {
                                setCurrentlyEditing(null);
                                setQuestionsAndAnswers((prevState) =>
                                  prevState.slice(0, -1)
                                );
                              } else {
                                handleDelete(qa.id);
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td className="border-b border-gray-300 px-1 py-2">
                        {qa.id !== "new" && (
                          <div className="flex justify-center items-center h-full">
                            {
                              <button
                                type="button"
                                onClick={() => {
                                  handleToggleApprovalState(qa.id);
                                }}
                              >
                                {qa.approved ? (
                                  <EyeIcon className="h-5 w-5 transition-colors transform mt-1 hover:text-customHighlightColor cursor-pointer" />
                                ) : (
                                  <EyeSlashIcon className="h-5 w-5 transition-colors transform mt-1 hover:text-customHighlightColor cursor-pointer" />
                                )}
                              </button>
                            }
                          </div>
                        )}
                      </td>
                      <td className="border-b border-gray-300 px-1 py-2">
                        <div className="flex justify-center items-center h-full">
                          <a
                            href={
                              qa.file_name?.includes("https://") ||
                              qa.file_name?.includes("http://")
                                ? qa.file_name
                                : `/api/files/${qa.file_name}`
                            }
                            className="text-xs text-customGrey hover:text-customHighlight no-underline"
                          >
                            {qa.file_name?.length > 20
                              ? `${qa.file_name
                                  .replace("https://", "")
                                  .slice(0, 20)}...`
                              : qa.file_name?.replace("https://", "")}
                          </a>
                        </div>
                      </td>
                      <td className="border-b border-gray-300 px-1 py-2">
                        <div className="flex justify-center items-center h-full">
                          {qa.source_user_name}
                          <br />
                          {qa.source_user_email}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className="mt-4">
                <button
                  onClick={() => {
                    setQuestionsAndAnswers((prevState) => [
                      ...prevState,
                      { question: "", answer: "", id: "new" },
                    ]);
                    setCurrentlyEditing("new");
                    setEditAnswer("");
                    setEditQuestion("");
                  }}
                  type="button" // Otherwise the button may be of type submit, or something similar.
                >
                  + Create a new approved question–answer pair.
                </button>

                <br />
                <br />
                <div>
                  {successfulFileUploads.length > 0 && (
                    <>
                      Select a document below to automatically generate a list
                      of approved questions and answers from an uploaded file:
                      <br />
                      <br />
                      <select
                        value={selectedFileId}
                        onChange={handleSelectChange}
                      >
                        <option value="" disabled>
                          Select a file
                        </option>
                        {successfulFileUploads.map((file) => (
                          <option
                            key={file.data.file_id}
                            value={file.data.file_id}
                          >
                            {file.data.filename}
                          </option>
                        ))}
                      </select>
                      <br />
                      <br />
                      {!generatingQAFromFile ? (
                        <button
                          onClick={handleButtonClick}
                          type="button"
                          className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                        >
                          Generate question–answer pairs
                        </button>
                      ) : (
                        <>
                          Generating question–answer pairs
                          <TypingDots />
                        </>
                      )}
                    </>
                  )}
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedQuestionsAnswers;
