import { useState, useCallback, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { useDispatch } from "react-redux"
import { logEvent } from "../redux/reducers/app.reducer";
import { NotificationType, useNotifications } from "../utils/notifications/Notifications";
import { uploadDemoFiles as uploadDemoFilesInternal } from "./uploadDemoFiles";
import { ssePostRequest } from "../utils/httpUtils";
import { handleFileUpload } from "../utils/FileUpload/handleFileUpload";

export const useAuditChecklist = () => {
  const dispatch = useDispatch()
  const { isSignedIn, user, isLoaded } = useUser();

  const [filesDropped, setFilesDropped] = useState(false);
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentReportURL, setCurrentReportURL] = useState(null);

  const [numberOfReports, setNumberOfReports] = useState(0);
  const [numberOfChecklists, setNumberOfChecklists] = useState(0);

  const [responseSource, setResponseSource] = useState(null);

  const { addNotification } = useNotifications();

  const [highlightAreasLEFT, setHighlightAreasLEFT] = useState([]);
  const [highlightAreasRIGHT, setHighlightAreasRIGHT] = useState([]);

  const [reportDetails, setReportDetails] = useState(null);

  const [checklistPDFURL, setChecklistPDFURL] = useState(null);

  const [checklistFileID, setChecklistFileID] = useState(null);

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(logEvent({
        user,
        event: `Looked at Audit Checklist.`
      }));
    }
  }, [isLoaded, isSignedIn, user]);

  // This runs once the files have been uploaded and gets our structured table.
  const parsePDF = async (fileName, fileID, url = "") => {
    // First we need to find out if this is a checklist or not.

    if (!fileName.toLowerCase().includes("checklist")) {
      // This is the financial report
      try {
        const onFinal = (finalPayload) => {
          console.log("Final data received:", finalPayload);
          // Handle the final data here, similar to how it's done in useInvoiceCrossReference.js
          setReportDetails(finalPayload.value);
          setCurrentReportURL(finalPayload.value.file_url);
          setNumberOfReports((prevNumber) => prevNumber + 1);
          addNotification(
            "Report successfully parsed.",
            "",
            NotificationType.success
          );
        };

        // Define the onError callback
        const onError = (error) => {
          addNotification(
            "Error parsing financial report.",
            "",
            NotificationType.error
          );
          console.error("Error:", error);
        };

        // Call ssePostRequest with the appropriate parameters
        ssePostRequest("/api/get_tables", { file: fileID }, { onFinal, onError });
      } catch (error) {
        addNotification(
          "Error parsing financial report.",
          "",
          NotificationType.error
        );
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    } else {
      // This is the checklist to be filled!
      setChecklistPDFURL(url);
      setNumberOfChecklists((prevNumber) => prevNumber + 1);
      setChecklistFileID(fileID);
      addNotification(
        "Checklist successfully parsed.",
        "",
        NotificationType.success
      );
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = () => {
        setFilesDropped(true);

        if (responseSource) {
          responseSource.removeEventListener("message");
          responseSource.close();
        }
      };

      const thenAction = ({ file, fileID, response }) => {
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        console.log("Running parse PDF on ");
        console.log(file.name);
        parsePDF(file.name, fileID);
      };

      const catchAction = ({ fileID }) => {
        setSuccessfulFileUploads((successfulFileUploads) =>
          successfulFileUploads.filter(
            (file) => file.data["file_id"] !== fileID
          )
        );
      };
      handleFileUpload(
        acceptedFiles,
        { addNotification, user },
        { preAction, thenAction, catchAction }
      );
    },
    [user]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("SUCCESSFUL FILE UPLOADS!");
      console.log(successfulFileUploads);
    } else {
      setCurrentReportURL(null);
      setFilesDropped(false);
    }
  }, [successfulFileUploads]);

  const uploadDemoFiles = () => {
    setFilesDropped(true);

    console.log(`Loading demo files`);

    let sampleFiles = [
      "https://tellensamplefiles.blob.core.windows.net/main/AuditChecker/kpmg_checklist.pdf",
      "https://tellensamplefiles.blob.core.windows.net/main/AuditChecker/unilever.pdf",
    ];

    uploadDemoFilesInternal({
      sampleFiles,
      setFilesDropped,
      user,
      onSuccessfulUpload: (response, file, fileID) => {
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        parsePDF(file.filename, fileID, file.url);
      },
      onErrorUpload: () => {
        addNotification("Error uploading file.", "", NotificationType.error);
      },
    });
  };

  return {
    isLoaded,
    isSignedIn,
    user,
    responseSource,
    setResponseSource,
    filesDropped,
    setFilesDropped,
    successfulFileUploads,
    setSuccessfulFileUploads,
    currentReportURL,
    setCurrentReportURL,
    highlightAreasLEFT,
    setHighlightAreasLEFT,
    highlightAreasRIGHT,
    setHighlightAreasRIGHT,
    parsePDF,
    onDrop,
    reportDetails,
    setReportDetails,
    numberOfReports,
    numberOfChecklists,
    checklistPDFURL,
    setChecklistPDFURL,
    checklistFileID,
    setChecklistFileID,
    uploadDemoFiles,
  };
};
