import { useState, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "@clerk/clerk-react";
import { useDispatch } from "react-redux"
import { logEvent } from "../redux/reducers/app.reducer";
import {
  NotificationType,
  useNotifications,
} from "../utils/notifications/Notifications";
import { postRequest, ssePostRequest } from "../utils/httpUtils";
import { uploadDemoFiles as uploadDemoFilesInternal } from "./uploadDemoFiles";
import { handleFileUpload } from "../utils/FileUpload/handleFileUpload";

export const useInvoiceCrossReference = () => {
  const dispatch = useDispatch()
  const { isSignedIn, user, isLoaded } = useUser();
  const { addNotification } = useNotifications();

  const [filesDropped, setFilesDropped] = useState(false);
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentInvoiceURL, setCurrentInvoiceURL] = useState(null);
  const [numberOfInvoices, setNumberOfInvoices] = useState(0);
  const [numberOfStatements, setNumberOfStatements] = useState(0);
  const [responseSource, setResponseSource] = useState(null);
  const [highlightAreasLEFT, setHighlightAreasLEFT] = useState([]);
  const [highlightAreasRIGHT, setHighlightAreasRIGHT] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [tables, setTables] = useState([]);
  const [statementPDFURL, setStatementPDFURL] = useState(null);
  const [statementFileID, setStatementFileID] = useState(null);
  const [listOfDOSLines, setListOfDOSLines] = useState([]);

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(logEvent({
        user,
        event: `Looked at Invoice Cross Reference.`
      }));
    }
  }, [isLoaded, isSignedIn, user]);

  // This runs once the file has been uploaded and creates our RAG embeddings if NOT an invoice. If an invoice, this uses Azure Document Intelligence to get the key-value pairs.
  const parsePDF = async (fileName, fileID) => {
    // First we need to find out if this is an invoice or a bank statement.

    if (!fileName.toLowerCase().includes("statement")) {
      setListOfDOSLines((prevLines) => [
        ...prevLines,
        "Extracting data from invoice " + fileName + "...",
      ]);

      // This is not the statement!
      try {
        const response = await postRequest("/api/analyze_invoice", {
          file: fileID,
        });

        setInvoiceDetails((currentInvoices) => [
          ...currentInvoices,
          { data: response.data, uuid: uuidv4() },
        ]);
        setNumberOfInvoices((prevNumber) => prevNumber + 1);
        setListOfDOSLines((prevLines) => [
          ...prevLines,
          "Successfully extracted data from invoice " + fileName,
        ]);
        console.log(response.data);
        // addNotification(
        //   "Invoice successfully parsed.",
        //   "",
        //   NotificationType.success
        // );
      } catch (error) {
        addNotification(
          "Error extracting data from invoice.",
          "",
          NotificationType.error
        );
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    } else {
      // This is the statement!
      try {
        setListOfDOSLines((prevLines) => [
          ...prevLines,
          "Extracting data from statement " + fileName + "...",
        ]);
        ssePostRequest(
          "/api/get_tables",
          {
            file: fileID,
          },
          {
            onStatus: (statusPayload) => {
              // Handle status updates here
              console.log("Status update:", statusPayload);
              setListOfDOSLines((prevLines) => [
                ...prevLines,
                "Status update: " + statusPayload.value, // Assuming statusPayload has a 'message' property
              ]);
            },
            onFinal: (finalPayload) => {
              // Handle final data here
              setTables(finalPayload.value);
              setStatementPDFURL(finalPayload.value.file_url);
              setNumberOfStatements((prevNumber) => prevNumber + 1);
              setStatementFileID(fileID);
              setListOfDOSLines((prevLines) => [
                ...prevLines,
                "Successfully extracted data from statement " + fileName,
              ]);
            },
            onError: (error) => {
              // Handle errors here
              addNotification(
                "Error parsing statement.",
                "",
                NotificationType.error
              );
              console.error("Error:", error);
            },
          }
        );
      } catch (error) {
        addNotification("Error parsing statement.", "", NotificationType.error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = () => {
        setFilesDropped(true);

        if (responseSource) {
          responseSource.removeEventListener("message");
          responseSource.close();
        }
      };

      const thenAction = ({ file, fileID, response }) => {
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        console.log("Running parse PDF on ");
        console.log(file.name);
        setListOfDOSLines((prevLines) => [
          ...prevLines,
          "Successfully uploaded " + file.name,
        ]);
        parsePDF(file.name, fileID);
      };
      const catchAction = ({ fileID }) => {
        setSuccessfulFileUploads((previousUploads) =>
          previousUploads.filter((file) => file.data["file_id"] !== fileID)
        );
      };

      handleFileUpload(
        acceptedFiles,
        { addNotification, user },
        { preAction, thenAction, catchAction }
      );
    },
    [user]
  );

  useEffect(() => {
    if (successfulFileUploads.length < 1) {
      setCurrentInvoiceURL(null);
      setFilesDropped(false);
    }
  }, [successfulFileUploads]);

  const uploadDemoFiles = (companyName) => {
    setFilesDropped(true);

    console.log(`Loading demo files for ${companyName}`);

    let sampleFiles = [];

    if (companyName == "Mineral Minds") {
      sampleFiles = [
        "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200045%20(ABC).pdf",
        "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200046%20(XYZ).pdf",
        "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200047%20(BigTime).pdf",
        "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200052%20(Anytown).pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200054%20(Intnl).pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200055%20(ABC).pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200056%20(Anytown).pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200058%20(BigTime).pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200059%20(NRG).pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Invoice%200060%20(ABC).pdf",
        "https://tellensamplefiles.blob.core.windows.net/main/Mineral%20Minds/Mineral%20Minds%202023%20Bank%20Statements.pdf",
      ];
    } else if (companyName == "Swift Consulting") {
      sampleFiles = [
        "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Bank%20Statements.pdf",
        "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-01.pdf",
        "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-02.pdf",
        "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-03.pdf",
        "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-04.pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-05.pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-06.pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-07.pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-08.pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-09.pdf",
        // "https://tellensamplefiles.blob.core.windows.net/main/Swift/Swift%20Consulting%20Data%20_%20Invoices%20-%20Inv-10.pdf",
      ];
    } else {
      console.log("ERROR: No demo files for this company.");
    }

    uploadDemoFilesInternal({
      sampleFiles,
      setFilesDropped,
      user,
      onStartUpload: (file) => {
        setListOfDOSLines((prevLines) => [
          ...prevLines,
          "Uploading " + file.filename + "...",
        ]);
      },
      onSuccessfulUpload: (response, file, fileID) => {
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        setListOfDOSLines((prevLines) => [
          ...prevLines,
          "Successfully uploaded " + file.filename,
        ]);
        parsePDF(file.filename, fileID, file.url);
      },
      onErrorUpload: () => {
        addNotification("Error uploading file.", "", NotificationType.error);
      },
    });
  };

  return {
    isLoaded,
    isSignedIn,
    user,
    responseSource,
    setResponseSource,
    filesDropped,
    setFilesDropped,
    successfulFileUploads,
    setSuccessfulFileUploads,
    currentInvoiceURL,
    setCurrentInvoiceURL,
    highlightAreasLEFT,
    setHighlightAreasLEFT,
    highlightAreasRIGHT,
    setHighlightAreasRIGHT,
    parsePDF,
    onDrop,
    invoiceDetails,
    setInvoiceDetails,
    tables,
    setTables,
    numberOfInvoices,
    numberOfStatements,
    statementPDFURL,
    setStatementPDFURL,
    statementFileID,
    setStatementFileID,
    uploadDemoFiles,
    listOfDOSLines,
    setListOfDOSLines,
  };
};
