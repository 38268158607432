import React, { useMemo } from "react";

/**
 * `InvoiceDetails` is a functional component that displays a table of invoice details.
 * Each row in the table corresponds to a key-value pair from the parsed invoice details.
 * The `onMouseEnter` event handler is used to set the highlight areas when the mouse hovers over a row.
 *
 * @component
 * @param {Object} props - The properties that define the component.
 * @param {string} props.invoiceDetails - The invoice details in JSON format.
 * @param {function} props.setHighlightAreas - The function to set the highlight areas.
 * @returns {React.ElementType} Returns a table of invoice details if there are any, otherwise returns null.
 */
const InvoiceDetails = ({ invoiceDetails, setHighlightAreas }) => {
  const parsedInvoiceDetails = useMemo(() => {
    if (!invoiceDetails) return [];
    return Object.entries(JSON.parse(invoiceDetails.data)).filter(
      ([key, _]) => {
        return !["filename", "file_id", "file_url"].includes(key);
      }
    );
  }, [invoiceDetails]);

  if (!parsedInvoiceDetails.length) return null;
  
  return (
    <table className="table-auto w-full">
      <thead>
        <tr className="border-b border-gray-300">
          <th className="min-w-[150px]">Key</th>
          <th className="min-w-[50px]">Value</th>
        </tr>
      </thead>
      <tbody>
        {parsedInvoiceDetails.map(([key, value]) => {
          return (
            <tr
              key={key}
              className="border-b border-gray-300 hover:bg-gray-200 cursor-pointer"
              onMouseEnter={() =>
                setHighlightAreas([
                  {
                    pageIndex: value.page_number - 1,
                    height: (value.height / value.page_height) * 100,
                    width: (value.width / value.page_width) * 100,
                    left: (value.left / value.page_width) * 100,
                    top: (value.top / value.page_height) * 100,
                  },
                ])
              }
            >
              <td className="text-sm py-2">{key}</td>
              <td className="text-sm py-2">
                {typeof value.text === "string"
                  ? value.text
                  : value.text
                  ? Object.entries(value.text).map(
                      ([subKey, subValue]) =>
                        subValue &&
                        subKey !== "code" && (
                          <span key={subKey}>
                            {`${subKey}: ${subValue || "-"}, `}
                            <br />
                          </span>
                        )
                    )
                  : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default InvoiceDetails;
