import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { errorResponseHandler } from "../../utils";
import { getRequest, postRequest } from "../../utils/httpUtils";

const initialState = {
  loading: undefined,
  customizations: undefined,
  customDocs: undefined,
};

export const getCustomizations = createAsyncThunk(
  "get/customizations",
  async () => {
    try {
      const { data } = await getRequest("/api/customizations");
      return data;
    } catch (e) {
      errorResponseHandler(e);
    }
  }
);

export const getCustomDocs = createAsyncThunk(
  "get/custom_docs",
  async (payload) => {
    try {
      const { data } = await postRequest("/api/get_custom_docs", payload);
      return data;
    } catch (e) {
      errorResponseHandler(e);
    }
  }
);

export const logUser = createAsyncThunk("log/user", async (payload) => {
  try {
    const { data } = await postRequest("/api/log_user", payload);
    return data;
  } catch (e) {
    errorResponseHandler(e);
  }
});

export const logEvent = createAsyncThunk("log/event", async (payload) => {
  try {
    const { data } = await postRequest("/api/log_event", payload);
    return data;
  } catch (e) {
    errorResponseHandler(e);
  }
});

export const customizationsSelector = createSelector(
  (state) => state.app.customizations,
  (num) => num
);

export const customDocsSelector = createSelector(
  (state) => state.app.customDocs,
  (num) => num
);

export const loadingSelector = createSelector(
  (state) => state.app.loading,
  (num) => num
);

const stagingEnvironment = "production-ts3";
const developmentEnvironment = "development";

const appReducer = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomizations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomizations.fulfilled, (state, action) => {
      state.customizations = action.payload;

      if (
        action.payload.environment.startsWith("production") &&
        action.payload.environment !== stagingEnvironment
      ) {
        state.customizations.environmentLabelOptions = {
          bgColor: "bg-red-500",
          label: "PRODUCTION",
        };
      } else if (action.payload.environment === stagingEnvironment) {
        state.customizations.environmentLabelOptions = {
          bgColor: "bg-amber-500",
          label: "STAGING",
        };
      } else if (action.payload.environment === developmentEnvironment) {
        state.customizations.environmentLabelOptions = {
          bgColor: "bg-green-500",
          label: "DEVELOPMENT",
        };
      } else {
        state.customizations.environmentLabelOptions = {
          bgColor: "bg-red-500",
          label: "UNKNOWN",
        };
      }

      state.loading = false;
    });
    builder.addCase(getCustomizations.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCustomDocs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomDocs.fulfilled, (state, action) => {
      state.customDocs = action.payload;
      state.loading = false;
    });
    builder.addCase(getCustomDocs.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(logUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(logUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(logEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logEvent.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(logEvent.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default appReducer.reducer;
