import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createTableNodeWithDimensions,
    $createTableCellNode,
    $createTableRowNode
  } from "@lexical/table";
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from 'lexical';
import { INSERT_UNORDERED_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND } from '@lexical/list';
import { BoldIcon, ItalicIcon, UnderlineIcon, ListBulletIcon, TableCellsIcon, 
    Bars3BottomLeftIcon, Bars3Icon, Bars3BottomRightIcon, 
    PhotoIcon, EyeDropperIcon } from "@heroicons/react/24/outline";


const ToolbarPlugin = () => {
    const [editor] = useLexicalComposerContext();
  
    const applyFormat = (format) => {
        editor.update(() => {
          const selection = editor.getEditorState().read(() => $getSelection());
          if ($isRangeSelection(selection)) {
            // Ensure the commands are correctly dispatched
            if (format === "bold") {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
            } else if (format === "italic") {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
            } else if (format === "underline") {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
            } else if (format === "bullet") {
              editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
            } else if (format === "table") {
              const tableNode = $createTableNodeWithDimensions(3, 3);
              editor.dispatchCommand('INSERT_TABLE_COMMAND', tableNode);
            }
          }
        });
      };
    return (
        <div className="flex space-x-2 items-center p-2 bg-white border-b border-gray-200">
            {/* Font Dropdown */}
            <select className="p-1 border border-gray-300 rounded">
                <option value="Roboto">Roboto</option>
                <option value="Arial">Arial</option>
                <option value="Times New Roman">Times New Roman</option>
                {/* Add more font options here */}
            </select>

            {/* Formatting Icons */}
            <button onClick={() => applyFormat("bold")} className="p-1">
                <BoldIcon className="h-5 w-5 text-gray-500" />
            </button>
            <button onClick={() => applyFormat("italic")} className="p-1">
                <ItalicIcon className="h-5 w-5 text-gray-500" />
            </button>
            <button onClick={() => applyFormat("underline")} className="p-1">
                <UnderlineIcon className="h-5 w-5 text-gray-500" />
            </button>

            {/* List Icons */}
            <button onClick={() => applyFormat("bullet")} className="p-1">
                <ListBulletIcon className="h-5 w-5 text-gray-500" />
            </button>

            {/* Text Alignment Icons */}
            <button className="p-1">
                <Bars3BottomLeftIcon className="h-5 w-5 text-gray-500" />
            </button>
            <button className="p-1">
                <Bars3Icon className="h-5 w-5 text-gray-500" />
            </button>
            <button className="p-1">
                <Bars3BottomRightIcon className="h-5 w-5 text-gray-500" />
            </button>

            {/* Additional Icons */}
            <button className="p-1">
                <EyeDropperIcon className="h-5 w-5 text-gray-500" />
            </button>
            <button className="p-1">
                <PhotoIcon className="h-5 w-5 text-gray-500" />
            </button>
        </div>
    );
  };

export default ToolbarPlugin;