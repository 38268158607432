import { useUser } from "@clerk/clerk-react";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useMemo, useState } from "react";
import useEditPrompt from "../hooks/useEditPrompt";
import useDeletePrompt from "../hooks/useDeletePrompt";

/**
 * `SavedPrompt` is a React component that displays a saved prompt with options to edit or delete it.
 * 
 * This component provides the following functionalities:
 * - Displays a truncated version of the prompt.
 * - Allows the user to delete the prompt.
 * - Toggles an edit mode to update the prompt.
 * - Submits the updated prompt or cancels the edit.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.appInstanceId - The application instance ID used for context.
 * @param {function} props.fetchSavedPrompts - Function to fetch the updated list of saved prompts.
 * @param {string} props.id - The ID of the prompt to manage.
 * @param {string} props.prompts - Saved prompt
 * @param {function} props.setQuery - Function to set the query based on prompts.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <SavedPrompt
 *   appInstanceId="app123"
 *   fetchSavedPrompts={() => {}}
 *   id="prompt1"
 *   performDeletePrompt={(id) => {}}
 *   prompts={'Prompt 1'}
 *   setQuery={(prompts) => {}}
 * />
 */
const SavedPrompt = ({
    appInstanceId,
    fetchSavedPrompts,
    id,
    prompts,
    setQuery,
}) => {
    const { user } = useUser();
    const { editPrompt: performEditPrompt, loading } = useEditPrompt(appInstanceId, user);
    const { deletePrompt: performDeletePrompt } = useDeletePrompt(appInstanceId, user);
    const slicedPrompt = useMemo(() => prompts.slice(0, 50), [prompts]);
    const setQueryInternal = useCallback(() => setQuery(prompts), [prompts, setQuery]);
    const [shouldShowEdit, setShouldShowEdit] = useState(false);
    const showEdit = useCallback(() => setShouldShowEdit(true), []);
    const [editInputValue, setEditInputValue] = useState(prompts);
    const hideEdit = useCallback(() => {
        setShouldShowEdit(false);
        setEditInputValue(prompts);
    }, [prompts]);
    const setEditInputValueInternal = useCallback((e) => setEditInputValue(e.target.value), []);
    const performEditPromptInternal = useCallback(() => {
        performEditPrompt(id, editInputValue).then(hideEdit).finally(fetchSavedPrompts);
    }, [editInputValue, hideEdit, id, performEditPrompt, fetchSavedPrompts]);
    const performDeletePromptInternal = useCallback(() => {
        performDeletePrompt(id).finally(fetchSavedPrompts);
    }, [id, performDeletePrompt, fetchSavedPrompts]);


    return (
        <div className="flex gap-1">
            <button
                type="button"
                className="text-gray-500 text-sm hover:text-customHighlightColor text-left"
                onClick={setQueryInternal}
            >
                - {slicedPrompt}...
            </button>
            <button type="button" onClick={performDeletePromptInternal}>
                <TrashIcon className="h-5 w-5" />
            </button>
            {!shouldShowEdit && (
                <button type="button" onClick={showEdit}>
                    <PencilIcon className="h-5 w-5" />
                </button>
            )}
            {shouldShowEdit && (
                <div className="flex gap-1">
                    <input
                        type="text"
                        className="border border-gray-300 rounded-md"
                        value={editInputValue}
                        disabled={loading}
                        onChange={setEditInputValueInternal}
                    />
                    <button type="button" onClick={performEditPromptInternal}>Submit</button>
                    <button type="button" onClick={hideEdit}>
                        <TrashIcon className="h-5 w-5" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default SavedPrompt;
