import React, { useState, useEffect } from "react";
import Typed from 'react-typed';
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { useDispatch, useSelector } from "react-redux"
import { useAuditChecklist } from "./hooks/useAuditChecklist";
import { NotificationType, useNotifications } from "./utils/notifications/Notifications";
import { highlightUtil } from "./utils/highlightUtil";
import { Viewer } from "./utils";
import { auditChecklist, auditChecklistSelector } from "./redux/reducers/apps.reducer";

const AuditChecklist = () => {
  const dispatch = useDispatch()
  const {
    filesDropped,
    currentReportURL,
    urlInputSubmitPressed,
    highlightAreasLEFT,
    highlightAreasRIGHT,
    reportDetails,
    numberOfReports,
    numberOfChecklists,
    checklistPDFURL,
    setChecklistPDFURL,
    checklistFileID,
    uploadDemoFiles,
  } = useAuditChecklist();

  const pageNavigationPluginInstanceLEFT = pageNavigationPlugin();
  const pageNavigationPluginInstanceRIGHT = pageNavigationPlugin();

  const jumpToPageRIGHT = pageNavigationPluginInstanceRIGHT.jumpToPage;

  const [allFilesParsed, setAllFilesParsed] = useState(false);

  const [allProcessed, setAllProcessed] = useState(false);

  const { addNotification } = useNotifications();

  const highlightPluginInstanceLEFT = highlightUtil(highlightAreasLEFT);
  const highlightPluginInstanceRIGHT = highlightUtil(highlightAreasRIGHT);

  const checklist = useSelector(auditChecklistSelector)

  const doAuditChecklist = async () => {
    try {
      dispatch(auditChecklist({
        report: reportDetails,
        checklist: checklistFileID,
      }))
    } catch (error) {
      addNotification(
        "Error filling form based upon financial report.",
        "",
        NotificationType.error
      );
    }
  };

  useEffect(() => {
    if (numberOfReports === 1 && numberOfChecklists == 1) {
      setAllFilesParsed(true);
      doAuditChecklist();
    }
  }, [numberOfReports, numberOfChecklists]);

  useEffect(() => {
    if (checklist) {
      setChecklistPDFURL(checklist["filled_form_pdf_url"]);
      setAllProcessed(true);
      addNotification(
        "Form filled based upon financial report.",
        "",
        NotificationType.success
      );
    }
  }, [checklist])

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div className="m-5">
        <h1 className="text-2xl font-bold mb-5">Audit Checklist</h1>

        {!urlInputSubmitPressed && !filesDropped && (
          <>
            <div className="flex flex-col items-center">
              <br />
              <div>Try sample data:</div>
              <div className="flex justify-center space-x-4 mt-2">
                <button
                  className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                  onClick={() => uploadDemoFiles("Mineral Minds")}
                >
                  KPMG IFRS disclosure checklist and Unilver financial report
                </button>
              </div>
            </div>
          </>
        )}

        <div className="mt-2">
          {filesDropped && (
            <>
              <p>
                {allFilesParsed ? (
                  <i>
                    Successfully parsed a report and a checklist.
                    {!allProcessed ? (
                      <span>
                        <br />
                        <br />
                        Working through checklist items
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      </span>
                    ) : (
                      <span>
                        <br />
                        <br />
                        Filled checklist.
                      </span>
                    )}
                  </i>
                ) : null}
                <br />
              </p>
              {!reportDetails && !checklistFileID && (
                <p className="text-sm">
                  Parsing report and checklist
                  <Typed strings={["..."]} loop typeSpeed={40} />
                </p>
              )}

              <div className="flex">
                <div className="h-screen w-1/2">
                  {currentReportURL && (
                    <Viewer
                      fileUrl={currentReportURL}
                      plugins={[
                        highlightPluginInstanceLEFT,
                        pageNavigationPluginInstanceLEFT,
                      ]}
                    />
                  )}
                </div>
                <div className="h-screen w-1/2">
                  {checklistPDFURL && (
                    <Viewer
                      fileUrl={checklistPDFURL}
                      plugins={[
                        highlightPluginInstanceRIGHT,
                        pageNavigationPluginInstanceRIGHT,
                      ]}
                      onDocumentLoad={() => {
                        console.log("Document loaded!");
                        jumpToPageRIGHT(8);
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Worker>
  );
};

export default AuditChecklist;
