import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { logEvent } from "../../redux/reducers/app.reducer";
import { getRequest, postRequest } from "../../utils/httpUtils";
import PillButton from "../../utils/PillButton";
import { formatNum } from "../../utils/utils";
import { MessageForm, Select } from "../../components";
import BlackComputerScreen from "../../utils/BlackComputerScreen";
import Typed from "react-typed";
import DeleteAppButton from "../../utils/DeleteAppButton";
import { useParams } from "react-router-dom";

const TableRow = ({ deficiency, expanded, toggle }) => {
  const reportDate = useMemo(
    () => new Date(deficiency.report_date).toDateString(),
    [deficiency]
  );
  return (
    <>
      <tr onClick={toggle} className="cursor-pointer hover:bg-gray-100">
        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="font-medium text-gray-900">
                {deficiency.company_name}
              </div>
              <div className="mt-1 text-gray-500">{reportDate}</div>
            </div>
          </div>
        </td>
        <td className="w-1/6 whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900 flex flex-wrap gap-2 break-words">
            <PillButton
              className="text-wrap text-xs"
              color="bg-blue-500"
              label={deficiency.deficiency_audit_area}
              key={deficiency.deficiency_audit_area}
            />
          </div>
        </td>
        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="font-medium text-gray-900">
                {deficiency.deficiency_issuer}
              </div>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900 flex flex-wrap gap-2 text-balance break-words">
            {deficiency.deficiency_violated_standard
              .split(/[;,]/)
              .map((standard) => (
                <div key={standard.trim()} className="">
                  {standard.trim()}
                </div>
              ))}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900">{deficiency.report_country}</div>
        </td>
        {/* <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900">{deficiency.report_name}</div>
        </td> */}
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900">{deficiency.inspection_year}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <PillButton label="Download PDF" color="bg-blue-500" />
        </td>
      </tr>
      {expanded && (
        <tr>
          <td colSpan="5" className="bg-gray-50">
            <div className="py-2 px-4">
              <div key={deficiency.deficiency_id} className="mb-4">
                <div className="font-medium text-gray-900">
                  Audit Area: {deficiency.deficiency_audit_area}
                </div>
                <div className="text-gray-600 my-4">
                  Description: {deficiency.deficiency_description}
                </div>
                <div className="text-gray-600 my-4">
                  Violated Standard: {deficiency.deficiency_violated_standard}
                </div>
                <hr />
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const PCAOBAnalyzer = () => {
  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(
        logEvent({
          user,
          event: "Looked at PCAOB app.",
        })
      );
    }
  }, [isLoaded, isSignedIn, user]);

  const [pcaobData, setPCAOBData] = useState([]);
  const [sqlQuery, setSqlQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [years, setYears] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [auditAreas, setAuditAreas] = useState([]);
  const [countries, setCountries] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [yearFilter, setYearFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [auditAreaFilter, setAuditAreaFilter] = useState([]);
  const [countryFilter, setCountryFilter] = useState([]);

  const [query, setQuery] = useState("");
  const tableContainerRef = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current && tableContainerRef.current) {
        tableContainerRef.current.scrollLeft = scrollRef.current.scrollLeft;
      }
    };
    if (scrollRef.current && tableContainerRef.current) {
      tableContainerRef.current.scrollLeft = 0;
      scrollRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const fetchFilters = async () => {
      const responsePcaobFilters = await getRequest("/api/get_pcaob_filters");
      const {
        unique_audit_areas: uniqueAuditAreas,
        unique_company_names: uniqueCompanies,
        unique_countries: uniqueCountries,
      } = responsePcaobFilters.data;
      setCompanyList(uniqueCompanies);
      setAuditAreas(uniqueAuditAreas);
      setCountries(uniqueCountries);
    };
    if (isSignedIn) {
      fetchFilters();
    }
  }, [isSignedIn]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filters = {
          deficiency_description: searchTerm,
          deficiency_audit_area: auditAreaFilter,
          company_name: companyFilter,
          report_country: countryFilter,
          year_filter: yearFilter,
        };
        const responseGetPcaob = await postRequest("/api/get_pcaob", {
          page,
          page_size: pageSize,
          filters,
        });
        const { data, total_records } = responseGetPcaob.data;
        setPCAOBData(data);
        setFilteredData(data);
        setTotalRecords(total_records);

        const currentYear = new Date().getFullYear();
        const generatedYears = Array.from({ length: 5 }, (_, i) =>
          (currentYear - i).toString()
        );
        setYears(generatedYears);
      } catch (error) {
        setPCAOBData([]);
        setFilteredData([]);
        setTotalRecords(0);
        console.log(error);
      }
    };

    if (isSignedIn) {
      fetchData();
    }
  }, [
    isSignedIn,
    page,
    pageSize,
    searchTerm,
    auditAreaFilter,
    companyFilter,
    countryFilter,
    yearFilter,
  ]);

  const numberOfReports = useMemo(() => {
    const reportIds = filteredData.map((r) => r.report_id);
    const uniqueReports = [...new Set(reportIds)];
    return uniqueReports.length;
  }, [filteredData]);

  useEffect(() => {
    console.log("Filtered data", filteredData);
  }, [filteredData]);

  useEffect(() => {
    console.log("Filtered data", filteredData);
  }, [filteredData]);

  const [expandedRows, setExpandedRows] = useState({});

  const handleRowClick = (id) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };

  const handleSubmitMessageForm = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setQuery("");
      const { data } = await postRequest("/api/pcaob/question_pcaob", {
        query,
      });
      if (data) {
        setFilteredData(
          pcaobData.filter((pcaob) => data.report_ids.includes(pcaob.report_id))
        );
        setSqlQuery(data.sql_query);
      } else {
        setSqlQuery("");
      }
      setLoading(false);
    },
    [query]
  );

  const handleCompanyChange = (values) => {
    const _values = values.map(({ value }) => value);
    setCompanyFilter(_values);
  };

  const handleAuditAreaChange = (values) => {
    const _values = values.map(({ value }) => value);
    setAuditAreaFilter(_values);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Additional function to handle changing the page size
  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPage(1); // Reset to the first page
  };

  const handleYearChange = (values) => {
    const _values = values.map(({ value }) => value);
    setYearFilter(_values);
  };

  const handleCountryChange = (values) => {
    const _values = values.map(({ value }) => value);
    setCountryFilter(_values);
  };

  // Calculate the starting and ending index
  const startIndex = (page - 1) * filteredData.length + 1;
  const endIndex = Math.min(page * filteredData.length, totalRecords);

  // Edge case handling
  const displayStartIndex = totalRecords > 0 ? startIndex : 0;
  const displayEndIndex = totalRecords > 0 ? endIndex : 0;
  const { instance_id } = useParams();

  return (
    <>
      <div className="flex mb-4 justify-between">
        <h1 className="text-xl font-bold">PCAOB Analyzer</h1>
        <DeleteAppButton app_instance_id={instance_id} />
      </div>
      <div className="mb-4">
        <MessageForm
          value={query}
          onChange={({ target: { value } }) => setQuery(value)}
          onSubmit={handleSubmitMessageForm}
          className="mb-4"
          onClear={() => {}}
          onStar={() => {}}
        />{" "}
        {loading && (
          <div className="text-sm text-gray-500 my-2">
            Creating and executing query
            <Typed strings={["..."]} loop typeSpeed={40} />
          </div>
        )}
        {sqlQuery && (
          <div className="text-sm text-gray-500 mb-4">
            <BlackComputerScreen lines={[sqlQuery]} />
          </div>
        )}
        <div className="flex space-x-4 items-start">
          <div className="relative inline-block w-full text-gray-700">
            <Select
              onChange={handleYearChange}
              options={
                years &&
                years.length > 0 &&
                years.map((aa) => ({ label: aa, value: aa }))
              }
              placeholder="All Years"
              isMulti
            />
          </div>

          <div className="relative inline-block w-full text-gray-700">
            <Select
              onChange={handleCompanyChange}
              options={
                companyList &&
                companyList.length > 0 &&
                companyList.map((aa) => ({ label: aa, value: aa }))
              }
              placeholder="All Companies"
              isMulti
            />
          </div>

          <datalist id="company-list">
            {companyList &&
              companyList.length > 0 &&
              companyList.map((company) => (
                <option key={company} value={company} />
              ))}
          </datalist>

          <input
            type="text"
            className="border rounded p-2"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div className="relative inline-block w-full text-gray-700">
            <Select
              onChange={handleAuditAreaChange}
              options={
                auditAreas &&
                auditAreas.length > 0 &&
                auditAreas.map((aa) => ({ label: aa, value: aa }))
              }
              placeholder="All Audit Areas"
              isMulti
            />
          </div>

          <div className="relative inline-block w-full text-gray-700 max-w-32">
            <Select
              onChange={handleCountryChange}
              options={
                countries &&
                countries.length > 0 &&
                countries.map((aa) => ({ label: aa, value: aa }))
              }
              placeholder="All Countries"
              isMulti
            />
          </div>
        </div>
      </div>
      {filteredData.length > 0 && (
        <div className="text-sm text-gray-500 mb-4">
          Showing {formatNum(displayStartIndex)}-{formatNum(displayEndIndex)}{" "}
          deficiencies from {formatNum(totalRecords)} reports
        </div>
      )}
      <div className="mt-8 flow-root">
        <div
          className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
          ref={tableContainerRef}
        >
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
            <table className="min-w-full divide-y divide-gray-300 overflow-x-scroll">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Company and Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Audit Area
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Deficiency issuer
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Violated Standards
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Country
                  </th>
                  {/* <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Report ID
                  </th> */}
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Inspection Year
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {filteredData &&
                  filteredData.map((deficiency) => (
                    <TableRow
                      key={deficiency.deficiency_id}
                      deficiency={deficiency}
                      expanded={!!expandedRows[deficiency.deficiency_id]}
                      toggle={() => handleRowClick(deficiency.deficiency_id)}
                    />
                  ))}
              </tbody>
            </table>
            <div className="flex items-center justify-between my-4">
              <button
                className={`px-4 py-2 border rounded ${
                  page <= 1
                    ? "bg-customLightGray cursor-not-allowed"
                    : "bg-customHighlightColor text-white hover:bg-customBlack"
                }`}
                onClick={() => handlePageChange(page - 1)}
                disabled={page <= 1}
              >
                Previous
              </button>
              <span className="mx-2 text-customHighlightColor">
                Page {page} of {Math.ceil(totalRecords / pageSize)}
              </span>
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= Math.ceil(totalRecords / pageSize)}
                className={`px-4 py-2 border rounded ${
                  page >= Math.ceil(totalRecords / pageSize)
                    ? "bg-customLightGray cursor-not-allowed"
                    : "bg-customHighlightColor text-white hover:bg-customBlack"
                }`}
              >
                Next
              </button>
              <div className="ml-4">
                <label
                  htmlFor="pageSize"
                  className="mr-2 text-customHighlightColor"
                >
                  Page Size:
                </label>
                <select
                  id="pageSize"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  className="border rounded px-4 py-2 br-4 text-customHighlightColor"
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
            <div
              className="my-2 lg:pl-72 bg-white overflow-x-scroll fixed h-4 w-full bottom-0 right-0 scrollbar-show"
              ref={scrollRef}
            >
              <div
                className="bg-black z-10"
                style={{ width: "calc(100% + 600px)" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PCAOBAnalyzer;
