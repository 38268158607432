import { CheckIcon } from "@heroicons/react/24/solid";

// Second one at https://tailwindui.com/components/application-ui/navigation/progress-bars

export default function ProgressBar({ steps, onStepClick }) {
  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            <a
              href="#"
              onClick={() => onStepClick(stepIdx)}
              className="group flex w-full items-center no-underline"
            >
              {step.status === "completed" ? (
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-customHighlightColor group-hover:bg-customHighlightColor">
                    <CheckIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900 no-underline">
                    {step.name}
                  </span>
                </span>
              ) : step.status === "current" ? (
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-customHighlightColor">
                    <span className="text-customHighlightColor">
                      {stepIdx + 1}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-customHighlightColor no-underline">
                    {step.name}
                  </span>
                </span>
              ) : (
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">
                      {stepIdx + 1}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900 no-underline">
                    {step.name}
                  </span>
                </span>
              )}
            </a>
            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  aria-hidden="true"
                  className="absolute right-0 top-0  h-full w-5 md:block"
                >
                  <svg
                    fill="none"
                    viewBox="0 0 22 80"
                    preserveAspectRatio="none"
                    className="h-full w-full text-gray-300"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      stroke="currentcolor"
                      vectorEffect="non-scaling-stroke"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}