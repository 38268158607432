/**
 * Asynchronously parses a file based on its type and performs a specific action depending on the outcome.
 * 
 * @function parseFile
 * @async
 * @param {string} fileID - The unique identifier of the file to be parsed.
 * @param {string} fileType - The type of the file to be parsed. Accepted values are "webpage" or any other type that requires PDF parsing.
 * @param {Function} postRequest - A function to make HTTP POST requests. It should accept a URL and an object containing request parameters.
 * @param {Function} setPDFDetails - A function to update the state or context with the parsed file details.
 * @param {Function} addNotification - A function to add a notification to the UI. It should accept a message, a description, and a notification type.
 * @param {Object} NotificationType - An object containing the types of notifications that can be added.
 * @param {Object} [options] - An optional object containing additional parameters.
 * @param {Function} [options.catchAction] - A function to execute in case of an error during the parsing process. It should accept an object containing the error, fileID, and fileType.
 * @returns {Promise<void>} A promise that resolves when the parsing process is complete.
 * @throws {Error} If an error occurs during the parsing process, it will be thrown.
 * 
 * @example
 * // Example usage
 * parseFile(
 *   "12345", // fileID
 *   "webpage", // fileType
 *   postRequest, // postRequest function
 *   setPDFDetails, // setPDFDetails function
 *   addNotification, // addNotification function
 *   NotificationType, // NotificationType object
 *   { catchAction: handleError } // Optional catchAction function
 * );
 */
export const parseFile = async (
  fileID,
  fileType,
  postRequest,
  setPDFDetails, // NOTE: This variable is deprecated and should be removed from the function signature.
  addNotification,
  NotificationType,
  { catchAction, finalAction },
  appInstanceId = null,
) => {
  if (fileType === "webpage") {
    try {
      const { data } = await postRequest("/api/parse_webpage", {
        filetype: fileType,
        file_id: fileID,
        app_instance_id: appInstanceId,
      });
      setPDFDetails && setPDFDetails(data);
      addNotification(
        "Webpage successfully parsed.",
        "Text from the webpage has been split and vectorized.",
        NotificationType.success
      );
    } catch (error) {
      if (catchAction) {
        catchAction({ error, fileID, fileType });
      }
      addNotification("Error parsing webpage.", "", NotificationType.error);
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  } else {
    try {
      const response = await postRequest("/api/parse_file", {
        document_type: "",
        file: fileID,
        app_instance_id: appInstanceId
      });
      setPDFDetails && setPDFDetails(response.data);
      addNotification(
        "File successfully parsed.",
        "Text from the file has been split and vectorized.",
        NotificationType.success
      );
    } catch (error) {
      if (catchAction) {
        catchAction({ error, fileID, fileType });
      }
      addNotification("Error parsing PDF.", "", NotificationType.error);
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  }
  if (finalAction) {
    finalAction({ fileID, fileType });
  }
};
