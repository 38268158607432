// StatementTable.js
import React from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import Typed from "react-typed";

const StatementTable = ({
  tables,
  crossReferenceCheckStatement,
  urlInput,
  urlInputSubmitPressed,
}) => {
  return (
    <table className="table-auto w-1/2">
      <thead>
        <tr className="border-b border-gray-300">
          <th className="min-w-[200px]">Parsed statement</th>
          <th className="min-w-[200px]">Annotated statement</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(tables ?? {}).length > 0 ? (
          <tr className="border-b border-gray-300 whitespace-nowrap">
            <td className="text-sm py-2">{tables["filename"]}</td>
            <td>
              {crossReferenceCheckStatement &&
              crossReferenceCheckStatement["file_url"] ? (
                <a
                  href={crossReferenceCheckStatement["file_url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button>
                    <ArrowDownTrayIcon className="h-6 w-6 text-gray-500 hover:text-customHighlightColor bg-white" />
                  </button>
                </a>
              ) : (
                <Typed strings={["..."]} loop typeSpeed={40} />
              )}
            </td>
          </tr>
        ) : (
          <p className="text-sm py-2">
            Parsing
            {urlInputSubmitPressed && urlInput.current && (
              <>
                {" "}
                <i>{urlInput.current.value.slice(0, 50)}</i>
              </>
            )}
            <Typed strings={["..."]} loop typeSpeed={40} />
          </p>
        )}
      </tbody>
    </table>
  );
};

export default StatementTable;