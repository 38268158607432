import React, { useState , useEffect } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { useBoardMinutesAnalyzer } from "./useBoardMinutesAnalyzer";
import BoardMinutesTable from "./BoardMinutesTable";
import BlackComputerScreen from "../../utils/BlackComputerScreen";
import RagQueryComponent from "../../utils/RagQueryComponent";
import Dropzone from "../../utils/FileUpload/Dropzone";
import { highlightUtil } from "../../utils/highlightUtil";
import { useUser } from "@clerk/clerk-react";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import { Viewer } from "../../utils";
import DeleteAppButton from "../../utils/DeleteAppButton";

const BoardMinutesAnalyzer = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const { appInstanceData } = useAppInstanceData();
  const [accountingQueryDone, setAccountingQueryDone] = useState(false); // Not used but may be

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      console.log(user, `Looked at Boards Minutes Analyzer`);
    }
  }, [isLoaded, isSignedIn, user, appInstanceData]);

  const { analyzerContext, fileIDs, listOfDOSLines, fetchSavedPrompts } =
    useBoardMinutesAnalyzer();
  const {
    boardMinuteDetails,
    currentSource,
    filesDropped,
    highlightAreas,
    onDrop,
    successfulFileUploads,
    uploadDemoFiles,
    urlInputSubmitPressed,
  } = analyzerContext;

  const pageNavigationPluginInstanceLEFT = pageNavigationPlugin();

  const jumpToPage = pageNavigationPluginInstanceLEFT.jumpToPage;

  const highlightPluginInstanceLEFT = highlightUtil(highlightAreas, currentSource?.data?.url);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div className="m-5">
        <div className="flex justify-between mb-5 items-center">
          <h1 className="text-2xl font-bold">Board Minutes Analyzer</h1>
          <DeleteAppButton app_instance_id={appInstanceData.id} />
        </div>

        {!urlInputSubmitPressed && !filesDropped && (
          <>
            <div className="flex justify-center">
              <Dropzone
                onDrop={onDrop}
                dragText="Drag a set of board minutes, or click to browse."
              />
            </div>
            <div className="flex flex-col items-center">
              <br />
              <div>Try sample data:</div>
              <div className="flex justify-center space-x-4 mt-2">
                <button
                  className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                  onClick={() =>
                    uploadDemoFiles("2023 New York City Loft Board Minutes")
                  }
                >
                  2023 New York City Loft Board Minutes
                </button>
              </div>
            </div>
          </>
        )}

        <div className="mt-2">
          {filesDropped && (
            <>
              {(boardMinuteDetails.length === 0 ||
                boardMinuteDetails.length < successfulFileUploads.length) && (
                <BlackComputerScreen lines={listOfDOSLines} />
              )}
              <br />
              <BoardMinutesTable analyzerContext={analyzerContext} />

              <div className="flex">
                <div className="h-screen w-1/2">
                  {currentSource && (
                    <>
                      <br />
                      <Viewer
                        fileUrl={currentSource.data?.url}
                        plugins={[
                          highlightPluginInstanceLEFT,
                          pageNavigationPluginInstanceLEFT,
                        ]}
                      />
                    </>
                  )}
                </div>
                <div className="h-screen w-1/2 flex-1">
                  <RagQueryComponent
                    pdfDetails={boardMinuteDetails}
                    analyzerContext={analyzerContext}
                    fileIDs={fileIDs}
                    jumpToPage={jumpToPage}
                    userObj= {{ id : user.id }}
                    appInstanceId={appInstanceData.id}
                    originalUserText={'Board Minutes Analyzer'}
                    generatedResponse={''}
                    setAccountingQueryDone={setAccountingQueryDone}
                    fetchSavedPrompts={fetchSavedPrompts}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Worker>
  );
};

export default BoardMinutesAnalyzer;
