import { ssePostRequest, getRequest } from "./httpUtils";

export const extract10KData = async (fileID, onEventHandlers) => ssePostRequest(
    "/api/10k/extract",
    {
        file: fileID,
    },
    onEventHandlers)

export const getFootnotesFor10K = async (fileID) => {
    const response = await getRequest(`/api/10k/get_footnote/${fileID}`);
    return response.data;
}
