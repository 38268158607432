import React, { useEffect } from "react";
import {
  SignedIn,
  SignedOut,
  UserProfile,
  useUser,
} from "@clerk/clerk-react";
import { useDispatch } from "react-redux"
import { logEvent } from "./redux/reducers/app.reducer";
import RedirectToSignIn from "./utils/RedirectToSignIn";

const Profile = () => {
  const dispatch = useDispatch()
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(logEvent({
        user,
        event: "Looked at user profile."
      }));
    }
  }, [isLoaded, isSignedIn, user]);

  return (
    <>
      {/* <h1 className="text-xl font-bold mb-4">Welcome to the Tellen demo!</h1> */}
      <SignedIn>
        <UserProfile />
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

export default Profile;
