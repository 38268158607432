import { useState, useEffect, useCallback } from "react";
import { postRequest } from "../utils/httpUtils";

/**
 * Custom hook to fetch companies and footnote titles from the server.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {boolean} params.isPublic - Indicates if the data is public.
 * @param {string} params.appInstanceID - The application instance ID.
 *
 * @returns {{
*   accountingBasis: object[] | null,
*   companies: object[] | null,
*   error: string | null,
*   footnoteTitles: object[] | null,
*   industries: object[] | null,
*   isLoading: boolean,
*   refetch: function,
*   years: object[] | null,
* }} Hook state and functions to handle companies, footnote titles, years, industries, and accounting basis.
*/
const useFetchFootnoteFiltersData = ({ isPublic, appInstanceID }) => {
  console.log("Hook params:", { isPublic, appInstanceID });

  const [companies, setCompanies] = useState(null);
  const [footnoteTitles, setFootnoteTitles] = useState(null);
  const [years, setYears] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [accountingBasis, setAccountingBasis] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use useCallback to memoize the fetchFiltersData function

  // TODO We should use useCallback but it was giving issues related to the appInstanceID value
  const fetchFiltersData = async () => {
    setIsLoading(true);
    setError(null); // Reset error state

    console.log("Sending request with:", { isPublic, appInstanceID }); 

    try {
      const data = { isPublic };
      if (appInstanceID !== undefined) {
        data.app_instance_id = appInstanceID;
      }
      console.log("HHHData:", data);
      const response = await postRequest("/api/footnotes/get_filters_data", data);
      if (response?.data) {
        setCompanies(response.data?.companies || []);
        setFootnoteTitles(response.data?.footnote_titles || []);
        setYears(response.data?.years || []);
        setIndustries(response.data?.industries || []);
        setAccountingBasis(response.data?.accounting_basis || []);
      }
    } catch (err) {
      setError("Failed to load filters data.");
      console.error("Error fetching filters data:", err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isPublic === undefined || appInstanceID === undefined) {
      return;
    }
    fetchFiltersData();
  }, [isPublic, appInstanceID]); // Should this contain fetchFiltersData?

  return {
    accountingBasis,
    companies,
    error,
    footnoteTitles,
    industries,
    isLoading,
    refetch: fetchFiltersData, // Expose the fetchFiltersData function as refetch
    years,
  };
};

export default useFetchFootnoteFiltersData;
