import React from "react";
import AppCardLong from "./AppCardLong";

const AppsListLong = ({ pageName, appsArray }) => {
  return (
    <div className="flex flex-col gap-4 w-full max-w-screen-xl mx-auto my-4 shadow-lg rounded-lg p-2">
      { pageName && (
        <div>
          <h1 className="text-2xl font-bold text-gray-700">{pageName}</h1>
        </div>
      ) }
      <div className="flex flex-col items-center w-full">
        {Array.isArray(appsArray) &&
          appsArray.map((card, index) => (
            <div className="w-full" key={index}>
              <AppCardLong
                key={card.app_instance_id}
                card={card}
                url={card.url}
                slug={card.slug}
                description={card.description}
              />
              { appsArray[index + 1] && (<div className="w-full border-b border-lightgray" />)}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AppsListLong;
