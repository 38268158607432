import React, { useState, useCallback } from "react";
import useDeleteApp from "../appManipulation/useDeleteApp";
import { useUser } from "@clerk/clerk-react";

const buttonClass = "ml-2 flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"

const DeleteAppButton = ({ app_instance_id }) => {
    const { user } = useUser();
    const [shouldShowConfirm, setShouldShowConfirm] = useState(false);
    const showConfirm = useCallback(() => setShouldShowConfirm(true), []);
    const hideConfirm = useCallback(() => setShouldShowConfirm(false), []);
    const { handleDelete, loading } = useDeleteApp(app_instance_id, user);

    return (
        <>
            {!shouldShowConfirm && (
                <button
                    type="button"
                    className={buttonClass}
                    onClick={showConfirm}
                >
                    Delete App
                </button>
            )}
            {shouldShowConfirm && (
                <div className="flex">
                    <button type="button" className={buttonClass} disabled={loading} onClick={handleDelete}>Confirm Delete</button>
                    <button type="button" className={buttonClass} disabled={loading} onClick={hideConfirm}>Cancel</button>
                </div>
            )}
        </>

    );
};

export default DeleteAppButton;
