import React, { useState } from "react";
import TableDetails from "../../utils/TableDetails";

const Footnote = ({ element, setHighlightAreas, jumpToPage, fileIDs }) => {
  const [expanded, setExpanded] = useState(null);

  const handleToggle = (title) => {
    if (expanded === title) {
      setExpanded(null);
    } else {
      setExpanded(title);
    }
  };

  return (
    <div>
      <div
        onClick={() => {
          handleToggle(element.title);
        }}
        style={{ cursor: "pointer" }}
      >
        <h2
          className="text-2xl mb-4"
          onMouseEnter={() => {
            jumpToPage(element.top.page_number - 1);
            setHighlightAreas([
              {
                file_id: fileIDs[0],
                pageIndex: element.top.page_number - 1,
                height:
                  (element.paragraphs[0].height /
                    element.paragraphs[0].page_height) *
                  100,
                width:
                  (element.paragraphs[0].width /
                    element.paragraphs[0].page_width) *
                  100,
                left:
                  (element.paragraphs[0].left /
                    element.paragraphs[0].page_width) *
                  100,
                top:
                  (element.paragraphs[0].top /
                    element.paragraphs[0].page_height) *
                  100,
              },
            ]);
          }}
        >
          <b>
            {element.number}. {element.title}
          </b>
        </h2>
      </div>
      {expanded === element.title && (
        <div>
          {element.paragraphs.slice(1).map((paragraph, index) => (
            <p
              key={index}
              onMouseEnter={() => {
                // TODO: Could you make it jump to the relevant page here, please? You can get the page number from 'element'
                jumpToPage(paragraph.page_number - 1);
                setHighlightAreas([
                  {
                    file_id: fileIDs[0],
                    pageIndex: paragraph.page_number - 1,
                    height: (paragraph.height / paragraph.page_height) * 100,
                    width: (paragraph.width / paragraph.page_width) * 100,
                    left: (paragraph.left / paragraph.page_width) * 100,
                    top: (paragraph.top / paragraph.page_height) * 100,
                  },
                ]);
              }}
            >
              {paragraph.text}
            </p>
          ))}
          <br />
          <br />
          {element.tables.map((table, index) => (
            <TableDetails
              key={index}
              tableDetails={table.cells}
              setHighlightAreas={setHighlightAreas}
              jumpToPage={jumpToPage}
              fileID={fileIDs[0]}
            />
          ))}
          <hr />
        </div>
      )}
    </div>
  );
};

export default Footnote;
