import { v4 as uuidv4 } from "uuid";
import { postRequest } from "../utils/httpUtils";
import { convertSampleFileURLsToObjects, ensureFunction, secureFilename } from "../utils";

/**
 * Uploads demo files to the server.
 *
 * This function iterates over a list of sample files, generates a unique ID for each file,
 * and sends a POST request to the `/upload_from_url` endpoint with the file URL and user information.
 * It handles the start, success, and error events of the upload process by calling the appropriate callback functions.
 *
 * @param {Object} params - The parameters object.
 * @param {Array.<{url: string}>} params.sampleFiles - An array of objects containing URLs of the sample files to upload.
 * @param {function} params.setFilesDropped - A callback function to update the state indicating whether files have been dropped.
 * @param {string} params.user - The user identifier or object associated with the upload.
 * @param {function} [params.onStartUpload=()=>{}] - An optional callback function to call when the upload starts. Defaults to an empty function.
 * @param {function} [params.onSuccessfulUpload=()=>{}] - An optional callback function to call when the upload is successful. Defaults to an empty function.
 * @param {function} [params.onErrorUpload=()=>{}] - An optional callback function to call when there is an error during the upload. Defaults to an empty function.
 */
export const uploadDemoFiles = ({
  sampleFiles,
  setFilesDropped,
  user,
  onStartUpload,
  onSuccessfulUpload,
  onErrorUpload,
}) => {
  onStartUpload = ensureFunction(onStartUpload);
  onSuccessfulUpload = ensureFunction(onSuccessfulUpload);
  onErrorUpload = ensureFunction(onErrorUpload);
  setFilesDropped(true);

  const files = convertSampleFileURLsToObjects(sampleFiles);

  files.forEach((file) => {
    const fileID = secureFilename(uuidv4());
    onStartUpload(file);
    postRequest(`/api/upload_from_url`, {
      url: file.url,
      fileID: fileID,
      user: user,
    })
      .then((response) => {
        onSuccessfulUpload(response, file, fileID);
      })
      .catch((error) => {
        onErrorUpload(error);
      });
  });
};
