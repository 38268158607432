import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToSignIn = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/sign_in');
  }, [navigate]);

  return null; // No need to render anything as we're redirecting
};

export default RedirectToSignIn;
