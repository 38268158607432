import React, { useState, useEffect, useCallback, Fragment } from "react";
import Typed from "react-typed";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { useInvoiceCrossReference } from "../../hooks/useInvoiceCrossReference";
import InvoiceDetails from "./InvoiceDetails";
import TableDetails from "../../utils/TableDetails";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import BlackComputerScreen from "../../utils/BlackComputerScreen";
import { postRequest } from "../../utils/httpUtils";
import {
  handleCSVDownload,
  handleXlsxDownload,
} from "../../utils";
import DownloadDropdown from "../../utils/DownloadDropdown";
import Dropzone from "../../utils/FileUpload/Dropzone";
import ParsedInvoicesTable from "./ParsedInvoicesTable";
import StatementTable from "./StatementTable";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import { highlightUtil } from "../../utils/highlightUtil";
import { Viewer } from "../../utils";
import DeleteAppButton from "../../utils/DeleteAppButton";

const InvoiceCrossReference = () => {
  const {
    urlInput,
    filesDropped,
    successfulFileUploads,
    currentInvoiceURL,
    setCurrentInvoiceURL,
    urlInputSubmitPressed,
    highlightAreasLEFT,
    setHighlightAreasLEFT,
    highlightAreasRIGHT,
    setHighlightAreasRIGHT,
    onDrop,
    invoiceDetails,
    tables,
    numberOfInvoices,
    numberOfStatements,
    statementPDFURL,
    setStatementPDFURL,
    statementFileID,
    uploadDemoFiles,
    listOfDOSLines,
    setListOfDOSLines,
  } = useInvoiceCrossReference();

  const { appInstanceData } = useAppInstanceData();

  const pageNavigationPluginInstanceLEFT = pageNavigationPlugin();
  const pageNavigationPluginInstanceRIGHT = pageNavigationPlugin();

  const [currentDetailsFilename, setCurrentDetailsFilename] = useState("");

  const [currentDetailsType, setCurrentDetailsType] = useState(null);

  const [allFilesParsed, setAllFilesParsed] = useState(false);

  const { addNotification } = useNotifications();

  const [crossReferenceCheckInvoices, setCrossReferenceCheckInvoices] =
    useState([]);
  const [crossReferenceCheckStatement, setCrossReferenceCheckStatement] =
    useState([]);

  const [showWorking, setShowWorking] = useState(true);

  const highlightPluginInstanceLEFT = highlightUtil(highlightAreasLEFT);
  const highlightPluginInstanceRIGHT = highlightUtil(highlightAreasRIGHT);

  const invoiceListAsJSON = useCallback((invoiceDetails) => {
    return invoiceDetails.map((invoice) => {
      const invoiceAsJSON = JSON.parse(invoice.data);
      return {
        FileID: invoiceAsJSON["file_id"] || "N/A",
        Filename: invoiceAsJSON["filename"] || "N/A",
        ID: invoiceAsJSON["Invoice  ID"]?.text || "N/A",
        Date: invoiceAsJSON["Invoice Date"]?.text || "N/A",
        Vendor: invoiceAsJSON["Vendor Name"]?.text || "N/A",
        Customer: invoiceAsJSON["Customer Name"]?.text || "N/A",
        Amount: invoiceAsJSON["Invoice Total"]?.text?.amount || "N/A",
        "Invoice Bounding Box": {
          page_number: invoiceAsJSON["Invoice Total"]?.page_number,
          top: invoiceAsJSON["Invoice Total"]?.top,
          left: invoiceAsJSON["Invoice Total"]?.left,
          width: invoiceAsJSON["Invoice Total"]?.width,
          height: invoiceAsJSON["Invoice Total"]?.height,
          page_width: invoiceAsJSON["Invoice Total"]?.page_width,
          page_height: invoiceAsJSON["Invoice Total"]?.page_height,
        },
      };
    });
  }, []);

  const handleCSVDownloadCallback = useCallback(() => {
    handleCSVDownload(invoiceListAsJSON(invoiceDetails));
  }, [invoiceDetails, invoiceListAsJSON]);

  const handleXlsxDownloadCallback = useCallback(() => {
    handleXlsxDownload(invoiceListAsJSON(invoiceDetails));
  }, [invoiceDetails, invoiceListAsJSON]);

  const doCrossReference = async () => {
    try {
      const response = await postRequest("/api/invoice_cross_reference", {
        invoice_list: invoiceListAsJSON(invoiceDetails),
        tables: tables,
        statement_pdf: statementFileID,
      });

      console.log("Cross reference check done!");
      console.log(response.data);
      setCrossReferenceCheckInvoices(response.data["invoices"]);
      setCrossReferenceCheckStatement(response.data["statement"]);
      setStatementPDFURL(response.data["statement"]["file_url"]);
      setListOfDOSLines((prevLines) => [
        ...prevLines,
        "Invoices successfully cross-referenced with bank statement",
      ]);
      addNotification(
        "Invoices successfully cross referenced with bank statement.",
        "",
        NotificationType.success
      );
      setShowWorking(false);
    } catch (error) {
      addNotification(
        "Error cross-referencing invoices with bank statement.",
        "",
        NotificationType.error
      );
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  useEffect(() => {
    console.log("Number of invoices");
    console.log(numberOfInvoices);
    console.log("Number of statements");
    console.log(numberOfStatements);
    console.log("Successful file uploads");
    console.log(successfulFileUploads.length);
    console.log("Successful files parsed");
    console.log(numberOfInvoices + numberOfStatements);
    if (
      numberOfStatements === 1 &&
      successfulFileUploads.length === numberOfInvoices + numberOfStatements
    ) {
      setAllFilesParsed(true);
      doCrossReference();
    }
  }, [
    invoiceDetails,
    successfulFileUploads,
    numberOfInvoices,
    numberOfStatements,
  ]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div className="m-5">
        <div className="flex justify-between mb-5 items-center">
          <h1 className="text-2xl font-bold">{appInstanceData?.custom_name}</h1>
          <DeleteAppButton app_instance_id={appInstanceData.id} />
        </div>

        {!urlInputSubmitPressed && !filesDropped && (
          <>
            <div className="flex justify-center">
              <Dropzone
                onDrop={onDrop}
                dragText="Drag invoices and a single bank statement here, or click to browse."
              />
            </div>
            <div className="flex flex-col items-center">
              <br />
              <div>Or try data from a sample company:</div>
              <div className="flex justify-center space-x-4 mt-2">
                <button
                  className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                  onClick={() => uploadDemoFiles("Mineral Minds")}
                >
                  Mineral Minds
                </button>
                <button
                  className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                  onClick={() => uploadDemoFiles("Swift Consulting")}
                >
                  Swift Consulting
                </button>
              </div>
            </div>
          </>
        )}

        <div className="mt-2">
          {filesDropped && (
            <>
              {showWorking && (
                <>
                  <BlackComputerScreen lines={listOfDOSLines} />
                  <br />
                </>
              )}
              <p>
                <ParsedInvoicesTable
                  invoiceDetails={invoiceDetails}
                  crossReferenceCheckInvoices={crossReferenceCheckInvoices}
                  currentInvoiceURL={currentInvoiceURL}
                  setCurrentInvoiceURL={setCurrentInvoiceURL}
                  setCurrentDetailsType={setCurrentDetailsType}
                  setCurrentDetailsFilename={setCurrentDetailsFilename}
                  allFilesParsed={allFilesParsed}
                  urlInputSubmitPressed={urlInputSubmitPressed}
                  urlInput={urlInput}
                />
                {invoiceDetails.length > 0 && (
                  <DownloadDropdown
                    handleCSVDownloadCallback={handleCSVDownloadCallback}
                    handleXlsxDownloadCallback={handleXlsxDownloadCallback}
                  />
                )}
                <br />
                <br />
                <StatementTable
                  tables={tables}
                  crossReferenceCheckStatement={crossReferenceCheckStatement}
                  urlInput={urlInput}
                  urlInputSubmitPressed={urlInputSubmitPressed}
                />
                <br />
                <br />

                {allFilesParsed ? (
                  <p align="center">
                    <i>
                      Successfully parsed {numberOfInvoices} invoices and a
                      statement.
                    </i>
                  </p>
                ) : null}
                <br />
              </p>
              {invoiceDetails.length === 0 &&
                Object.keys(tables ?? {}) === 0 && (
                  <p className="text-sm">
                    Parsing bank statement and invoice
                    {invoiceDetails.length > 1 ? "s" : null}
                    <Typed strings={["..."]} loop typeSpeed={40} />
                  </p>
                )}

              <div className="flex">
                <div className="h-screen w-1/2">
                  {statementPDFURL && (
                    <Viewer
                      fileUrl={statementPDFURL}
                      plugins={[
                        highlightPluginInstanceLEFT,
                        pageNavigationPluginInstanceLEFT,
                      ]}
                    />
                  )}
                </div>
                <div className="h-screen w-1/2">
                  {currentInvoiceURL && (
                    <Viewer
                      fileUrl={currentInvoiceURL}
                      plugins={[
                        highlightPluginInstanceRIGHT,
                        pageNavigationPluginInstanceRIGHT,
                      ]}
                    />
                  )}
                </div>
              </div>
              <br />
              <br />
              <div className="flex">
                <div className="h-screen w-1/2">
                  {Object.keys(tables ?? {}).length > 0 &&
                    tables["tables"].length > 0 &&
                    tables["tables"].map((table) => (
                      <Fragment key={table.uuid}>
                        <TableDetails
                          tableDetails={table.cells}
                          setHighlightAreas={setHighlightAreasLEFT} // TODO: Deal with this. We want to call it setHighlightAreasLEFT here but setHighlightAreasRIGHT below
                          jumpToPage={pageNavigationPluginInstanceLEFT.jumpToPage}
                        />
                        <br />
                      </Fragment>
                    ))}
                </div>
                <div className="h-screen w-1/2">
                  {invoiceDetails.length > 0 && (
                    <InvoiceDetails
                      invoiceDetails={invoiceDetails.find(
                        (invoice) =>
                          JSON.parse(invoice.data)["filename"] ===
                          currentDetailsFilename
                      )}
                      setHighlightAreas={setHighlightAreasRIGHT} // TODO: Deal with this
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Worker>
  );
};

export default InvoiceCrossReference;
