import React from "react";
import AppsList from "./appsPage/AppsList";
import useAppList from "./appsPage/useAppList";
import { useSelector } from "react-redux";
import { customizationsSelector } from "./redux/reducers/app.reducer";

const CompanyApps = () => {
  const appData = useAppList();
  const { name: companyName } = useSelector(customizationsSelector);

  return (
    <AppsList
      pageName={`${companyName} Apps`}
      appsArray={appData && appData.company_apps}
    />
  );
};

export default CompanyApps;
