import ProgressBar from "../../utils/progressBar";
import { useState, useEffect, useMemo } from "react";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import GenericExtractor from "../GenericExtractor/GenericExtractor";
import { Link } from "react-router-dom";
import Results from "./Results";

const InternalConsistencyCheck = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { appInstanceData } = useAppInstanceData();

  const getStatus = (stepIndex, currentStep) => {
    if (stepIndex === currentStep) return "current";
    if (stepIndex < currentStep) return "completed";
    return "upcoming";
  };

  const stepNames = ["Upload", "Results"];

  const steps = stepNames.map((name, index) => ({
    name,
    status: getStatus(index, currentStep),
  }));

  const [
    successfulFileUploadsFromUploadData,
    setSuccessfulFileUploadsFromUploadData,
  ] = useState([]);

  const [filesDroppedFromUploadData, setFilesDroppedFromUploadData] =
    useState(false);

  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  useEffect(() => {
    // Log the whole variable
    console.log(
      "AAAA successfulFileUploadsFromUploadData:",
      successfulFileUploadsFromUploadData
    );

    // Log the mapped list
    const fileIds = successfulFileUploadsFromUploadData?.map(
      (file) => file.data.file_id
    );
    console.log("AAAA Mapped file IDs:", fileIds);
  }, [successfulFileUploadsFromUploadData]);

  return (
    <>
      <ProgressBar steps={steps} onStepClick={handleStepClick} />
      <div className="flex justify-between w-full px-4 pb-1 mb-4">
        <div className="w-1/2 flex justify-start">
          {currentStep > 0 ? (
            <button
              onClick={prevStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Previous
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>

        <div className="w-1/2 flex justify-end">
          {currentStep < steps.length - 1 ? (
            <button
              onClick={nextStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Next
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>
        {/* <div className="ml-2 flex justify-end">
          <Link to={`/app/edit/${appInstanceData.id}`}>Edit Prompts</Link>
        </div> */}
      </div>

      {currentStep === 0 && (
        <>
          <p>Upload financial statements from this year and last year.</p>
          <GenericExtractor
            successfulFileUploads={successfulFileUploadsFromUploadData}
            setSuccessfulFileUploads={setSuccessfulFileUploadsFromUploadData}
            filesDropped={filesDroppedFromUploadData}
            setFilesDropped={setFilesDroppedFromUploadData}
            type="10k"
            showHeader={false}
          />
        </>
      )}
      {currentStep === 1 && (
        <Results
          files={successfulFileUploadsFromUploadData?.map(
            (file) => file.data.file_id
          )}
          appInstanceID={appInstanceData.id}
        />
      )}

      <div className="flex justify-between w-full px-4 pb-1 mt-4">
        <div className="w-1/2 flex justify-start">
          {currentStep > 0 ? (
            <button
              onClick={prevStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Previous
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>

        <div className="w-1/2 flex justify-end">
          {currentStep < steps.length - 1 ? (
            <button
              onClick={nextStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Next
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default InternalConsistencyCheck;
