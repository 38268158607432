import { NotificationType } from "../utils/notifications/Notifications";
import { postRequest } from "../utils/httpUtils";

export const handleAddToSavedPrompts = async ({
  event,
  queryType,
  queryInput,
  queryValues,
  savedPrompts,
  setSavedPrompts,
  setDoneAddToSavedPrompts,
  user,
  fileType,
  addNotification,
  app_instance_id
}) => {
  event.preventDefault();

  if (
    queryInput.current.value !== "" &&
    !savedPrompts.includes(queryInput.current.value)
  ) {
    let prompts = queryInput.current.value;

    postRequest("/api/add_to_saved_prompts", {
      prompts: prompts,
      user: user,
      app_instance_id: app_instance_id,
    })
      .then(({data}) => {
        const newElement = {prompts: prompts, id: data.id};
        console.log(newElement);
        setSavedPrompts(savedPrompts => [newElement, ...savedPrompts]);
        setDoneAddToSavedPrompts(true);
        addNotification(
          "Prompt Added",
          "Your prompt has been added to the list of saved prompts",
          NotificationType.success
        );
      })
      .catch((error) => {
        addNotification(
          "There was an error adding your prompt",
          "",
          NotificationType.error
        );
        console.error(error);
      });
  }
};
