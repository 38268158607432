import { useState } from 'react';

/**
 * `useQueryType` is a custom React hook for managing the type of query in the application.
 *
 * @returns {Array} An array containing:
 * - `queryType` (string): The current type of query.
 * - `setValidQueryType` (function): A function that sets the query type if it's valid.
 * - `error` (string): An error message if the query type is not valid.
 * - `queryTypes` (string[]): A list of possible query types
 */
export const useQueryType = () => {
  const queryTypes = ['Natural Language', 'Data Extraction'];
  const [queryType, setQueryType] = useState(queryTypes[0]);
  const [error, setError] = useState(null);
  let queryHeaderText;
  switch (queryType) {
    case "Natural Language":
      queryHeaderText = "Ask a question:";
      break;
    case "Data Extraction":
      queryHeaderText = "What data would you like to extract?";
      break;
  }

  const setValidQueryType = (newQueryType) => {
    if (queryTypes.includes(newQueryType)) {
      setQueryType(newQueryType);
      setError(null); // clear any previous error
    } else {
      setError("Wrong query type");
    }
  };

  return [queryType, setValidQueryType, error, queryTypes, queryHeaderText];
}